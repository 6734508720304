type TabTitleProps = {
  timeframe: number;
};

const TabTitle = ({ timeframe }: TabTitleProps) => {
  const title = timeframe === 0.5 ? "30" : timeframe;
  const prefix = timeframe === 0.5 ? "sec" : "min";

  return (
    <div id={`chart_tab_${timeframe}`} className='flex gap-[1px] text-[8pt] font-medium my-auto'>
      <div className='my-auto'>
      {title}
      </div>
      <span className="text-gray-400 dark:text-gray-700 text-[7pt] uppercase my-auto pt-[1px]">{prefix}</span>
    </div>
  );
};

export default TabTitle;
