import { BiFork } from "react-icons/bi";
import Tool from "./Tool";

const PitchFork = () => {
  return (
    <Tool
      text={"Pitchfork"}
      tool={"pitchfork"}
      icon={
        <BiFork size={22} className="" />
      }
    />
  );
};

export default PitchFork;
