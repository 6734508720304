import { Chart as MtopsChart } from 'chart';
import CandleCountDown from './CandleCountDown';
import ModeLabel from './ModeLabel';
import useColorScheme from 'src/hooks/useColorscheme';
import OptionsDropdown from './OptionsDropdown';
import ToolbarArea from './ToolbarArea';
import { BiHorizontalCenter, BiRedo, BiUndo } from 'react-icons/bi';
import MarketLabel from './MarketLabel';
import useStateId from 'src/hooks/useStateId';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';

type BadgeProperties = {
  chart: MtopsChart,
  market: string,
  active: boolean,
  timeframe: number
};


const Toolbar = ({ market, active, timeframe, chart }: BadgeProperties) => {
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);

  const theme = useColorScheme();
  const stateId = useStateId();
  const debug = useAppSelector((state) => state.settings.debug);

  const handleUndo = () => {
    if (canUndo) {
      chart.undoManager.undo();
      stateId.update();
    }
  }

  const handleRedo = () => {
    if (canRedo) {
      chart.undoManager.redo();
      stateId.update();
    }
  }

  // install keyboard handler
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      /** @ts-ignore */
      if (event.target?.localName === "textarea") return;
      /** @ts-ignore */
      if (event.target?.localName === "input") return;

      // disable key repeat
      if (event.repeat) return;

      if ((event.metaKey || event.ctrlKey) && !event.shiftKey && event.key == "z") {
        event.preventDefault();
        handleUndo();
      }

      if ((event.metaKey || event.ctrlKey)
        && event.shiftKey && event.key == "z") {
        event.preventDefault();
        handleRedo();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [chart, stateId]);

  useEffect(() => {
    const handleStateChange = () => {
      const status = chart.undoManager.getStatus();
      setCanUndo(status.canUndo);
      setCanRedo(status.canRedo);
    }

    chart.event.on("undomanager:state:change", handleStateChange);

    handleStateChange();

    return () => {
      chart.event.off("undomanager:state:change", handleStateChange);
    }
  }, [chart])

  const handleRecenter = () => {
    chart.controls.zoomRestore();
  }


  const handleDebug = () => {
    chart.undoManager.debug();
  }

  return (
    <>
      <div className="screenshot-badge hidden absolute z-50 right-12 my-3 mx-10" style={{ color: theme == "light" ? "black" : "white", fontSize: "14px" }}>
        {timeframe >= 1 ? `${timeframe}min` : `${timeframe * 60}sec`}
      </div>

      <div
        data-html2canvas-ignore
        className={`border-b-2 ${active ? "border-trtp-primary" : "border-white dark:border-black"} divide-white dark:divide-black divide-x text-xs flex bg-gray-50 dark:bg-gray-975`}
        style={{ zIndex: 100 }}
      >
        <OptionsDropdown chart={chart} timeframe={timeframe} />
        <ToolbarArea className='text-xxs uppercase'>
          <div
            className="flex gap-1 hover:text-trtp-primary dark:hover:dark:text-white cursor-pointer"
            onClick={handleRecenter}
          >
            <BiHorizontalCenter className="my-auto" size={14} />
            <div>recenter</div>
          </div>

          <div
            className={`flex gap-1 ${canUndo ? "hover:text-trtp-primary dark:hover:dark:text-white cursor-pointer" : "text-gray-400 dark:text-gray-700"}`}
            onClick={handleUndo}
          >
            <BiUndo className="my-auto" size={14} />
            <div>undo</div>
          </div>

          <div
            className={`flex gap-1 ${canRedo ? "hover:text-trtp-primary dark:hover:dark:text-white cursor-pointer" : "text-gray-400 dark:text-gray-700"}`}
            onClick={handleRedo}
          >
            <BiRedo className="my-auto" size={14} />
            <div>redo</div>
          </div>

          {debug ? (
            <div
              className="flex gap-1 hover:text-trtp-primary dark:hover:dark:text-white cursor-pointer"
              onClick={handleDebug}
            >
              <div>debug</div>
            </div>
          ) : (<></>)}
        </ToolbarArea>
        <div className="flex-1">
        </div>

        <ToolbarArea className='font-semibold text-xxs uppercase'>
          <MarketLabel />
        </ToolbarArea>
        <ToolbarArea className='font-semibold text-xxs uppercase'>
          <div className="flex gap-2">
            <div className="text-gray-400 dark:text-gray-600">Bar close: </div>
            <div className="w-[50px] font-mono text-gray-500"><CandleCountDown chart={chart} timeframe={timeframe} /></div>
          </div>
        </ToolbarArea>
      </div>
      <ModeLabel timeframe={timeframe} />
    </>
  );

}

export default Toolbar;
