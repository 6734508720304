import { ToolCategory, ToolDefinition, ToolsCategories } from "common";
import Splitter from "./buttons/Splitter";
import Buttons from './Buttons'
import React from "react";

const Toolbar = () => {
  return (
    <>{Object.values(ToolsCategories).map((category: ToolCategory, categoryIndex: number) => {
      return <React.Fragment key={category.name}>
        {categoryIndex != 0 && categoryIndex != ToolsCategories.length &&  (<Splitter />)}
        {Object.values(category.tools).map((tool: ToolDefinition) => (
          <React.Fragment key={tool.id}>{Buttons[tool.id] ?? ""}</React.Fragment>
        ))}
      </React.Fragment>
    })}
    </>);
}

export default Toolbar;
