import { Tool, Tools } from "common";
import { useState, useEffect } from "react";
import Switch, { SwitchOption } from "src/component/Switch";
import useStateId from "src/hooks/useStateId";
import Row from "../Row";

type GannFibOptionProperties = {
  turningPoint: any;
  chart: any;
};

const options: SwitchOption[] = [
  {
    id: "off",
    title: "Off",
    value: "off",
  },
  {
    id: "on",
    title: "On",
    value: "on",
  },
];

const GannFibOption = ({ turningPoint}: GannFibOptionProperties) => {
  const [fib, setFib] = useState<string>("");

  const { id: stateId, update } = useStateId();

  useEffect(() => {
    setFib(turningPoint.getOption("show_fib"));
  }, [turningPoint, stateId]);

  const handleChange = (option: SwitchOption) => {
    turningPoint.setOption("show_fib", option.value);

    setFib(option.value as string);
    update();
  };

   
  return (
    <Row
      title="Price Fibonacci"
      component={
        <div className="bg-gray-100 dark:bg-black p-1">
          <Switch
            options={options}
            selected={fib == "off" ? "off" : "on"}
            onChange={handleChange}
          />
        </div>
      }
    />
  );
};

export default GannFibOption;
