import { ReactComponent as TrtpLogo } from './trtp-logo.svg';

const AppLoading = () => {
  return (
    <div className="h-screen w-screen flex dark:bg-black">
      <div className="flex flex-col gap-10 max-w-sm w-96 m-auto">
        <div className="mx-auto">
      <TrtpLogo className="dark:invert animate-pulse" style={{ width: '50px', height: '50px' }} />
        </div>
        <div className="text-gray-600 font-medium dark:text-white text-xs text-center">
          Loading
        </div>
      </div>
    </div>
  );
};

export default AppLoading;
