import { RxAngle } from "react-icons/rx";
import Tool from "./Tool";

const AngleLine = () => {
  return (
    <Tool
      text={"Angle Line"}
      tool={"angleline"}
      keybinding={"k"}
      icon={<RxAngle size={20} />}
    />
  );
};

export default AngleLine;
