import { useEffect } from "react";
import { useAppSelector } from "../redux/hooks";

const useDynamicTitle = () => {
  const activeMarket = useAppSelector((state) => state.markets.active);

  const market = useAppSelector((root) => root.markets.markets.find((m) => m.identifier == activeMarket));
  const name = 'Tesseract Pro'

  useEffect(() => {
    if (!market) {
      document.title = name;
    } else {
      document.title = `${name} - ${market.name}`
    }

  }, [market])
};

export default useDynamicTitle;
