import { Tool, Tools } from "common";
import { useEffect, useState } from "react";

import ColorPicker from "src/component/ColorPicker";
import useTool from "src/hooks/useTool";
import Row from "./Row";
import LineStyleRow from "./Row/LineStyleRows";
import LineWidthRow from "./Row/LineWidthRow";

const VerticalRay = () => {
  const [color, setColor] = useState<string>(Tools[Tool.VerticalRay].options.color.default as string);

  const tool = useTool();

  useEffect(() => {
    if (tool === null) return;

    setColor(tool.getOption("color"));
  }, [tool]);

  const handleChange = (color: string) => {
    setColor(color);
    tool?.setOption("color", color);
  };

  return (
    <div className="flex flex-col gap-2">
      <Row
        title="Color"
        component={
          <ColorPicker
            color={color}
            onColorPick={(color) => handleChange(color)}
          />
        }
      />
        <LineWidthRow />
        <LineStyleRow />
    </div>
  );
};

export default VerticalRay;
