import { useAppSelector, useAppDispatch } from "./redux/hooks";

import Switch, { SwitchOption } from "./component/Switch";
import Title from "./properties/Title";
import { setToolbarPosition, ToolbarPosition, setPrimaryColor, setTheme, setDebug, setColor, resetColor, setMarketIndicator } from "./redux/features/counter/settingsSlice";
import Row from "./properties/Row";
import ColorPicker from "./component/ColorPicker";
import { ThemeType, useConfig } from "./context/ConfigContext";
import OnOffSwitch from "./component/OnOffSwitch";
import { PriIndicatorPosition, setPriIndicatorFixed } from "./redux/features/counter/chartSettingsSlice";
import TitleButtonContainer from "./properties/TitleButtonContainer";
import { TitleButton } from "./properties/TitleButton";
import { FaHistory } from "react-icons/fa";
import { useState } from "react";
import { setTimezone } from "./redux/features/counter/settingsSlice";
import useIsMobile from "./hooks/useIsMobile";
import SubTitle from "./properties/SubTitle";

const rewardOptions: SwitchOption[] = [
  {
    id: "left",
    value: 2,
    title: "Left",
  },
  {
    id: "float",
    value: 1,
    title: "Floating",
  },
  {
    id: "right",
    value: 3,
    title: "Right",
  },
];

const themeOptions: SwitchOption[] = [
  {
    id: ThemeType.Light,
    value: 1,
    title: "Light",
  },
  {
    id: ThemeType.Dark,
    value: 2,
    title: "Dark",
  },
  {
    id: ThemeType.System,
    value: 3,
    title: 'System',
  }
];

const SettingsPanel = () => {
  const dispatch = useAppDispatch();
  const serverNumber = useAppSelector((state) => state.chart.serverNumber);

  const [timezones] = useState(
    (Intl as any).supportedValuesOf('timeZone')
  )

  const { isDevelopment } = useConfig();
  const isMobile = useIsMobile();

  const toolbarPosition = useAppSelector((state) => state.settings.toolbarPosition);
  const primaryColor = useAppSelector((state) => state.settings.primaryColor);
  const colors = useAppSelector((state) => state.settings.colors);
  const theme = useAppSelector((state) => state.settings.theme);
  const debug = useAppSelector((state) => state.settings.debug);
  const chartSettings = useAppSelector((state) => state.chartSettings);
  const settings = useAppSelector((state) => state.settings);

  const onTimezoneChange = (data: any) => {
    dispatch(setTimezone(data.target.value));
  }

  return (
    <div className={`${isMobile && "min-h-[400px] max-h-[400px]"} flex flex-col gap-2 overflow-y-auto text-xs dark:text-white flex-1`}>
      <div className="flex flex-col gap-2">
        <Title>Tesseract Pro Settings</Title>
        <Row
          title="Accent Color"
          component={
            <ColorPicker
              color={primaryColor}
              onColorPick={(color) => {
                dispatch(setPrimaryColor(color));
              }}
            />
          }
        />
        {isMobile == false && (
          <Row
            title="Theme"
            component={
              <div className="dark:bg-black p-1">
                <Switch
                  options={themeOptions}
                  selected={theme}
                  onChange={(option: SwitchOption) => {
                    dispatch(setTheme(option.id as ThemeType));
                  }}
                />
              </div>
            }
          />
        )}
        {isMobile == false && (
          <Row
            title="Toolbar Position"
            component={
              <div className="dark:bg-black p-1">
                <Switch
                  options={rewardOptions}
                  selected={toolbarPosition}
                  onChange={(option: SwitchOption) => {
                    dispatch(setToolbarPosition(option.id as ToolbarPosition));
                  }}
                />
              </div>
            }
          />
        )}
        {isMobile == false && (
          <Row
            title="Market indicator"
            component={
              <div className="dark:bg-black p-1 flex">
                <OnOffSwitch selected={settings.show_marketindicator} onChange={(option) => {
                  dispatch(setMarketIndicator(option == "on"))
                }} />
              </div>
            }
          />
        )}
        <Row
          title="Timezone"
          component={
            <div className="dark:bg-black p-1 flex">
              <select
                className="border border-gray-100 dark:border-black rounded-sm bg-white text-gray-900 text-xxs focus:ring-trtp-primary focus:border-trtp-primary block w-full p-1 dark:bg-black dark:placeholder-gray-400 dark:text-white dark:focus:ring-trtp-primary cursor-pointer"
                onChange={onTimezoneChange}
                defaultValue={settings.timezone}>
                <option>UTC</option>
                {timezones.map((zone: string) => (<option key={zone}>{zone}</option>))}
              </select>
            </div>
          }
        />
        <Title>
          Chart settings
        </Title>
        <Row
          title="PRI indicator"
          component={
            <div className="dark:bg-black p-1 flex">
              <Switch
                options={[
                  {
                    id: "top",
                    title: "Top",
                  },
                  {
                    id: "float",
                    title: "Floating",
                  },
                  {
                    id: "bottom",
                    title: "Bottom",
                  }]
                }
                selected={chartSettings.pri_indicator_position}
                onChange={(option: SwitchOption) => {
                  dispatch(setPriIndicatorFixed(option.id as PriIndicatorPosition));
                }}
              />
            </div>
          }
        />
        <Title>
          <div className="flex">
            <div className="flex-1">
              Chart Colors
            </div>
            <TitleButtonContainer>
              <TitleButton tooltip={'Reset colors'} onClick={() => dispatch(resetColor())}>
                <FaHistory />
              </TitleButton>
            </TitleButtonContainer>
          </div>
        </Title>
        <Row
          title="Transition lines"
          component={
            <div className="flex flex-col gap-2 mx-4">
              <div className='flex gap-2'>
                <div className="mt-[6px]" style={{ fontSize: '7pt' }}>Long</div>
                <ColorPicker
                  color={colors?.transition_long ?? ""}
                  debounce={true}
                  onColorPick={(color) => {
                    dispatch(setColor(["transition_long", color]));
                  }}
                />
              </div>
              <div className='flex gap-2'>
                <div className="mt-[6px]" style={{ fontSize: '7pt' }}>Short</div>
                <ColorPicker
                  color={colors?.transition_short ?? ""}
                  debounce={true}
                  onColorPick={(color) => {
                    dispatch(setColor(["transition_short", color]));
                  }}
                />
              </div>
            </div>
          }
        />

        <SubTitle>Candles</SubTitle>
        <Row
          title="Body"
          component={
            <div className="flex flex-col gap-2 mx-4">
              <div className='flex gap-2'>
                <div className="mt-[6px]" style={{ fontSize: '7pt' }}>Long</div>
                <ColorPicker
                  color={colors?.candle_long ?? ""}
                  debounce={true}
                  onColorPick={(color) => {
                    dispatch(setColor(["candle_long", color]));
                  }}
                />
              </div>
              <div className='flex gap-2'>
                <div className="mt-[6px]" style={{ fontSize: '7pt' }}>Short</div>
                <ColorPicker
                  color={colors?.candle_short ?? ""}
                  debounce={true}
                  onColorPick={(color) => {
                    dispatch(setColor(["candle_short", color]));
                  }}
                />
              </div>
            </div>
          }
        />
        <Row
          title="Border"
          component={
            <div className="flex flex-col gap-2 mx-4">
              <div className='flex gap-2'>
                <div className="mt-[6px]" style={{ fontSize: '7pt' }}>Long</div>
                <ColorPicker
                  color={colors?.candle_long_border ?? ""}
                  debounce={true}
                  onColorPick={(color) => {
                    dispatch(setColor(["candle_long_border", color]));
                  }}
                />
              </div>
              <div className='flex gap-2'>
                <div className="mt-[6px]" style={{ fontSize: '7pt' }}>Short</div>
                <ColorPicker
                  color={colors?.candle_short_border ?? ""}
                  debounce={true}
                  onColorPick={(color) => {
                    dispatch(setColor(["candle_short_border", color]));
                  }}
                />
              </div>
            </div>
          }
        />
        <Row
          title="Line"
          component={
            <div className="flex flex-col gap-2 mx-4">
              <div className='flex gap-2'>
                <div className="mt-[6px]" style={{ fontSize: '7pt' }}>Long</div>
                <ColorPicker
                  color={colors?.candle_long_line ?? ""}
                  debounce={true}
                  onColorPick={(color) => {
                    dispatch(setColor(["candle_long_line", color]));
                  }}
                />
              </div>
              <div className='flex gap-2'>
                <div className="mt-[6px]" style={{ fontSize: '7pt' }}>Short</div>
                <ColorPicker
                  color={colors?.candle_short_line ?? ""}
                  debounce={true}
                  onColorPick={(color) => {
                    dispatch(setColor(["candle_short_line", color]));
                  }}
                />
              </div>
            </div>
          }
        />

        {isDevelopment && (
          <>
            <Title>Development</Title>
            <Row
              title="Debug"
              component={
                <div className="dark:bg-black p-1">
                  <OnOffSwitch selected={debug} onChange={(option) => {
                    dispatch(setDebug(option == "on"))
                  }} />
                </div>
              }
            />
          </>
        )}
      </div>
      <div className="flex gap-1 my-4 justify-center text-[7pt] text-gray-500 ">
        <span className="font-medium">Version</span> {process.env.REACT_APP_TPRO_VERSION}
        <span className="font-medium">Server</span> {serverNumber}
      </div>
    </div>
  );
};

export default SettingsPanel;
