import { TbTimelineEventX } from "react-icons/tb";
import Tool from "./Tool";

const TurningPoint = () => {
  return (
    <Tool
      text={"Turning Point"}
      tool={"turningpoint"}
      icon={<TbTimelineEventX size={20} />}
    />
  );
};

export default TurningPoint;
