import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { IoPauseOutline, IoPlayOutline, IoRefresh } from "react-icons/io5";
import { useChart } from "src/context/ChartContext";

const ReplayControls = () => {
  const [currentState, setCurrentState] = useState(0);
  const [speed, setSpeed] = useState(85);
  const chartProvider = useChart();

  const activeTimeframe = useAppSelector((state) => state.chart.timeframe);

  useEffect(() => {
    const chart = chartProvider.get(activeTimeframe);

    if (!chart) return;

    const onChange = (event: any) => {
      setCurrentState(event.data);
    };

    chart.event.on('replay:statechange', onChange)

    return () => {
      chart.event.off('replay:statechange', onChange)
    }
  }, [activeTimeframe]);

  const onSpeedChange = (c: any) => {
    const chart = chartProvider.get(activeTimeframe);
    if (!chart) return;

    setSpeed(c.target.value);
    
    // @ts-ignore
    chart.mode.current.setSpeed(c.target.value);
  }

  const onPlay = () => {
    const chart = chartProvider.get(activeTimeframe);
    if (!chart) return;

    // @ts-ignore
    chart.mode.current.setSpeed(speed);

    // @ts-ignore
    chart.mode.current.play();
  }

  const onPause = () => {
    const chart = chartProvider.get(activeTimeframe);
    if (!chart) return;

    // @ts-ignore
    chart.mode.current.pause();
  }

  const onReplay = () => {
    const chart = chartProvider.get(activeTimeframe);
    if (!chart) return;

    // @ts-ignore
    chart.mode.current.replay();
  }
  if (currentState == 0) {
    return (
      <div className="bg-gray-100 dark:bg-gray-950 text-trtp-primary font-medium text-xs text-center py-2">Place markers on chart</div>
    )
  }

  return (
    <div className="bg-gray-100 dark:bg-gray-950 p-2 flex gap-2 pointer-events-auto">
      {(currentState == 4 || currentState == 2) && (
        <div className="bg-gray-200 dark:bg-black p-1 rounded-sm text-gray-800 dark:text-gray-500 hover:text-trtp-primary cursor-pointer">
          <IoPlayOutline size={16}  onClick={onPlay} />
        </div>
      )}
      {(currentState == 1) && (
        <div className="bg-gray-200 dark:bg-black p-1 rounded-sm text-gray-800 dark:text-gray-500 hover:text-trtp-primary cursor-pointer">
          <IoPauseOutline size={16}  onClick={onPause} />
        </div>
      )}
      {(currentState == 3 || currentState == 1 || currentState == 2) && (
        <div className="bg-gray-200 dark:bg-black p-1 rounded-sm text-gray-800 dark:text-gray-500 hover:text-trtp-primary cursor-pointer">
          <IoRefresh size={16}  onClick={onReplay} />
        </div>
      )}
      <div className="text-xxs my-auto flex gap-1">
        <div><input type="range" min="1" max="100" defaultValue={speed} onChange={onSpeedChange} /></div>
        <div className="dark:text-gray-300">{speed}</div>
      </div>
    </div>
  )
}

export default ReplayControls;
