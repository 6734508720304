import { useEffect, useState } from "react";

import ColorPicker from "src/component/ColorPicker";
import useStateId from "src/hooks/useStateId";
import useTool from "src/hooks/useTool";
import Row from "./Row";
import LineStyleRow from "./Row/LineStyleRows";
import LineWidthRow from "./Row/LineWidthRow";

const Magbox = () => {
  const tool = useTool();
  const stateId = useStateId();

  const [color, setColor] = useState<string>("");
  const [borderColor, setBorderColor] = useState<string>("");

  useEffect(() => {
    if (tool === null) return;

    setColor(tool.getOption("color"));
    setBorderColor(tool.getOption("borderColor"));
  }, [tool, stateId.id]);

  const handleColorChange = (color: string) => {
    setColor(color);
    tool?.setOption("color", color);
  };

  const handleBorderColorChange = (color: string) => {
    setBorderColor(color);
    tool?.setOption("borderColor", color);
  };

  return (
    <div className="flex flex-col gap-2 w-full">
      <Row
        title="Background"
        component={
          <ColorPicker
            color={color}
            onColorPick={(color) => handleColorChange(color)}
          />
        }
      />
      <Row
        title="Border"
        component={
          <ColorPicker
            color={borderColor}
            onColorPick={(color) => handleBorderColorChange(color)}
          />
        }
      />
      <LineWidthRow />
      <LineStyleRow />
    </div>
  );
};

export default Magbox;
