import { AbstractTool } from "chart";
import { Preset, Tool } from "common";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useRef, useState } from "react";
import { AiOutlinePushpin } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import useIsMobile from "src/hooks/useIsMobile";
import useTitleToolSize from "src/hooks/useTitleToolSize";
import useToolPreset from "src/hooks/useToolPreset";
import { deletePreset } from "src/redux/features/counter/presetsSlice";
import { removeDefaultPreset, setDefaultPreset } from "src/redux/features/counter/settingsSlice";
import { setSheetOpen } from "src/redux/features/mobile/mobileAppSlice";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { TitleButton } from "../TitleButton";
import TitleButtonContainer from "../TitleButtonContainer";
import NewPresetModal from "./NewPresetModal";

type PresetContainerProperties = {
  tool: AbstractTool;
  showPresets: boolean;
  setShowPresets: (_: boolean) => void;
}

var disabled = false;

const PresetContainer = ({ tool, showPresets, setShowPresets }: PresetContainerProperties) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const isMobile = useIsMobile();
  const toolSize = useTitleToolSize();
  const toolPreset = useToolPreset();
  const dispatch = useAppDispatch();

  const [showSave, setShowSave] = useState(false);

  const presets = useAppSelector((state) => state.presets.presets[tool?.typeName as Tool]);
  const defaultPreset = useAppSelector((state) => {
    return state.settings.defaultPreset ? state.settings.defaultPreset[tool?.typeName as Tool] ?? '' : ''
  });

  const onPresetItemHover = (preset: Preset) => {
    if (!tool.objectId) return;
    if (isMobile) return;
    if (!showPresets) return;
    if (disabled) {
      return;
    }

    toolPreset.save(tool);
    toolPreset.hoverStart(tool, preset);
  }

  const onPresetItemHoverEnd = (preset: Preset) => {
    if (!showPresets) return;
    if (isMobile) return;
    if (disabled) {
      return;
    }

    toolPreset.hoverEnd(tool, preset);
    toolPreset.restore(tool);
  }

  const onPresetSelect = useCallback((preset: Preset) => {
    if (tool === null) return;

    setShowPresets(false);

    toolPreset.set(tool, preset);
    if (isMobile) dispatch(setSheetOpen(false));
  }, [tool, isMobile]);

  const onNew = useCallback(() => {
    if (tool === null) return;
    setShowSave(true);
  }, [tool]);

  const handleDefaultPreset = useCallback((preset: Preset) => {
    if (preset.id == defaultPreset) {
      dispatch(removeDefaultPreset(tool.typeName as Tool));
    } else {
      dispatch(setDefaultPreset([tool.typeName as Tool, preset.id]))
    }
  }, [tool, defaultPreset]);

  const handleDeletePreset = useCallback(async (preset: Preset) => {
    if (preset.id == defaultPreset) {
      dispatch(removeDefaultPreset(tool.typeName as Tool));
    }

    await dispatch(deletePreset({ tool: tool.typeName, preset }));
  }, [tool]);

  const handleSaveCurrentSettings = useCallback(() => {
    setShowSave(true);
  }, [tool]);

  const handleAnimationStart = () => {
    disabled = true;
  };

  const handleAnimationComplete = () => {
    disabled = false;
  };

  return (
    <>
      <AnimatePresence>
        {showSave && (<NewPresetModal tool={tool} onClose={() => setShowSave(!showSave)} />)}
      </AnimatePresence>

      <AnimatePresence>
        {showPresets && (
          <motion.div
            data-presetcontainer
            ref={containerRef}
            initial={{
              height: 0,
              opacity: 0,
            }}
            animate={{
              height: 'auto',
              opacity: 1
            }}
            exit={{
              height: 0,
              opacity: 0,
            }}
            onAnimationStart={handleAnimationStart}
            onAnimationComplete={handleAnimationComplete}
            style={{
              pointerEvents: disabled ? 'none' : 'auto',
            }}
          >
            <div
              className="flex flex-col text-xs p-1 divide-white dark:divide-gray-900 bg-white dark:bg-gray-900 border-b border-x shadow mr-1 border-gray-200 dark:border-gray-950">

              {(!presets || presets.length == 0) && (
                <div className="flex items-center justify-between gap-3 dark:text-gray-500 p-2">
                  <div className="flex flex-col gap-1">
                    <div className="font-semibold text-gray-800 dark:font-bold dark:text-white">
                      No presets for {tool.typeDisplayName}
                    </div>
                    <div className="text-xxs leading-snug">
                      Create your first preset from the current settings
                    </div>
                  </div>
                  <div
                    onClick={onNew}
                    className="flex gap-2 items-center bg-gray-100 dark:bg-black dark:text-white border border-transparent hover:text-trtp-primary dark:hover:border-trtp-primary rounded px-2 py-1 cursor-pointer">
                    <FaPlus />
                    Create
                  </div>
                </div>
              )}

              {(presets && presets.length > 0) && (
                <div className="pt-1 pb-2 flex items-center justify-between gap-3 dark:text-gray-500 pl-2 ">
                  <div className="font-semibold text-gray-800 dark:font-bold dark:text-white">
                    Presets
                  </div>
                  <div
                    onClick={handleSaveCurrentSettings}
                    className="flex gap-2 items-center bg-gray-100 dark:bg-black dark:text-white border border-transparent hover:text-trtp-primary dark:hover:border-trtp-primary rounded px-2 py-1 cursor-pointer">
                    <FaPlus />
                    Create
                  </div>
                </div>
              )}

              <AnimatePresence initial={false}>
                {(presets && presets.length > 0) && presets.map((preset: Preset, index: number) => (
                  <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    key={index}
                    onMouseOver={() => onPresetItemHover(preset)}
                    onMouseOut={() => onPresetItemHoverEnd(preset)}
                    className={`transition-all ${isMobile ? "h-9" : "h-8"} group flex gap-2 justify-between bg-gray-50 dark:bg-gray-950 hover:bg-gray-100 dark:text-gray-300 p-1 pl-3  border border-gray-50 dark:border-black dark:hover:border-trtp-primary`}>
                    <div
                      className="cursor-pointer my-auto flex-1 flex gap-3 rounded-sm group-hover:text-trtp-primary dark:group-hover:text-white"
                      onClick={() => onPresetSelect(preset)}>
                      <div>{preset.name}</div>
                      {defaultPreset == preset.id && (
                        <div className="text-gray-600">default</div>
                      )}
                    </div>
                    <div className={`${!isMobile && 'hidden'} group-hover:block`}>
                      <TitleButtonContainer>
                        <TitleButton toolposition={"left"} tooltip={'Set default'} onClick={() => handleDefaultPreset(preset)} activated={preset.id == defaultPreset}>
                          <AiOutlinePushpin size={toolSize} />
                        </TitleButton>
                        <TitleButton toolposition={"right"} confirm={true} activated={false} tooltip={'Delete ' + preset.name} onClick={() => handleDeletePreset(preset)}>
                          <FaTimes size={toolSize} />
                        </TitleButton>
                      </TitleButtonContainer>
                    </div>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default PresetContainer;
