import { Tools } from "common";
import { FaTimes } from "react-icons/fa";
import Modal from "./component/Modal";
import { showKeyboardHelp } from "./redux/features/counter/appSlice";
import { useAppDispatch } from "./redux/hooks";

enum Context {
  Tools = "tools",
  Application = "application",
  Gann = "gann",
  Chart = "chart"
}

type Shortcut = {
  key: string[],
  name: string
}

const SHORTCUTS: { [key: string]: Shortcut[] } = {
  [Context.Application]: [
    {
      key: [ "Tab" ],
      name: "Toggle menu"
    },
    {
      key: [ "ctrl", "q" ],
      name: "Switch to properties panel"
    },
    {
      key: [ "ctrl", "w" ],
      name: "Switch to forex calender panel"
    },
    {
      key: [ "ctrl", "e" ],
      name: "Switch to checklist panel"
    },
    {
      key: [ "ctrl", "r" ],
      name: "Switch to settings panel"
    },
    {
      key: [ "?" ],
      name: "Show keyboard shortcuts"
    },
    {
      key: [ "ctrl", "o" ],
      name: "Open Tesseract Pro Discord"
    },
  ],

  [Context.Chart]: [
    {
      key: [ "=" ],
      name: "Zoom current cycle into view"
    },
    {
      key: [ "o" ],
      name: "Annotation mode"
    },
    {
      key: [ "ctrl", "d" ],
      name: "Duplicate current selected tool"
    },
    {
      key: [ "m" ],
      name: "Measure mode"
    },
  ],
  [Context.Tools]: Object.values(Tools).filter((tool) => {
    return tool.shortcut != undefined;
  }).map((tool) => {
    let key = [tool.shortcut?.key ?? ''];
    let {name} = tool;

    if (tool.shortcut?.ctrl) {
      key.push('ctrl');
    }

    return { key, name }
  }),
  // [
  //   {
  //     key: [ "a" ],
  //     name: "Turningpoint"
  //   },
  //   {
  //     key: [ "s" ],
  //     name: "Target marker"
  //   },
  //   {
  //     key: [ "backspace" ],
  //     name: "Delete selected"
  //   },
  //   {
  //     key: [ "h" ],
  //     name: "Horizontal Ray"
  //   },
  //   {
  //     key: [ "g" ],
  //     name: "Gann grid"
  //   },
  //   {
  //     key: [ "k" ],
  //     name: "Angle Line"
  //   },
  //   {
  //     key: [ "v" ],
  //     name: "Vertical Ray"
  //   },
  //   {
  //     key: [ "i" ],
  //     name: "Text"
  //   },
  //   {
  //     key: [ "j" ],
  //     name: "Arrow"
  //   },
  //   {
  //     key: [ "l" ],
  //     name: "Line"
  //   },
  //   {
  //     key: [ "z" ],
  //     name: "Rectangle"
  //   },
  //   {
  //     key: [ "x" ],
  //     name: "Protractor"
  //   },
  // ],
  [Context.Gann]: [
    {
      key: [ "y" ],
      name: "Gann Circle"
    },
    {
      key: [ "q" ],
      name: "Gann XS"
    },
    {
      key: [ "w" ],
      name: "Gann size S"
    },
    {
      key: [ "e" ],
      name: "Gann size M"
    },
    {
      key: [ "r" ],
      name: "Gann size L"
    },
    {
      key: [ "t" ],
      name: "Gann size XL"
    },
    {
      key: [ "f" ],
      name: "Grid/Fan"
    },
    {
      key: [ "c" ],
      name: "Focus mode"
    }
  ],
};

const Box = ({ name, description, type }: { name: string, description: string, type: Context }) => {
  return (
    <div>
      <div className="text-gray-800 dark:text-white px-4 py-2 text-sm flex justify-between">
        <div className={"font-semibold"}>{name}</div>
        <div className={"text-gray-600 dark:text-gray-400 text-xs"}>{description}</div>
      </div>
      <div className="text-white border dark:border-gray-800 flex flex-col divide-y divide-gray-200 dark:divide-gray-800 rounded">
        {SHORTCUTS[type].map((mapping: Shortcut) => (
          <div className="bg-white dark:bg-black px-2 py-2 text-xs flex justify-between">
            <div className="my-auto pl-2 text-gray-600 dark:text-gray-400">{mapping.name}</div>
            <div className="flex gap-3">
              {mapping.key.map((key: string) => (
                <div className="py-1 px-3 bg-gray-400 dark:bg-gray-900 dark:text-gray-500 rounded" style={{minWidth: "30px"}}>{key}</div>
              ))}
            </div> 
          </div>
        ))}
      </div>
    </div>
  );
}

const KeyboardShortcuts = () => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(showKeyboardHelp(false));
  }

  return (
      <Modal handleClose={handleClose}>
      <div className="flex w-screen h-screen">
        <div className="flex flex-col m-auto bg-gray-100 dark:bg-gray-950 w-1/2 h-2/3 rounded p-4 pt-2 overflow-y-auto">
          <div className="flex justify-between py-4">
            <div className="text-gray-500 dark:text-white font-bold ">Keyboard shortcuts</div>
            <div className="text-gray-500 dark:text-gray-500 hover:text-trtp-primary cursor-pointer">
              <FaTimes size={22} onClick={handleClose}/>
            </div>
          </div>
          <div className="grid gap-2 ">
            <Box name="Application" description={""} type={Context.Application} />
            <Box name="Chart"  description={""} type={Context.Chart} />
            <Box name="Tools"  description={""} type={Context.Tools} />
            <Box name="Gann"  description={"When turningpoint is selected"} type={Context.Gann} />
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default KeyboardShortcuts;
