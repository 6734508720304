import { TbRulerMeasure } from "react-icons/tb";
import Mode from "./Mode";

const Measure = () => {
  return (
    <Mode
      text={"Measure"}
      mode={"measure"}
      keybinding={"m"}
      icon={<TbRulerMeasure size={20} />}
    />
  );
};

export default Measure;
