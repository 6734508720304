import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { MarketInterface } from "common"

interface MarketsState {
  active: string;
  markets: MarketInterface[]

  current_prices: { [key: string]: number };
}

const initialState: MarketsState = {
  active: document.location.hash.trim() != "" ? document.location.hash.substr(1) : 'btcusdt',
  markets: [],
  current_prices: {}
};

export const marketsSlice = createSlice({
  name: "markets",
  initialState,
  reducers: {
    setAssetPrice: (state, action: PayloadAction<[string, number]>) => {
      state.current_prices = { ...state.current_prices, ...{ [action.payload[0]]: action.payload[1] } };
    },

    setMarkets: (state, action: PayloadAction<MarketInterface[]>) => {
      state.markets = action.payload;
    },

    patchSetting: (state, action: PayloadAction<{ market: string, key: string, value: string | boolean | number }>) => {
      const marketIndex = state.markets.findIndex((m) => m.identifier == action.payload.market);
      if (marketIndex == -1) return;

      /** @ts-ignore */
      state.markets[marketIndex][action.payload.key] = action.payload.value;
    },

    patchPriceSetting: (state, action: PayloadAction<{ timeframe: number, market: string, value: number }>) => {
      const marketIndex = state.markets.findIndex((m) => m.identifier == action.payload.market);
      if (marketIndex == -1) return;

      state.markets[marketIndex].mts_pricelines[action.payload.timeframe] = action.payload.value;
    },

    selectMarket: (state, action: PayloadAction<string>) => {
      document.location.hash = action.payload;
      state.active = action.payload;
    },

    setFavorite: (state, action: PayloadAction<string>) => {
      const marketIndex = state.markets.findIndex((m) => m.identifier == action.payload);
      if (marketIndex == -1) return;

      state.markets[marketIndex].is_favorite = true;
    },

    removeFavorite: (state, action: PayloadAction<string>) => {
      const marketIndex = state.markets.findIndex((m) => m.identifier == action.payload);
      if (marketIndex == -1) return;

      state.markets[marketIndex].is_favorite = false;
    },

    createMarket: (state, action: PayloadAction<string>) => {
      state.markets = [...state.markets, {
        isnew: true,
        active: true,
        continuous: true,
        currency: "",
        identifier: action.payload,
        mts_pricelines: { "0.5": 1, 2: 1, 8: 1, 32: 1, 128: 1, 512: 1, 2048: 1 },
        name: "",
        precision: 2,
        provider: "binance",
        provider_id: "",
        symbol: "",
        asset_type: "",
        is_favorite: false
      }]
    },
  }
});

export const {
  setMarkets,
  patchSetting,
  patchPriceSetting,
  createMarket,
  selectMarket,
  setAssetPrice,
  setFavorite,
  removeFavorite
} = marketsSlice.actions;

export default marketsSlice.reducer;
