import { useEffect, useRef } from "react";
import Draggable from "react-draggable";
import { MdDragIndicator } from "react-icons/md";

import Toolbar from "./component/Toolbar"
import Splitter from "./component/Toolbar/buttons/Splitter";
import FunctionButtons from "./component/Toolbar/FunctionButtons";

const FloatingToolbar = () => {
  const toolbarRef = useRef<HTMLDivElement>(null);

  const triggerMouseEvent = (element: any, eventType: string) => {
    const mouseEvent = new MouseEvent(eventType, {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    element.dispatchEvent(mouseEvent);
  };

  /**
   * We simulate the clicking on the toolbar when the
   * browser window resizes. This will re-evaluate the toolbar
   * bounds
   */
  useEffect(() => {
    const simulateDrag = () => {
      const el = document.querySelector(".drag");

      triggerMouseEvent(el, "mouseover");
      triggerMouseEvent(el, "mousedown");

      window.requestAnimationFrame(() => {
        triggerMouseEvent(document, "mousemove");
        triggerMouseEvent(el, "mouseup");
        triggerMouseEvent(el, "click");
      });
    };

    simulateDrag();

    const resizeObserver = new ResizeObserver(simulateDrag);
    resizeObserver.observe(document.querySelector("body") as HTMLBodyElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const getDefaultPosition = () => {
    const position = localStorage.getItem("mts_toolbar_position");
    if (!position) return { x: 0, y: 0 };

    return JSON.parse(position);
  };

  const savePosition = (_: any, data: { x: number; y: number }) => {
    localStorage.setItem(
      "mts_toolbar_position",
      JSON.stringify({ x: data.x, y: data.y })
    );
  };

  console.info("rerender tb");

  return (
    <Draggable
      bounds="body"
      handle=".drag"
      defaultPosition={getDefaultPosition()}
      onStop={savePosition}
    >
      <div
        ref={toolbarRef}
        className="flex flex-wrap justify-start p-4 gap-5 text-gray-500 bg-gray-100 shadow-lg cursor-default dark:shadow-2xl hover:opacity-100 opacity-70 transition-opacity dark:bg-gray-950 rounded-md"
        style={{
          position: "absolute",
          zIndex: 500,
        }}
      >
        <MdDragIndicator
          size={20}
          className={`cursor-move drag text:text-gray-400`}
        />
        <Toolbar />
        <Splitter />
        <FunctionButtons />
      </div>
    </Draggable>
  );
};

export default FloatingToolbar;
