import { useEffect, useState } from "react";

import ColorPicker from "src/component/ColorPicker";
import useStateId from "src/hooks/useStateId";
import useTool from "src/hooks/useTool";
import Row from "./Row";
import LineStyleRow from "./Row/LineStyleRows";
import LineWidthRow from "./Row/LineWidthRow";
import TextRow from "./Row/TextRow";

const HorizontalRay = () => {
  const [color, setColor] = useState<string>("");

  const tool = useTool();
  const stateId = useStateId();

  useEffect(() => {
    if (tool === null) return;

    setColor(tool.getOption("color", ""));
  }, [tool, stateId.id]);

  const handleChange = (color: string) => {
    setColor(color);
    tool?.setOption("color", color);
  };

  return (
    <div className="flex flex-col gap-2">
      <TextRow label={"Label"}/>  
      <Row
        title="Color"
        component={
          <ColorPicker
            color={color}
            onColorPick={(color) => handleChange(color)}
          />
        }
      />
      <LineWidthRow />
      <LineStyleRow />
    </div>
  );
};

export default HorizontalRay;
