type RowProps = {
  title: string;
  component: React.ReactNode;
};
const Row = ({ title, component }: RowProps) => {
  return (
    <div className="flex flex-row w-full justify-center select-none">
      <div className="flex mb-auto p-2 px-4 truncate justify-end text-right w-32 text-xs">{title}</div>
      <div
        className="flex-1 bg-gray-50 dark:bg-gray-950 flex p-1 mr-2"
      >
        {component}
      </div>
    </div>
  );
};

export default Row;
