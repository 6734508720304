import { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useChart } from "./context/ChartContext";
import useCriterion from "./hooks/useCriterion";
import useStateId from "./hooks/useStateId";
import useTurningPoint from "./hooks/useTurningPoint";

const Header = ({ name }: { name: string }) => {
  return <div className="text-xs p-2 pl-4  bg-gray-50 dark:bg-gray-950">{name}</div>;
};

const Value = ({ name, value }: { name: string; value: number }) => {
  const [checked, setChecked] = useState(false);
  const chartProvider = useChart();

  const onPriceHover = () => {
    const chart = chartProvider.current();

    /** @ts-ignore */
    const markers = chart?.markers;

    markers?.add(value.toString(), { price: value, text: name });
  };

  const onPriceLeave = () => {
    const chart = chartProvider.current();

    /** @ts-ignore */
    const markers = chart?.markers;

    markers?.remove(value.toString());
  };

  return (
    <div
      className="flex items-center hover:bg-gray-50 dark:hover:bg-gray-950 text-selectable pr-2 pl-4"
      onMouseEnter={() => onPriceHover()}
      onMouseLeave={() => onPriceLeave()}
    >
      {checked && <FaCheckCircle className="text-selected" />}
      <div className="flex-1 text-xs" onClick={() => setChecked(!checked)}>
        {name}
      </div>

      <div className="text-sm hover:bg-gray-100 dark:hover:bg-gray-950 p-2 rounded-md">
        ${value}
      </div>
    </div>
  );
};

const TradePanel = () => {
  const [start, setStart] = useState(-1);
  const [end, setEnd] = useState(0);
  const [score, setScore] = useState(0);

  const criterion = useCriterion();
  const { id: stateId } = useStateId();

  const turningPoint = useTurningPoint();
  const chartProvider = useChart();

  useEffect(() => {
    if (!turningPoint) return;

    const chart = chartProvider.current();

    const start = Math.floor(turningPoint?.getPrice() || 0);
    setStart(start);

    const end = Math.floor(chart?.turningpoints?.forecast?.gannPrice || 0);
    setEnd(end);

    const score = turningPoint?.getOption("score", 0 /*2046*/) || 0;
    const rewardLevel = turningPoint?.getOption("reward_level", 1) || 1;
    setScore(criterion.calculateScore(score, rewardLevel).score);
  }, [stateId, chartProvider, criterion, turningPoint]);

  return (
    <div className="flex flex-col gap-0  h-full overflow-y-auto">
      {score <= 0 ? (
        <div className="flex flex-col font-semibold text-center text-red-500 text-xs max-w-[200px] mx-auto my-10">
          Score too low
        </div>
      ) : (
        <>
          <Value
            name={"Entry"}
            value={criterion.calculateTarget(0, start, end)}
          />
          <Value name={"Stop loss"} value={start} />
          <Header name={"Targets"} />
          <Value
            name={"Safe"}
            value={criterion.calculateTarget(1, start, end)}
          />
          <Value
            name={"Moderate"}
            value={criterion.calculateTarget(2, start, end)}
          />
          <Value
            name={"Max"}
            value={criterion.calculateTarget(3, start, end)}
          />
        </>
      )}
    </div>
  );
};

export default TradePanel;
