import { useConfig } from "../context/ConfigContext";

const useRestBackend = () => {
  const { restBackendUrl, session } = useConfig();

  // TODO: add post calls
  const restCall = async (url: string, props: object = {}) : Promise<any> => {
      const response = await fetch(
        restBackendUrl + url,
        {
          ...props,
          headers: new Headers({
            // @ts-ignore
           Authorization: `Bearer ${session.access_token}`,
          'Content-Type': 'application/json',
          })
        });

      const data = await response.json();

      return data;
  }

  return { restCall };
}

export default useRestBackend;
