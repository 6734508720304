import { Chart as MtopsChart } from 'chart';
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

const CandleCountDown = ({ timeframe, chart }: { timeframe: number, chart: MtopsChart }) => {
  const [countDown, setCountDown] = useState<string>("");

  useEffect(() => {
    const calculate = () => {
      const candle = chart.data.getByIndex(0);

      if (candle.close === 0) return;

      const now = DateTime.utc();
      const date = DateTime.fromSeconds(candle.date + timeframe * 60);

      if (date < now) {
        setCountDown("00:00:00");
      } else {
        const diff = date.diff(now, ['hours', 'minutes', 'seconds']);
        const diffFormatted = diff.toFormat('hh:mm:ss');
        setCountDown(diffFormatted);
      }
    }

    const interval = setInterval(calculate, 1000)

    calculate();
    chart.event.on('data:load', calculate);

    return () => {
      chart.event.off('data:load', calculate);
      clearInterval(interval);
    }
  }, [chart]);

  if (countDown == "") {return <></>;}
  return (
    <>{countDown}</>
  );
}

export default CandleCountDown;
