
type DebounceFunction = (...args: any[]) => void;

export default function debounce<T extends DebounceFunction>(
  func: T,
  delay: number
): (...args: Parameters<T>) => void {
  let timerId: NodeJS.Timeout | null;

  let immediatelyTriggered = false;

  return function debounced(...args: Parameters<T>): void {
    clearTimeout(timerId!);

    timerId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

