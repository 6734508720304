import {Chart as MtopsChart} from "chart";
import React, { useContext } from "react";
import { PageType, setPage, setSheetOpen } from "src/redux/features/mobile/mobileAppSlice";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";

export type OptionalChart = MtopsChart | null;

type MobileAppControllerContextProperties = {
  switchPage: (page: PageType) => void;
  openPage: (page: PageType) => void;
  closePage: () => void;
};

export const MobileAppControllerContext = React.createContext<MobileAppControllerContextProperties>({
  switchPage: (_: PageType) => {},
  openPage: (_: PageType) => {},
  closePage: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const MobileAppControllerProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();

  // Switches the page when its already open 
  const switchPage = (page: PageType) => {
      dispatch(setSheetOpen(false));
      
      setTimeout(() => {
        dispatch(setPage(page))
        dispatch(setSheetOpen(true));
      }, 200)
  };

  const openPage = (page: PageType) => {
      dispatch(setPage(page))
      dispatch(setSheetOpen(true));
  }

  const closePage = () => {
      dispatch(setPage("chart"))
      dispatch(setSheetOpen(false));
  }

  return (
    <MobileAppControllerContext.Provider
      value={{ switchPage, openPage, closePage }}>
      {children}
    </MobileAppControllerContext.Provider>
  );
};

export const useMobileAppController = () => useContext(MobileAppControllerContext);
