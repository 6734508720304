import { ReactComponent as TrtpLogo } from '../../../trtp-logo.svg';
import { ReactComponent as TrtpLogoWhite } from '../../../trtp-logo-white.svg';
import useColorScheme from 'src/hooks/useColorscheme';

const BrandingLogo = () => {
  const theme = useColorScheme();

  return (
    <div className="absolute top-[10px] right-[60px] flex gap-2 z-50 dark:text-white bg-white p-1 rounded dark:bg-black pointer-events-none" style={{zIndex: '5'}}>
      {theme == "dark" ? (
        <TrtpLogoWhite className="my-auto" style={{ width: '15px', height: '15px'}} />
      ): (
        <TrtpLogo className="dark:invert my-auto" style={{ width: '15px', height: '15px'}} />
      )}
      <div className="flex flex-col my-auto select-none">
       <div className="text-xxs font-semibold" style={{zIndex: 200}}>
          Tesseract Pro
        </div>
      </div>
    </div>
  );
};

export default BrandingLogo;
