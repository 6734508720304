
import { useMemo } from "react";
import { useAppSelector } from "../../../redux/hooks";

const Splitter = () => {
  const toolbarPosition = useAppSelector((state) => state.settings.toolbarPosition);

  const borderPosition = useMemo(() => {
    switch(toolbarPosition) {
      case "left":
      case "right":
        return "border-t";
      default: 
        return "border-l";
    }
  }, [toolbarPosition]);


  return <div className={`${borderPosition} border-gray-400 dark:border-gray-800`}></div>;
};

export default Splitter;
