
import { useEffect, useRef, useState } from "react";

import { MarketInterface, ASSETS, Asset } from "common";

import { useSocket } from "../../context/SocketContext";
import Title from "../../properties/Title";
import { setAssetPrice } from "../../redux/features/counter/marketsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import AssetChip from "../Mobile/MarketsPanel/AssetChip";
import MarketRow from "./MarketsPanel/MarketRow";
import { useConfig } from "src/context/ConfigContext";

const MarketsPanel = ({ afterSelect = () => { } }: { afterSelect?: () => void }) => {
  const { sockets } = useSocket();

  const [assetType, setAssetType] = useState<string>("");

  const dispatch = useAppDispatch();
  const markets = useAppSelector((root) => root.markets.markets);

  const config = useConfig();

  /** @ts-ignore */
  const plan = config.session.tpro.pln;

  useEffect(() => {
    if (!sockets?.mts) return;
    if (markets.length == 0) return;

    const onPriceChange = (data: [string, string]) => {
      dispatch(setAssetPrice([data[0], parseFloat(data[1])]))
    }

    sockets.mts.emit('pricechange:subscribe');
    sockets.mts.on('pricechange', onPriceChange);

    return () => {
      sockets?.mts?.emit('pricechange:unsubscribe');
      sockets?.mts?.off('pricechange', onPriceChange);
    }
  }, [sockets]);

  const filterMarkets = (market: MarketInterface) => {
    if (assetType != "") {
      if (market.asset_type != assetType) {
        return false;
      }
    }

    return true;
  };

  return (
    <div className="min-h-[400px] max-h-[400px] flex flex-col gap-2 overflow-y-auto text-xs dark:text-white flex-1">
      <div className="flex flex-col flex-1 overflow-y-auto">
        <Title>
          <div className="flex gap-2 px-3 overflow-x-auto">
            <AssetChip text={"All"} selected={assetType == ""} onSelect={() => setAssetType("")} />
            {ASSETS.filter((asset: Asset) => {
              if (plan != 0) return true;
              if (asset.id == "future") return false;

              return true;
            }).map((asset: Asset) => (
              <AssetChip
                key={asset.id}
                text={asset.name}
                selected={assetType == asset.id}
                onSelect={() => setAssetType(asset.id)}
              />
            ))}
          </div>
        </Title>

        {markets
          .filter(filterMarkets)
          .filter((value) => value.is_favorite == true)
          .filter((value) => {
            if (value.asset_type == 'future' && plan == 0) return false;
            return true;
          })
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((market) => (
            <MarketRow
              key={market.identifier}
              market={market}
              afterSelect={afterSelect} />
          ))}

        {markets
          .filter(filterMarkets)
          .filter((value) => value.is_favorite == false)
          .filter((value) => {
            if (value.asset_type == 'future' && plan == 0) return false;
            return true;
          })
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((market) => (
            <MarketRow
              key={market.identifier}
              market={market}
              afterSelect={afterSelect} />
          ))}
      </div>
    </div>
  );
}

export default MarketsPanel;
