import { useChart } from "src/context/ChartContext";
import useTurningPoint from "src/hooks/useTurningPoint";
import SelectTurningPointMessage from "src/SelectTurningPointMessage";
import ToolTitle from "./ToolTitle";
import FixedScaleOption from "./turningpoint/FixedScaleOption";
import FocusOption from "./turningpoint/FocusOption";
import GannBoxOption from "./turningpoint/GannboxOption";
import GannDoubleArcsOption from "./turningpoint/GannDoubleArcsOption";
import GannFibOption from "./turningpoint/GannFibOption";
import GridOption from "./turningpoint/GridOption";
import ScaleOption from "./turningpoint/ScaleOption";
import SupportResistanceOption from "./turningpoint/SupportResistanceOption";
import TurningPointNumberOption from "./turningpoint/TurningPointNumberOption";

const TurningPoint = () => {
  const turningPoint = useTurningPoint();

  const chartProvider = useChart();
  const chart = chartProvider.current();

  if (!turningPoint) return <SelectTurningPointMessage />;

  return (
    <div className="flex flex-col gap-2">
      <ToolTitle allowDuplicate={false} tool={turningPoint}>Turning Point {turningPoint.getNumber()}</ToolTitle>
      <TurningPointNumberOption turningPoint={turningPoint} chart={chart} />
      <SupportResistanceOption turningPoint={turningPoint} chart={chart} />
      <ScaleOption turningPoint={turningPoint} chart={chart} />
      <FocusOption turningPoint={turningPoint} chart={chart} />
      <FixedScaleOption turningPoint={turningPoint} chart={chart} />
      <GridOption turningPoint={turningPoint} chart={chart} />

      <GannBoxOption turningPoint={turningPoint} chart={chart} />
      <GannDoubleArcsOption turningPoint={turningPoint} chart={chart} />
      <GannFibOption turningPoint={turningPoint} chart={chart} />
    </div>
  );
};

export default TurningPoint;
