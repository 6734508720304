import { getModeName } from 'common';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useChart } from 'src/context/ChartContext';
import { setMode } from 'src/redux/features/counter/chartSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import ReplayControls from './Mode/ReplayControls';

const ModeLabel = ({ timeframe }: { timeframe: number }) => {
  const dispatch = useAppDispatch();

  const mode = useAppSelector((state) => state.chart.mode[timeframe]);
  const stateId = useAppSelector((state) => state.chart.stateId);
  const [placingToolName, setPlacingToolName] = useState("");
  const chartProvider = useChart();

  useEffect(() => {
    const chart = chartProvider.get(timeframe);
    if (!chart) return;

    setPlacingToolName(chart.tools.currentTool?.typeDisplayName || "");
  }, [stateId, chartProvider]);

  return (
    <AnimatePresence>
      {!(mode == undefined || mode == "" || mode == "graph") && (

        <motion.div
          initial={{ opacity: 0 }}
          exit={{ opacity: 0}}
          animate={{ opacity: 1 }}
          transition={{duration: 0.2}}
          className="bg-trtp-primary rounded-sm flex flex-col select-none absolute z-50 top-12 right-12 cursor-default pointer-events-none"
          data-html2canvas-ignore
        >
          <div
            className="text-center text-xs px-5 py-2 text-white">
            {mode == "placetool" ? (
              <>{placingToolName}</>
            ) : (
              <>{getModeName(mode)}</>
            )}
          </div>
          {mode == "replay" && (
            <ReplayControls />
          )}
          <div
            className="bg-gray-100 dark:bg-gray-900 text-center text-xs py-2 dark:text-white cursor-pointer pointer-events-auto rounded-b-sm"
            onClick={() => {
              dispatch(setMode([timeframe, "graph"]));
            }}
          >
            Exit
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default ModeLabel;
