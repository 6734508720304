import Sheet from 'react-modal-sheet';
import { useAppSelector } from "./redux/hooks";
import { useDispatch } from "react-redux";

import Chart from "./Chart";
import useSetTurningpoints from "./hooks/useSetTurningpoints";
import { useSocket } from "./context/SocketContext";
import ChartLoading from "./ChartLoading";
import MarketsPanel from "./component/Mobile/MarketsPanel";
import { setTimeframe } from "./redux/features/counter/chartSlice";
import SettingsPanel from "./SettingsPanel";
import DrawPanel from "./component/Mobile/DrawPanel";
import useTimeframes from "./hooks/useTimeframes";
import PropertiesPanel from "./PropertiesPanel";
import BrandingLogo from "./component/Mobile/Header/BrandingLogo";
import { timeframeName, TIMEFRAME_NAME } from "./lib/timeframeName";
import ToolsPanel from "./ToolsPanel";

import "./App.css";
import "./override/react-modal-sheet.css";
import "./override/react-color.css";

import { MdOutlineAdd, MdOutlineSettingsInputComponent } from 'react-icons/md';
import { FaRegObjectGroup } from 'react-icons/fa';
import Overwatch from './Overwatch';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import MorePanel from './component/Mobile/MorePanel';
import ModeIndicator from './component/Mobile/ModeIndicator';
import { IoIosArrowDown } from 'react-icons/io';
import { PageType } from './redux/features/mobile/mobileAppSlice';
import { useMobileAppController } from './context/MobileAppControllerContext';
import Halloween from './FunCalendarEvents/Halloween';
import LockedScalingIndicator from './component/Mobile/LockedScalingIndicator';
import { DateTime } from 'luxon';
import FreeBanner from './FreeBanner';
import Christmas from './FunCalendarEvents/Christmas';
import NewYear from './FunCalendarEvents/NewYear';
import { BiUndo } from 'react-icons/bi';
import FloatingToolbar from './component/Mobile/FloatingToolbar';

type PageButtonType = {
  page: PageType,
  icon: JSX.Element,
  highlight: boolean
};

const pageButtons: PageButtonType[] = [
  { page: "tools", icon: <FaRegObjectGroup size={18} />, highlight: false },
  { page: "properties", icon: <MdOutlineSettingsInputComponent size={18} />, highlight: false },
  { page: "more", icon: <HiOutlineDotsVertical size={18} />, highlight: false },
  { page: "draw", icon: <MdOutlineAdd size={18} />, highlight: true },
];

const PageButton = ({ children, onClick, highlight }: { children: JSX.Element, highlight: boolean, onClick: () => void }) => {
  return (
    <div
      className={`shadow flex font-medium ${highlight ? "bg-trtp-primary text-white px-4" : "bg-gray-100 dark:bg-gray-900 px-2 text-gray-500 dark:text-gray-300"} rounded-md`}
      onClick={onClick}>
      <div className="my-auto">{children}</div>
    </div>
  )
}

function AppMobile() {
  const dispatch = useDispatch();
  const { connected } = useSocket();
  const { timeframes } = useTimeframes();

  const controller = useMobileAppController();

  const activeTimeframe = useAppSelector((state) => state.chart.timeframe);
  const currentMarket = useAppSelector((state) => state.markets.active);
  const market = useAppSelector((root) => root.markets.markets.find((m) => m.identifier == currentMarket));
  const mode = useAppSelector((state) => state.chart.mode[activeTimeframe]);
  const page = useAppSelector((state) => state.mobileApp.page);
  const isOpen = useAppSelector((state) => state.mobileApp.sheetOpen);

  useSetTurningpoints();

  const handleToolDoubleClick = () => {
    controller.openPage("properties");
  }

  const currentDate = DateTime.now().setZone('America/New_York');
  const targetDate = DateTime.fromObject({ year: 2024, month: 11, day: 29 }).setZone('America/New_York');
  const isBlackFriday = false;//(currentDate < targetDate);

  return (
    <>
      <Christmas />
      <NewYear />
      <Halloween />
      <FreeBanner />
      {!connected ? (
        <>
          <div className="absolute top-0 left-0 h-[100svh] w-screen z-40 bg-black opacity-20"></div>
          <div className="absolute top-0 left-0 h-[100svh] w-screen z-50">
            <ChartLoading />
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col h-[100svh] w-screen divide-y-2 divide-gray-100 dark:divide-gray-900">
            <FloatingToolbar />
            {/* note: the fixed height on the overwatch is important for touch input */}
            <div className="bg-gray-50 dark:bg-black h-[67px]"><Overwatch /></div>
            <div className="flex flex-1 relative">
              {/* <Toolbar /> */}
              <BrandingLogo />
              <LockedScalingIndicator />
              <Chart
                timeframe={activeTimeframe}
                onToolDoubleClick={handleToolDoubleClick}
              />
            </div>
            {(mode != "graph" && mode != "create" && mode != undefined && mode != "") ? (
              <div className="h-[54px] bg-trtp-primary px-2 py-2 dark:text-white z-50 rounded-t">
                <ModeIndicator />
              </div>
            ) : (
              <div className="h-[54px] bg-white px-2 py-2 dark:bg-black dark:text-white z-50">
                <div className={"flex gap-2 text-sm justify-between"} >
                  <div className="flex gap-2 font-medium bg-gray-100 dark:bg-gray-900 px-3 py-2 rounded-md" onClick={() => { controller.openPage("markets"); }}>
                    <div className="uppercase text-gray-600 dark:text-gray-100 my-auto font-semibold truncate max-w-[100px]">{market?.name || ""}</div>
                    <div className="text-gray-400 dark:text-gray-600 text-xs my-auto">{timeframeName(activeTimeframe * 60, TIMEFRAME_NAME.Short)}</div>
                  </div>
                  <div className="flex gap-2 overflow-x-auto p-[1px]">
                    {pageButtons.map((button) => (
                      <PageButton
                        key={button.page}
                        highlight={button.highlight}
                        onClick={() => { controller.openPage(button.page); }}>
                        {button.icon}
                      </PageButton>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <Sheet
              isOpen={isOpen}
              onClose={() => controller.closePage()}
              rootId={"root"}
              snapPoints={[.75]}
              detent={"content-height"}
            >
              <Sheet.Container>
                <Sheet.Header>
                  <div className="grid grid-cols-3 p-2">
                    <div></div>
                    <div className="flex justify-center" onClick={() => controller.closePage()}>
                      <div className="bg-gray-100 dark:bg-gray-900 px-2 py-1 rounded">
                        <IoIosArrowDown className="mx-auto my-auto text-trtp-primary" size={20} />
                      </div>
                    </div>
                    <div className="flex justify-end">
                    </div>
                  </div>
                </Sheet.Header>
                <Sheet.Content className="pb-2">
                  {page == "markets" && (<MarketsPanel afterSelect={() => controller.closePage()} />)}
                  {page == "settings" && (<SettingsPanel />)}
                  {page == "tools" && (<ToolsPanel />)}
                  {page == "draw" && (<DrawPanel afterSelect={() => controller.closePage()} />)}
                  {page == "properties" && (<PropertiesPanel />)}
                  {page == "more" && (<MorePanel afterSelect={() => controller.closePage()} />)}
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop onTap={() => controller.closePage()} />
            </Sheet>
          </div>
        </>
      )}
    </>
  );
}
export default AppMobile;
