import { useState, useEffect } from 'react';
import useStateId from 'src/hooks/useStateId';
import Row from '../Row';

const Number = ({
  num,
  selected,
  onClick,
}: {
  num: number;
  selected: boolean;
  onClick: (num: number) => void;
}) => {
  return (
    <div
      className={`${
        selected
          ? 'bg-trtp-primary text-white dark:text-white'
          : 'dark:hover:bg-gray-900 hover:bg-gray-200 cursor-pointer '
      } uppercase px-2 py-1 transition-all rounded-sm text-center`}
      onClick={() => onClick(num)}>
      {num}
    </div>
  );
};

const TurningPointNumberOption = ({
  turningPoint,
  chart,
}: {
  turningPoint: any;
  chart: any;
}) => {
  const [scale, setScale] = useState<number>(1);
  const { id: stateId } = useStateId();

  useEffect(() => {
    setScale(turningPoint.getNumber());
  }, [turningPoint, chart, stateId]);

  const setLevel = (level: number) => {
    setScale(level);

    turningPoint.setNumber(level);

    chart.event.fire('tools:property_changed', turningPoint);
  };

  return (
    <Row
      title="Number"
      component={
        <div
          className="grid grid-cols-5 dark:bg-black bg-white p-1 select-none"
          style={{ fontSize: '7pt' }}>
          {[1, 3, 5, 7, 9, 2, 4, 6, 8, 10].map((num: number) => (
            <Number
              key={num}
              num={num}
              selected={scale === num}
              onClick={() => setLevel(num)}
            />
          ))}
        </div>
      }
    />
  );
};

export default TurningPointNumberOption;
