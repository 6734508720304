import { useEffect, useState } from "react";

import { FibinaciLevelAngled } from 'chart';

import useTool from "src/hooks/useTool";
import Row from "./Row";
import ColorPicker from 'src/component/ColorPicker';
import { MdOutlineRemoveCircleOutline } from 'react-icons/md';
import Tooltip from 'rc-tooltip';
import Switch, { SwitchOption } from 'src/component/Switch';
import { CgBorderStyleDashed, CgBorderStyleDotted, CgBorderStyleSolid } from 'react-icons/cg';
import LineWidthSwitch from 'src/component/LineWidthSwitch';
import { TitleButton } from "./TitleButton";
import { BiHide, BiShow } from "react-icons/bi";
import { RxAngle } from "react-icons/rx";
import useStateId from "src/hooks/useStateId";

const styles: SwitchOption[] = [
  {
    id: "solid",
    value: 1,
    title: <CgBorderStyleSolid size={16} />,
    tooltip: 'Solid'
  },
  {
    id: "dashed",
    value: 2,
    title: <CgBorderStyleDashed size={16} />,
    tooltip: 'Dashed'
  },
  {
    id: "dotted",
    value: 3,
    title: <CgBorderStyleDotted size={16} />,
    tooltip: 'Dotted'
  },
];

const labelsOptions: SwitchOption[] = [
  {
    id: "off",
    title: "Off",
    value: 0,
  },
  {
    id: "on",
    title: "On",
    value: 1,
  },
];

const GannBox = () => {
  const [timeLevels, setTimeLevels] = useState<FibinaciLevelAngled[]>([]);
  const [priceLevels, setPriceLevels] = useState<FibinaciLevelAngled[]>([]);
  const [color, setColor] = useState<string>("");
  const [style, setStyle] = useState<string>("");
  const [lineWidth, setWidth] = useState<string>("");
  const [labels, setLabels] = useState<boolean>(false);

  const tool = useTool();
  const stateId = useStateId();

  useEffect(() => {
    if (tool === null) return;

    setTimeLevels(tool.getLevels("time"));
    setPriceLevels(tool.getLevels('price'));

    setColor(tool.getOption("color"));
    setLabels(tool.getOption("labels"));
    setStyle(tool.getOption("style"));
    setWidth(tool.getOption("width"));
  }, [tool, stateId.id]);

  useEffect(() => {
    if (color == "") return;

    tool?.setOption("color", color);
  }, [color])

  const handleStyleChange = (newStyle: SwitchOption) => {
    setStyle(newStyle.id);
    tool?.setOption("style", newStyle.id);
  };

  const handleWidthChange = (width: SwitchOption) => {
    setWidth(width.id);
    tool?.setOption("width", width.value);
  };

  const handleLabelsChange = (option: SwitchOption) => {
    tool?.setOption("labels", option.value == 1);
    setLabels(option.value === 1);
  };

  const saveTimeLevels = () => {
    tool?.setLevels(timeLevels, "time");
  }

  const savePriceLevels = () => {
    tool?.setLevels(priceLevels, "price");
  }

  const sortLevels = () => {
    const _timeLevels = [...timeLevels].sort((a, b) => a.level - b.level);
    setTimeLevels([..._timeLevels]);

    const _priceLevels = [...priceLevels].sort((a, b) => a.level - b.level);
    setPriceLevels([..._priceLevels]);
  };

  const removeTimeLevel = (index: number) => {
    const newLevels = [...timeLevels];
    newLevels.splice(index, 1);
    setTimeLevels(newLevels);
    tool?.setLevels(newLevels, "time");
  }

  const removePriceLevel = (index: number) => {
    const newLevels = [...priceLevels];
    newLevels.splice(index, 1);
    setPriceLevels(newLevels);
    tool?.setLevels(newLevels, "price");
  }

  const addTimeLevel = () => {
    const newLevels = [...timeLevels, {
      angle: true,
      visible: true,
      level: timeLevels[timeLevels.length - 1].level + 0.1
    }];

    setTimeLevels(newLevels);
    tool?.setLevels(newLevels, 'time');
  }

  const addPriceLevel = () => {
    const newLevels = [...priceLevels, {
      angle: true,
      visible: true,
      level: priceLevels[priceLevels.length - 1].level + 0.1
    }];

    setPriceLevels(newLevels);
    tool?.setLevels(newLevels, 'price');
  }

  const onShowPriceLevel = (index: number, visible: boolean) => {
    const _levels = [...priceLevels];
    _levels[index].visible = visible;
    setPriceLevels(_levels);
    savePriceLevels();
  }

  const onShowPriceAngle = (index: number, angle: boolean) => {
    const _levels = [...priceLevels];
    _levels[index].angle = angle;
    setPriceLevels(_levels);
    savePriceLevels();
  }

  const onShowTimeLevel = (index: number, visible: boolean) => {
    const _levels = [...timeLevels];
    _levels[index].visible = visible;
    setTimeLevels(_levels);
    saveTimeLevels();
  }

  const onShowTimeAngle = (index: number, angle: boolean) => {
    const _levels = [...timeLevels];
    _levels[index].angle = angle;
    setTimeLevels(_levels);
    saveTimeLevels();
  }

  return (
    <div className="flex flex-col gap-2">
      <Row
        title="Color"
        component={
          <ColorPicker color={color} onColorPick={(color) => {
            setColor(color);
          }} />
        }
      />
      <Row
        title="Style"
        component={
          <Switch
            options={styles}
            selected={style}
            onChange={handleStyleChange}
          />
        }
      />
      <Row
        title="Line width"
        component={
          <LineWidthSwitch selected={lineWidth} onChange={handleWidthChange} />
        }
      />
    <Row
      title="Show labels"
      component={
        <div className="bg-gray-100 dark:bg-black p-1">
          <Switch
            options={labelsOptions}
            selected={labels ? "on" : "off"}
            onChange={handleLabelsChange}
          />
        </div>
      }
    />
      <Row
        title="Time Levels"
        component={
          <div className="flex w-full flex-col">
            <div className="flex flex-col gap-1">
              {timeLevels.map((fib: FibinaciLevelAngled, index: number) => (
                <div className="flex gap-1" key={index}>
                  <div className="w-5 flex gap-1">
                    <div className="my-auto">
                      {fib.visible ? (
                        <TitleButton activated={true} tooltip={"Hide level"} onClick={() => {
                          onShowTimeLevel(index, !fib.visible);
                        }}>
                          <BiShow />
                        </TitleButton>
                      ) : (
                        <TitleButton activated={false} tooltip={"Show level"} onClick={() => {
                          onShowTimeLevel(index, !fib.visible);
                        }}>
                          <BiHide />
                        </TitleButton>
                      )}
                    </div>
                  </div>
                  <div className="w-5 flex">
                    <div className="my-auto">
                      {fib.angle ? (
                        <TitleButton activated={true} tooltip={"Hide angle"} onClick={() => {
                          onShowTimeAngle(index, !fib.angle);
                        }}>
                          <RxAngle />
                        </TitleButton>
                      ) : (
                        <TitleButton activated={false} tooltip={"Show angle"} onClick={() => {
                          onShowTimeAngle(index, !fib.angle);
                        }}>
                          <RxAngle />
                        </TitleButton>
                      )}
                    </div>
                  </div>
                  <div className="w-16 my-auto">
                    <input
                      key={index}
                      type="number"
                      value={fib.level}
                      className="text-[8pt] p-1 w-full rounded bg-white text-right outline-trtp-primary border border-gray-200 dark:border-gray-800 dark:bg-black"
                      onChange={(field) => {
                        const _levels = [...timeLevels];
                        _levels[index].level = parseFloat(field.target.value);
                        setTimeLevels(_levels);
                        saveTimeLevels();
                      }}
                      onBlur={sortLevels}
                    />
                  </div>
                  <div className="my-auto text-gray-500 dark:text-gray-700 hover:text-trtp-primary cursor-pointer" onClick={() => {
                    removeTimeLevel(index);
                  }}>
                    <Tooltip overlay={`Remove level ${fib.level}`} mouseEnterDelay={0.75} >
                      <MdOutlineRemoveCircleOutline size={14} />
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex-1 flex w-full justify-center">
              <div className="bg-gray-100 hover:bg-gray-200 dark:bg-black dark:hover:bg-gray-900 text-gray-600 hover:text-black dark:hover:text-white cursor-pointer text-center rounded mx-1 my-2 p-1 w-full" onClick={addTimeLevel}>
                Add time level
              </div>
            </div>
          </div>
        }
      />
      <Row
        title="Price Levels"
        component={
          <div className="flex w-full flex-col">
            <div className="flex flex-col gap-1">
              {priceLevels.map((fib: FibinaciLevelAngled, index: number) => (
                <div className="flex gap-1" key={index}>
                  <div className="w-5 flex gap-1">
                    <div className="my-auto">
                      {fib.visible ? (
                        <TitleButton activated={true} tooltip={"Hide level"} onClick={() => {
                          onShowPriceLevel(index, !fib.visible);
                        }}>
                          <BiShow />
                        </TitleButton>
                      ) : (
                        <TitleButton activated={false} tooltip={"Show level"} onClick={() => {
                          onShowPriceLevel(index, !fib.visible);
                        }}>
                          <BiHide />
                        </TitleButton>
                      )}
                    </div>
                  </div>
                  <div className="w-5 flex">
                    <div className="my-auto">
                      {fib.angle ? (
                        <TitleButton activated={true} tooltip={"Hide angle"} onClick={() => {
                          onShowPriceAngle(index, !fib.angle);
                        }}>
                          <RxAngle />
                        </TitleButton>
                      ) : (
                        <TitleButton activated={false} tooltip={"Show angle"} onClick={() => {
                          onShowPriceAngle(index, !fib.angle);
                        }}>
                          <RxAngle />
                        </TitleButton>
                      )}
                    </div>
                  </div>
                  <div className="w-16 my-auto">
                    <input
                      key={index}
                      type="number"
                      value={fib.level}
                      className="text-[8pt] p-1 w-full rounded bg-white text-right outline-trtp-primary border border-gray-200 dark:border-gray-800 dark:bg-black"
                      onChange={(field) => {
                        const _levels = [...priceLevels];
                        _levels[index].level = parseFloat(field.target.value);
                        setPriceLevels(_levels);
                        savePriceLevels();
                      }}
                      onBlur={sortLevels}
                    />
                  </div>
                  <div className="my-auto text-gray-500 dark:text-gray-700 hover:text-trtp-primary cursor-pointer" onClick={() => {
                    removePriceLevel(index);
                  }}>
                    <Tooltip overlay={`Remove level ${fib.level}`} mouseEnterDelay={0.75} >
                      <MdOutlineRemoveCircleOutline size={14} />
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex-1 flex w-full justify-center">
              <div className="bg-gray-100 hover:bg-gray-200 dark:bg-black dark:hover:bg-gray-900 text-gray-600 hover:text-black dark:hover:text-white cursor-pointer text-center rounded mx-1 my-2 p-1 w-full" onClick={addPriceLevel}>
                Add price level
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default GannBox;
