import { useState, useEffect } from "react";
import Switch, { SwitchOption } from "src/component/Switch";
import useStateId from "src/hooks/useStateId";
import Row from "../Row";

type GannBoxOptionProperties = {
  turningPoint: any;
  chart: any;
};

const GannDoubleArcsOption = ({ turningPoint}: GannBoxOptionProperties) => {
  const [hidden, setHidden] = useState(true);
  const [doubleArcs, setDoubleArcs] = useState(turningPoint.getOption("doublearcs") === 1);

  const { id: stateId, update } = useStateId();

  useEffect(() => {
    setHidden(
      turningPoint.getOption("extend_arcs") == "3"
    );

    setDoubleArcs(turningPoint.getOption("doublearcs") === 1);
  }, [turningPoint, stateId]);

  const handleChange = (option: SwitchOption) => {
    turningPoint.setOption("doublearcs", option.value);

    setDoubleArcs(option.value === 1);

    update();
  };

  const options: SwitchOption[] = [
    {
      id: "off",
      title: "Off",
      value: 0,
    },
    {
      id: "on",
      title: "On",
      value: 1,
    },
  ];
   
  if (hidden) return <></>;

  return (
    <Row
      title="Double Arcs"
      component={
        <div className="bg-gray-100 dark:bg-black p-1">
          <Switch
            options={options}
            selected={doubleArcs ? "on" : "off"}
            onChange={handleChange}
          />
        </div>
      }
    />
  );
};

export default GannDoubleArcsOption;
