import ToolTip from 'rc-tooltip';
import { useCallback} from 'react';
import { useChart } from 'src/context/ChartContext';

import { useAppSelector } from '../../../redux/hooks';

type ModeProperties = {
  mode: string;
  text: string;
  keybinding?: string;
  icon: JSX.Element;
};

export  const ToolTipPosition = {
    "left": "right",
    "right": "left",
    "float": "bottom"
  }

const Mode = ({ mode, keybinding, text, icon }: ModeProperties) => {
  const activeTimeframe = useAppSelector((state) => state.chart.timeframe);
  const toolbarPosition = useAppSelector((state) => state.settings.toolbarPosition);

  const chartProvider = useChart();

  const selectMode = useCallback(
    (mod: string) => {
      const chart = chartProvider.get(activeTimeframe);

      if (!chart) return;

      // @ts-ignore
      chart.mode.switch(mod);
    },
    [activeTimeframe, chartProvider]
  );

  return (
    <ToolTip
      overlay={
        <div>
          {text}
          {keybinding && keybinding?.trim() != '' && (
            <span className="px-2 py-1 ml-1 font-mono text-xs bg-gray-800 dark:text-gray-400 rounded-md">
              {keybinding}
            </span>
          )}
        </div>
      }
      placement={ToolTipPosition[toolbarPosition]}
      mouseEnterDelay={0.2}>
      <div
        className={`cursor-pointer hover:text-trtp-primary mx-auto`}
        onClick={() => selectMode(mode)}>
        {icon}
      </div>
    </ToolTip>
  );
};

export default Mode;
