import { AbstractTool } from "chart";
import { useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { BsLock, BsUnlock } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { IoDuplicateOutline } from "react-icons/io5";
import { MdOutlineSettingsInputComponent } from "react-icons/md";
import { useMobileAppController } from "src/context/MobileAppControllerContext";
import useIsMobile from "src/hooks/useIsMobile";
import useStateId from "src/hooks/useStateId";
import useTitleToolSize from "src/hooks/useTitleToolSize";
import { selectItem } from "src/redux/features/counter/menuSlice";
import { TitleButton } from "../../properties/TitleButton";
import { useAppSelector } from "../../redux/hooks";
import { useAppDispatch } from "../../redux/hooks";

type ToolProperties = {
  tool: AbstractTool
}

const Tool = ({ tool }: ToolProperties) => {
  const [locked, setLocked] = useState(tool.getOption("locked", "0") == "1");
  const [visible, setVisible] = useState(tool.getOption("visible", "1") == "1");

  const { update: updateState } = useStateId();

  const toolSize = useTitleToolSize();

  const dispatch = useAppDispatch();

  const activeTimeframe = useAppSelector((state) => state.chart.timeframe);
  const selectedToolId = useAppSelector((state) => state.chart.toolId[activeTimeframe]);

  const isMobile = useIsMobile();

  const mobileAppController = useMobileAppController();

  const onHover = () => {
    if (!tool.objectId) return;
    if (isMobile) return;

    //@ts-ignore
    tool.chart.toolHighlight.clear(); // make sure there is only one

    //@ts-ignore
    tool.chart.toolHighlight.add(tool.objectId);
  }

  const onHoverEnd = () => {
    //@ts-ignore
    tool.chart?.toolHighlight.clear(); // make sure there is only one
  }

  const onSelect = () => {
    if (!tool.objectId) return;

    // //@ts-ignore
    // tool.chart.toolHighlight.remove(tool.objectId);

    // tool.doSelect();

    tool.visible = !tool.visible;
    setVisible(tool.visible);
  }

  const onProperties = () => {
    if (!tool.objectId) return;

    //@ts-ignore
    tool.chart.toolHighlight.remove(tool.objectId);

    tool.doSelect();

    if (mobileAppController) {
      mobileAppController.switchPage("properties");
    }
  }

  const onUnlock = () => {
    setLocked(true);
    tool.setOption("locked", "1");
    updateState()
  }

  const onLock = () => {
    setLocked(false);
    tool.setOption("locked", "0");
    updateState()
  }

  const handleDuplicate = () => {
    tool?.duplicate();
  }

  const handleDelete = () => {
    tool.chart.tools.delete(tool);
    dispatch(selectItem("tools"));
  }

  const handleVisible = () => {
    setVisible(true);
    tool.setOption("visible", "1");
    updateState()
  }

  const handleHide = () => {
    setVisible(false);
    tool.setOption("visible", "0");
    updateState()
  }

  return (
    <div
      className="mx-1 bg-gray-50 dark:bg-gray-950 dark:text-gray-300 hover:bg-gray-100 p-2 cursor-pointer flex gap-2"
      onMouseOver={onHover}
      onMouseOut={onHoverEnd}
    >
      {visible ? (
        <TitleButton activated={true} tooltip={"Hide"} onClick={handleHide}>
          <BiShow size={toolSize}/>
        </TitleButton>
      ) : (
        <TitleButton activated={false} tooltip={"Show"} onClick={handleVisible}>
          <BiHide size={toolSize}/>
        </TitleButton>
      )}
      <div className={`${selectedToolId == tool.objectId ? "text-trtp-primary font-bold" : ""} my-auto flex-1 hover:text-trtp-primary dark:hover:text-trtp-primary ${visible ? "" : "text-gray-600"}`} onClick={onSelect}>
        {tool.typeDisplayName}
        {tool.typeName == "turningpoint" && (
          //@ts-ignore
          <> {tool.getNumber()}</>)}
      </div>
      <div className="flex gap-1">
        <>
          {locked ? (
            <TitleButton activated={true} tooltip={`Unlock ${tool.typeDisplayName}`} onClick={onLock}>
              <BsLock size={toolSize} />
            </TitleButton>
          ) : (
            <TitleButton activated={false} tooltip={`Lock ${tool.typeDisplayName}`} onClick={onUnlock}>
              <BsUnlock size={toolSize} />
            </TitleButton>
          )}
        </>
        {tool.typeName != "turningpoint" && (
          <>
            <TitleButton activated={false} tooltip={"Duplicate"} onClick={handleDuplicate}>
              <IoDuplicateOutline size={toolSize} />
            </TitleButton>
            <TitleButton activated={false} tooltip={'Delete'} onClick={handleDelete}>
              <FaTimes size={toolSize} />
            </TitleButton>
          </>
        )}
          <TitleButton activated={false} tooltip={`Properties`} onClick={onProperties}>
            <MdOutlineSettingsInputComponent size={toolSize} />
          </TitleButton>
      </div>
    </div>
  )
}

export default Tool;
