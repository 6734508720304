import { MarketInterface } from "common";
import useColorChange from "use-color-change";

const PriceValue = ({ price, market }: { price: number, market: MarketInterface }) => {
  const colorStyle = useColorChange(price, {
    higher: "limegreen",
    lower: "crimson",
    duration: 600
  });
  return (<div key={market.identifier} style={colorStyle} className="font-semibold">{price}</div>);
}

export default PriceValue;
