import { useEffect, useState } from "react";
import Title from "./properties/Title";
import { useChart } from "./context/ChartContext";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { AbstractTool } from "chart";
import Tool from "./component/ToolsPanel/Tool";
import debounce from "./lib/debounce";
import useStateId from "./hooks/useStateId";
import { selectItem } from "./redux/features/counter/menuSlice";
import useIsMobile from "./hooks/useIsMobile";

const ToolsPanel = () => {
  const [tools, setTools] = useState<AbstractTool[]>([]);

  const activeTimeframe = useAppSelector((state) => state.chart.timeframe);
  const market = useAppSelector((state) => state.markets.active);

  const [deleteSure, setDeleteSure] = useState(false);

  const isMobile = useIsMobile();
  const { update: updateState, id } = useStateId();

  const dispatch = useAppDispatch();

  const filterTools = (tool: AbstractTool) => tool.isStatic == false && tool.typeName != "turningpoint";

  const chartProvider = useChart();
  useEffect(() => {
    const chart = chartProvider.get(activeTimeframe);

    if (!chart) return;

    const update = () => {
      setTools(Object.values(chart.tools.getVisibleTools(false)).filter(filterTools));
    }

    const onChange = debounce(update, 100);

    chart.event.on("tool:registered", onChange);
    chart.event.on("tool:deleted", onChange);
    chart.event.on('chart:positionchange', onChange);

    update();

    return () => {
      chart.event.off('chart:positionchange', onChange);
      chart.event.off("tool:registered", onChange);
      chart.event.off("tool:deleted", onChange);
    }
  }, [activeTimeframe, market]);

  if (tools.length == 0) {
    return (
      <div className="flex flex-col gap-2 overflow-y-auto text-xs dark:text-white flex-1">
        <div className="p-10 text-center dark:text-gray-600">No tools visible on chart</div>
      </div>
    );
  }

  const handleHide = () => {
    for (const tool of tools) {
      tool.setOption("visible", "0");
    }
    updateState()
  }

  const handleShow = () => {
    for (const tool of tools) {
      tool.setOption("visible", "1");
    }
    updateState()
  }

  const handleDelete = () => {
    for (const tool of tools) {
      tool.chart.tools.delete(tool);
    }
    dispatch(selectItem("tools"));
    setDeleteSure(false);
  }

  const handlePreDelete = () => {
    setDeleteSure(true);

    setTimeout(() => {
      setDeleteSure(false);
    }, 3500);
  }

  return (
    <div className={`${isMobile && "min-h-[400px] max-h-[400px]"} overflow-y-auto text-xs dark:text-white flex-1 flex flex-col gap-1`}>
      <Title>Visible tools</Title>
      <div className={`flex justify-end gap-2 py-0 px-1 ${isMobile && "text-sm"}`} >
        <div className="bg-gray-100 dark:bg-black text-gray-600 cursor-pointer hover:bg-trtp-primary hover:text-white rounded px-2 py-1" onClick={handleHide}>Hide all</div>
        <div className="bg-gray-100 dark:bg-black text-gray-600 cursor-pointer hover:bg-trtp-primary hover:text-white rounded px-2 py-1" onClick={handleShow}>Show all</div>
        {deleteSure ? (
          <div className="bg-red-600 dark:bg-red-900 cursor-pointer text-white rounded px-2 py-1" onClick={handleDelete}>Sure?</div>
        ) : (
          <div className="bg-gray-100 dark:bg-black text-gray-600 cursor-pointer hover:bg-trtp-primary hover:text-white rounded px-2 py-1" onClick={handlePreDelete}>Delete all</div>
        )}
      </div>
      <div key={id} className="flex flex-col gap-1">
        {tools.map((tool: AbstractTool) => (<Tool key={tool.objectId} tool={tool} />))}
      </div>
    </div >
  );
};
export default ToolsPanel;

