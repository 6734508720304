const SelectTurningPointMessage = () => {
  return (
    <div className="flex w-full">
      <div className="text-black dark:text-gray-500 rounded-md my-5 mx-auto px-3 py-2">
        Select a turning point
      </div>
    </div>
  );
};

export default SelectTurningPointMessage;
