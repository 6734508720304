import { useEffect, useState } from "react";
import { FaRegTimesCircle } from "react-icons/fa";

import useTool from "src/hooks/useTool";
import Row from "../Row";

const TextRow = ({label = 'Text'}: {label?: string}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [text, setText] = useState<string>("");

  const tool = useTool();

  useEffect(() => {
    if (tool === null) return;

    setText(tool.getOption("text", ""));
  }, [tool]);

  const handleChange = (value: string) => {
    setText(value);
    tool?.setOption("text", value);
  };

  return (<div className="flex flex-col gap-2">
    <Row
      title={label}
      component={
        <div className={`flex text-[8pt] p-1 w-full rounded bg-white text-left outline-none border ${focus ? "border-trtp-primary" : "border-gray-200"} dark:border-gray-800 dark:bg-black`}>
        <input
          value={text}
          className="bg-transparent outline-none flex-1"
          type="text"
          placeholder={"Add custom label"}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={(e) => handleChange(e.target.value)} />
          {text != "" && (
            <FaRegTimesCircle
              size={12}
              className="my-auto text-gray-400 cursor-pointer hover:text-trtp-primary"
              onClick={() => handleChange("")}
            />
          )}
          
          </div>
      }
    />
  </div>);
}

export default TextRow;
