export const SCORE = {
  TREND_DIRECTION: 2,
  TURNINGPOINT_DIRECTION: 4,
  SCALING: 8,
  KILLZONE: 16,
  TIME_PROJECTION: 32,
  CUBE_ROOT: 64,
  OSCILLATOR: 128,
  PRICE_PROJECTION: 256,
  FAN_LINE: 512,
  ARC_CURVE: 1024,
};

export type CriterionScore = {
  TP: number;
  PP: number;
  FL: number;
  AC: number;
  CS: number;
  MS: number;
  QS: number;
  SW: number;
  LS: number;
  KZ: number;
  DTA: number;
  probability: number;
  pnl: number;
  protectCapital: number;
  controlOverSelf: number;
  growAcount: number;
  score: number;
  result: number;
};

const useCriterion = () => {
  const getScoreName = (score: number) => {
    if (score < 0) {
      return 'Avoid';
    } else if (score >= 0 && score <= 32) {
      return 'Adequate';
    } else if (score >= 33 && score <= 65) {
      return 'Approved';
    } else if (score >= 66) {
      return 'High confidence';
    }
  };

  const getScoreColor = (score: number) => {
    if (score < 0) {
      return 'text-red-500';
    } else if (score >= 0 && score <= 32) {
      return 'text-orange-500';
    } else if (score >= 33 && score <= 65) {
      return 'text-trtp-primary';
    } else if (score >= 66) {
      return 'text-trtp-primary';
    }
  };

  const calculateScore = (score: number, rewardLevel: number) => {
    const state: CriterionScore = {
      TP: score & SCORE.TREND_DIRECTION ? 1 : 0,
      PP: score & SCORE.PRICE_PROJECTION ? 1 : 0,
      FL: score & SCORE.FAN_LINE ? 1 : 0,
      AC: score & SCORE.ARC_CURVE ? 1 : 0,
      CS: score & SCORE.CUBE_ROOT ? 1 : 0,
      MS: score & SCORE.TREND_DIRECTION ? 1 : 0,
      QS: score & SCORE.TURNINGPOINT_DIRECTION ? 1 : 0,
      SW: score & SCORE.SCALING ? 1 : 0,
      LS: score & SCORE.OSCILLATOR ? 1 : 0,
      KZ: score & SCORE.KILLZONE ? 1 : 0,
      DTA: Math.round(((rewardLevel * 33.33) / 100) * 10) / 10,
      probability: 0,
      pnl: 0,
      protectCapital: 0,
      controlOverSelf: 0,
      growAcount: 0,
      score: 0,
      result: 0,
    };

    state.probability =
      (state.TP +
        state.PP +
        state.FL +
        state.AC +
        state.CS +
        state.MS +
        state.QS +
        state.SW +
        state.LS +
        state.KZ) /
      10;

    state.pnl = state.DTA;
    state.protectCapital = state.DTA * state.probability;
    state.controlOverSelf = state.protectCapital - (1 - state.probability);
    state.growAcount = state.controlOverSelf / state.pnl;

    state.result =
      state.protectCapital *
        [
          state.TP ? 0.1 : 0,
          state.PP ? 0.1 : 0,
          state.FL ? 0.1 : 0,
          state.AC ? 0.1 : 0,
          state.CS ? 0.1 : 0,
          state.MS ? 0.15 : 0,
          state.QS ? 0.1 : 0,
          state.SW ? 0.05 : 0,
          state.LS ? 0.05 : 0,
          state.KZ ? 0.15 : 0,
        ].reduce((acc, curr) => curr + acc, 0) -
      (1 - state.probability);

    state.score = Math.round(state.growAcount * 100);

    return state;
  };

  const calculateTarget = (
    targetLevel: number,
    current_tp: number,
    high_level: number
  ) => {
    const increment = (high_level - current_tp) / 5;

    return Math.round(current_tp + increment * (targetLevel + 1));
  };

  const calculatePercentage = (input: number) => {
    if (input >= 0.31 && input <= 0.69) {
      return 2;
    } else if (input >= 0.7 && input <= 1) {
      return 3;
    } else {
      return 1;
    }
  };

  return {
    calculateScore,
    calculateTarget,
    calculatePercentage,
    getScoreName,
    getScoreColor,
  };
};

export default useCriterion;
