import { PlacementModeType } from 'chart';
import ToolTip from 'rc-tooltip';
import { useEffect } from 'react';
import { IoMagnetOutline } from 'react-icons/io5';
import { setMagnet, toggleMagnet } from 'src/redux/features/counter/appSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useChart } from '../../context/ChartContext';

const Magnet = () => {
  const chartProvider = useChart();
  const dispatch = useAppDispatch();

  const isActive = useAppSelector((root) => root.app.magnetActive);

  useEffect(() => {
    //@ts-ignore
    for (const chart of chartProvider.all()) {
      if (isActive) {
        chart.tools.placementMode.setMode(PlacementModeType.Magnetic);
      } else {
        chart.tools.placementMode.setMode(PlacementModeType.Float);
      }
    }

    const downHandler = (key: any) => {
      if (key.repeat) { return }

      /** @ts-ignore */
      if (key.target?.localName === "textarea") return;
      /** @ts-ignore */
      if (key.target?.localName === "input") return;

      if (key.ctrlKey || key.metaKey) {
        return;
      }

      if (key.key === "Shift") {
        setToggleMagnet();
      }
    }

    const upHandler = (key: any) => {
      /** @ts-ignore */
      if (key.target?.localName === "textarea") return;
      /** @ts-ignore */
      if (key.target?.localName === "input") return;

      if (key.ctrlKey || key.metaKey) {
        return;
      }
      if (key.key === "Shift") {
        setToggleMagnet();
      }
    }

    window.addEventListener("keydown", downHandler, false);
    window.addEventListener("keyup", upHandler, false);

    return () => {
      window.removeEventListener("keydown", downHandler, false);
      window.removeEventListener("keyup", upHandler, false);
    };
  }, [isActive]);

  const setToggleMagnet = () => {
    dispatch(toggleMagnet());
  }

  return (
    <ToolTip
      overlay={
        <div className="flex gap-1">
          <div className="my-auto">
            Toggle Magnet
          </div>
          <span className="px-2 py-1 ml-1 font-mono text-xs bg-gray-800 dark:text-gray-400 rounded-md">
            Shift
          </span>
        </div>
      }
      placement={"right"}
      mouseEnterDelay={0.2}>
      <div
        className={
          `cursor-pointer p-1
          ${isActive ?
            "rounded-sm bg-trtp-primary text-white" : "hover:text-trtp-primary"}`}
        onClick={() => {
          setToggleMagnet();
        }}>
        <IoMagnetOutline size={16} className="mx-auto" />
      </div>
    </ToolTip>
  )
}

export default Magnet;
