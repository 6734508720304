import Annotate from "./buttons/Annotate";
import ClearChart from "./buttons/ClearChart";
import Delete from "./buttons/Delete";
import Measure from "./buttons/Measure";
import Replay from "./buttons/Replay";
import Screenshot from "./buttons/Screenshot";
import Splitter from "./buttons/Splitter";
import Magnet from "./Magnet";

const FunctionButtons = () => (
  <>
    <Screenshot />
    <Measure />
    <Annotate />
    <Replay />
    <Splitter />
    <Delete />
    <ClearChart />
    <Splitter />
    <Magnet />
  </>
)

export default FunctionButtons;
