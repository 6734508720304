import { RiShapeLine } from "react-icons/ri";
import Tool from "./Tool";

const MagBox = () => {
  return (
    <Tool
      text={"Rectangle"}
      tool={"magbox"}
      icon={<RiShapeLine size={20} />}
    />
  );
};

export default MagBox;
