import TurningPoint from "./buttons/TurningPoint";
import Line from "./buttons/Line";
import VerticalRay from "./buttons/VerticalRay";
import HorizontalRay from "./buttons/HorizontalRay";
import MagBox from "./buttons/MagBox";
import GannGrid from "./buttons/GannGrid";
import Marker from "./buttons/Marker";
import Arrow from "./buttons/Arrow";
import Text from "./buttons/Text";
import ReverseGann from "./buttons/ReverseGann";
import FibRetracement from "./buttons/FibRetracement";
import AngleLine from "./buttons/AngleLine";
import GannSquare from "./buttons/GannSquare";
import GannBox from "./buttons/GannBox";
import Circle from "./buttons/Circle";
import GannFan from "./buttons/GannFan";
import PitchFork from "./buttons/PitchFork";
import { Tool } from "common";
import Spiral from "./buttons/Spiral";

const Buttons: { [key: string]: JSX.Element } = {
  [Tool.TurningPoint]: <TurningPoint />,
  [Tool.MagBox]: <MagBox />,
  [Tool.Marker]: <Marker />,
  [Tool.Protractor]: <ReverseGann />,
  [Tool.GannGrid]: <GannGrid />,
  [Tool.GannSquare]: <GannSquare />,
  [Tool.GannBox]: <GannBox />,
  [Tool.GannFan]: <GannFan />,
  [Tool.FibRetracement]: <FibRetracement />,
  [Tool.PitchFork]: <PitchFork />,
  [Tool.Line]: <Line />,
  [Tool.Circle]: <Circle />,
  [Tool.AngleLine]: <AngleLine />,
  [Tool.VerticalRay]: <VerticalRay />,
  [Tool.HorizontalRay]: <HorizontalRay />,
  [Tool.Arrow]: <Arrow />,
  [Tool.Text]: <Text />,
  [Tool.Spiral]: <Spiral />,
}

export default Buttons;
