import { useState, useEffect } from "react";

import useStateId from "src/hooks/useStateId";
import Row from "../Row";
import Switch, { SwitchOption } from "src/component/Switch";
import { BsArrowsAngleContract, BsArrowUpRightSquare } from "react-icons/bs";
import { TbArrowsDoubleNeSw } from "react-icons/tb";

export const gannScaleOptions: SwitchOption[] = [
  {
    id: "3",
    value: 3,
    title: "XS",
    tooltip: "X-Small",
  },
  {
    id: "2",
    value: 2,
    title: "S",
    tooltip: "Small",
  },
  {
    id: "1",
    value: 1,
    title: "M",
    tooltip: "Medium",
  },
  {
    id: "4",
    value: 4,
    title: "L",
    tooltip: "Large",
  },
  {
    id: "5",
    value: 5,
    title: "XL",
    tooltip: "X-Large",
  },
  {
    id: "6",
    value: 6,
    title: <TbArrowsDoubleNeSw className="my-auto" size={14}/>,
    tooltip: "Custom scale",
  }
];

const ScaleOption = ({
  turningPoint,
  chart,
}: {
  turningPoint: any;
  chart: any;
}) => {
  const [scale, setScale] = useState<number>(1);
  const [hidden, setHidden] = useState(true);

  const { id: stateId, update } = useStateId();

  useEffect(() => {
    setHidden(
      turningPoint.getOption("extend_arcs") === "3"
    );
    setScale(turningPoint.getOption("level"));
    //setCustom(turningPoint.getOption("custom_scale", "0") == "1");
  }, [turningPoint, chart, stateId]);

  const setLevel = (level: number) => {
    setScale(level);
    turningPoint.setOption("level", level);
    // setCustom(false)
    update();
  };

  if (hidden) return <></>;

  return (
    <Row
      title="Scale"
      component={
        <div className="flex dark:bg-black bg-gray-50 p-1">
          <Switch
            options={gannScaleOptions}
            selected={`${scale}`}
            onChange={(option: SwitchOption) => setLevel(parseInt(option.id))}
          />
        </div>
      }
    />
  );
};

export default ScaleOption;
