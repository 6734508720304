import Tooltip from 'rc-tooltip';

export type SwitchOption = {
  id: string; 
  title: string | React.ReactElement;
  value?: string | number;
  tooltip?: string;
  uppercase?: boolean;
};

type SwitchProps = {
  activated?: boolean;
  selected?: string | number | null;
  options: SwitchOption[];
  onChange: (options: SwitchOption) => void;
};

const Switch = ({activated=true, selected, options, onChange}: SwitchProps) => {
  const handleClick = (option: SwitchOption) => {
    if (options.length == 2 && selected === option.id) {
      // Select next
      const index = options.findIndex((o) => o.id === option.id);

      onChange(options[(index + 1) % options.length]);
    } else {
      onChange(option);
    }
  };

  return (
    <div className="flex select-none flex-1 bg-white dark:bg-black rounded-sm border border-gray-100 dark:border-black p-0 ">
      {options.map((option: SwitchOption) => (
        <Tooltip
          key={option.id}
          id={option.id}
          trigger={option.tooltip !== undefined ? ['hover'] : []}
          mouseEnterDelay={0.3}
          placement={'bottom'}
          overlay={<div>{option.tooltip}</div>}>
          <div
            className={` cursor-pointer ${
              activated && selected == option.id
                ? `bg-trtp-primary text-white font-medium  ${
                    options.length > 2 ? 'cursor-default' : ''
                  }`
                : 'dark:hover:bg-gray-900 hover:bg-gray-200'
            } text-xxs ${
              option.uppercase ? 'uppercase' : ''
            } px-2 py-1 transition-all rounded-sm`}
            onClick={() => handleClick(option)}>
            {option.title}
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default Switch;
