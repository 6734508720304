import { useEffect } from "react";
import { ReactComponent as TrtpLogo } from './trtp-logo.svg';

const Ratelimited = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, 60000);
  }, []);

  return (
    <div className="h-screen w-screen flex gap-10 flex-col dark:bg-black justify-center">
      <div className="mx-auto">
        <TrtpLogo className="dark:invert animate-bounce" style={{ width: '125px', height: '125px' }} />
      </div>
      <div className="flex mx-auto dark:text-white">
        Too  many requests, please wait a minute...
      </div>
      <div className="flex mx-auto text-gray-600 dark:text-gray-400 text-xs">
        The page will reload automatically
      </div>
    </div>
  );
};

export default Ratelimited;
