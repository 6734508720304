import { useState, useEffect } from "react";
import Switch, { SwitchOption } from "src/component/Switch";
import useStateId from "src/hooks/useStateId";
import Row from "../Row";

type GannBoxOptionProperties = {
  turningPoint: any;
  chart: any;
  checkHidden?: boolean;
};

const GannBoxOption = ({ turningPoint, checkHidden = true}: GannBoxOptionProperties) => {
  const [hidden, setHidden] = useState(true);
  const [gannBox, setGannBox] = useState(turningPoint.getOption("gannbox") === 1);

  const { id: stateId, update } = useStateId();

  useEffect(() => {
    setHidden(
      turningPoint.getOption("extend_arcs") !== "0"
    );

    setGannBox(turningPoint.getOption("gannbox") === 1);
  }, [turningPoint, stateId]);

  const handleChange = (option: SwitchOption) => {
    turningPoint.setOption("gannbox", option.value);

    setGannBox(option.value === 1);

    update();
  };

  const options: SwitchOption[] = [
    {
      id: "off",
      title: "Off",
      value: 0,
    },
    {
      id: "on",
      title: "On",
      value: 1,
    },
  ];

  if (hidden && checkHidden != false) return <></>;

  return (
    <Row
      title="Levels"
      component={
        <div className="bg-gray-100 dark:bg-black p-1">
          <Switch
            options={options}
            selected={gannBox ? "on" : "off"}
            onChange={handleChange}
          />
        </div>
      }
    />
  );
};

export default GannBoxOption;
