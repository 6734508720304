import { useEffect } from "react";
import { useSocket } from "src/context/SocketContext";
import debounce from "src/lib/debounce";
import { setTimeframe } from "../redux/features/counter/chartSlice";
import { useAppDispatch } from "../redux/hooks";

const useSelectChartOnScroll = (chartContainerRef: any, timeframe: number) => {
  const { connected } = useSocket();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!chartContainerRef.current) return;

    const container = chartContainerRef.current;

    const fireAction = () => {
      dispatch(setTimeframe(timeframe));
    }

    const onWheel = debounce(fireAction, 50);

    container.addEventListener("wheel", onWheel);
    return () => {
      container.removeEventListener("wheel", onWheel);
    };
  }, [connected, timeframe, chartContainerRef.current]);
};

export default useSelectChartOnScroll;
