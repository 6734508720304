import { IoIosResize } from "react-icons/io";
import Tool from "./Tool";

const HorizontalRay = () => {
  return (
    <Tool
      text={"Horizonal Ray"}
      tool={"horizontal-ray"}
      icon={<IoIosResize size={20} className={"transform rotate-45"} />}
    />
  );
};

export default HorizontalRay;
