import { useDispatch } from "react-redux";
import { increaseStateId } from "src/redux/features/counter/chartSlice";
import { useAppSelector } from "../redux/hooks";

let updateTimer: any = null;

const useStateId = () => {
  const stateId = useAppSelector((state) => state.chart.stateId);

  const dispatch = useDispatch();

  const update = () => {
    if (updateTimer) clearTimeout(updateTimer);

    // Debounce the update timer
    updateTimer = setTimeout(() => {
      dispatch(increaseStateId());
      updateTimer = null;
    }, 100);
  };

  return { id: stateId, update };
};

export default useStateId;
