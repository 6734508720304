import { useEffect, useState } from "react";

import { useAppSelector } from "../redux/hooks";
import { ThemeType } from "../context/ConfigContext";
import useIsMobile from "./useIsMobile";

const useColorScheme = () => {
  const [theme, setTheme] = useState<ThemeType | null>(null);

  const isMobile = useIsMobile();
  const configuredTheme = useAppSelector((state) => isMobile ? "system" : state.settings.theme);

  const primaryColor = useAppSelector((state) => state.settings.primaryColor);

  useEffect(() => {
    if (primaryColor == "") return;
    if (primaryColor == "var(--trtp-primary)") return;

    document.documentElement.style.setProperty('--trtp-primary', primaryColor);
  }, [primaryColor])

  const setDocumentTheme = (color: string) => {
    document.documentElement.classList.remove("light");
    document.documentElement.classList.remove("dark");

    if (color == "dark") {
      document.body.style.backgroundColor = "black";
    } else {
      document.body.style.backgroundColor = "white";
    }

    if (color == "system") {
    debugger
    }

    document.documentElement.classList.add(color);
  }

  useEffect(() => {
    if (!theme) return;

    setDocumentTheme(theme);
  }, [theme])

  useEffect(() => {
    if (configuredTheme != "system") {
      setTheme(configuredTheme);
    } else {

      if (typeof window === "undefined") {
        setTheme(ThemeType.Light);
      }

      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        setTheme(ThemeType.Dark);
      } else {
        setTheme(ThemeType.Light);
      }
    }
    const onThemeChange = (e: any) => {
      const colorScheme = e.matches ? "dark" : "light";
      if (colorScheme === "light") {
        setTheme(ThemeType.Light);
      } else if (colorScheme === "dark") {
        setTheme(ThemeType.Dark);
      }
    };

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", onThemeChange);

    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", onThemeChange);
    };
  }, [configuredTheme]);

  return theme;
};

export default useColorScheme;
