import ToolTip from 'rc-tooltip'; 
import { useCallback } from 'react';
import { HiOutlineCamera } from 'react-icons/hi';

import { useAppSelector } from '../../../redux/hooks';
import { ToolTipPosition } from './Tool';

import html2canvas from 'html2canvas';
import { useConfig } from '../../../context/ConfigContext';
import useColorScheme from '../../../hooks/useColorscheme';

const Screenshot = () => {
  const toolbarPosition = useAppSelector((state) => state.settings.toolbarPosition);
  const market = useAppSelector((state) => state.markets.active);
  const marketConfig = useAppSelector((root) => root.markets.markets.find((m) => m.identifier == market));
  const theme = useColorScheme();

  const config = useConfig();

  const takeScreenshot = useCallback(() => {
    const color = theme == 'light' ? '#f7f7f7' : '#000000';//green';//getComputedStyle(document.body).getPropertyValue('--trtp-background');
    var dockBars = document.getElementsByClassName("dock-bar");
    var screenshotHide = document.getElementsByClassName("screenshot-hide");
    var badges = document.getElementsByClassName("screenshot-badge");
    const textColor = theme == "light" ? "black" : "white";

    //@ts-ignore
    for (var i = 0; i < dockBars.length; i++) dockBars[i].style.display = "none";
    
    //@ts-ignore
    for (var i = 0; i < screenshotHide.length; i++) screenshotHide[i].style.display = "none";
    
    //@ts-ignore
    for (var i = 0; i < badges.length; i++) badges[i].style.display = "block";

    /** @ts-ignore */
    const username = config.session.user.name;

    const fontSize = 16 * devicePixelRatio;
    const smallFontSize = 12 * devicePixelRatio;
    const smallerFontSize = 8 * devicePixelRatio;

    // @ts-ignore
    html2canvas(document.querySelector(".dock-layout"), { backgroundColor: color }).then((canvas: any) => {
      const toolbarHeight = 50 * devicePixelRatio;
      const padding = 10;

      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = (canvas.width + (padding*2)) * devicePixelRatio;
      tempCanvas.height = (canvas.height + toolbarHeight + padding) * devicePixelRatio;

      const tempContext = tempCanvas.getContext('2d');
      if (!tempContext) return;

      tempContext.scale(devicePixelRatio, devicePixelRatio)

      // Set the background color to black
      tempContext.fillStyle = color;
      tempContext.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

      tempContext.drawImage(canvas, padding, toolbarHeight);

      tempContext.fillStyle = textColor;
      tempContext.font = fontSize + 'px Messina Sans';

      const text = marketConfig?.name || "";
      const x = 22;
      const y = fontSize + 6*devicePixelRatio;
      tempContext.fillText(text, x, y);

      tempContext.font = smallFontSize + 'px Messina Sans';
      tempContext.fillText('Published by ' + username, x, y + fontSize );

      const url = config.siteUrl + '/trtp-logo.svg';
      fetch(url)
        .then(response => response.text())
        .then(svgText => {
          const modifiedSvgText = svgText.replace('<svg', '<svg fill="'+textColor+'" ');

          const image = new Image();
          image.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(modifiedSvgText)}`;
          image.crossOrigin = "anonymous";
          image.addEventListener('load', function() {

            tempContext.textAlign = "right";
            tempContext.font = fontSize + 'px Messina Sans';
            const text = "Tesseract Pro"
            const x = canvas.width;
            const y = toolbarHeight/2 + (5*devicePixelRatio)
            const textSize = tempContext.measureText(text).width;
            tempContext.fillText(text, x, y);

            tempContext.font = smallerFontSize + 'px Messina Sans';
            tempContext.fillText("Visit https://tesseractpro.io", x, y + (12*devicePixelRatio));

            const iconHeight = 20 * devicePixelRatio;
            const iconWidth = 18 * devicePixelRatio;
            tempContext.drawImage(this, canvas.width - textSize - iconWidth - (10*devicePixelRatio), 15*devicePixelRatio, iconWidth, iconHeight);


            // @ts-ignore
            window.open().document.write('<img src="' + tempCanvas.toDataURL() + '" width="' + canvas.width / devicePixelRatio + '" height="' + canvas.height / devicePixelRatio + '"/>');
          });
        });
    });

    //@ts-ignore
    for (var i = 0; i < dockBars.length; i++) dockBars[i].style.display = "block";

    //@ts-ignore
    for (var i = 0; i < screenshotHide.length; i++) screenshotHide[i].style.display = "block";

    //@ts-ignore
    for (var i = 0; i < badges.length; i++) badges[i].style.display = "none";
  }, [market, marketConfig, config, theme]);

  return (
    <ToolTip
      overlay={<div>Capture Screenshot</div>}
      placement={ToolTipPosition[toolbarPosition]}
      mouseEnterDelay={0.5}>
      <div
        className={`cursor-pointer hover:text-trtp-primary`}
        onClick={takeScreenshot}>
        <HiOutlineCamera size={20} className="mx-auto" />
      </div>
    </ToolTip>
  );
};

export default Screenshot;
