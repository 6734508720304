import { MdFormatLineSpacing  } from "react-icons/md";
import Tool from "./Tool";

const FibRetracement = () => {
  return (
    <Tool
      text={"Fibonacci Retracement"}
      tool={"fib-retracement"}
      icon={<MdFormatLineSpacing size={20} />}
    />
  );
};

export default FibRetracement;
