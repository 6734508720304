import { useState, useEffect, useRef, useCallback } from 'react';
import { ChromePicker, SketchPicker } from 'react-color';
import { CgColorPicker } from 'react-icons/cg';
import { useDebouncedEffect } from '@react-hookz/web';

import useColorScheme from '../hooks/useColorscheme';
import { AnimatePresence, motion } from 'framer-motion';
import useIsMobile from 'src/hooks/useIsMobile';

type ColorPickerProps = {
  color: string;
  onColorPick?: (color: string) => void;
  debounce?: boolean;
};

const ColorPicker = ({ color, onColorPick, debounce = false }: ColorPickerProps) => {
  const isMobile = useIsMobile();
  const containerRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState<any>(color);
  const currentRef = useRef<HTMLDivElement | null>(null);

  const [open, setOpen] = useState(false);

  const colorscheme = useColorScheme();

  useDebouncedEffect(
    () => {
      if (onColorPick) {
        if (value) onColorPick(value);
      }
    },
    [value],
    debounce ? 200 : 0,
    500
  );

  const handleChange = (color: any) => {
    if (color == null) return;

    if (onColorPick) {
      setValue(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    }
  }

  const onPickerClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {

    const handleClick = (e: any) => {
      if (!containerRef.current) return;

      if (!containerRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    if (!open) {
      document.removeEventListener("click", handleClick);
    } else {
      document.addEventListener("click", handleClick);
    }

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [open, currentRef, containerRef]);

  return (<div ref={containerRef} className="flex flex-col select-none" >
    <div
      ref={currentRef}
      className={`cursor-pointer border border-gray-200 dark:border-gray-900 rounded-sm p-1 text-center ${color == "" ? "text-gray-400" : "text-white"}`}

      style={{ width: '40px', backgroundColor: color, fontSize: '7pt', margin: '3px' }}
      onClick={onPickerClick}>
      <CgColorPicker size={12} className="mx-auto" />
    </div>

    <AnimatePresence>
      {open && (
        <motion.div
          initial={{opacity: 0, height: 0}}
          animate={{opacity: 1, height: 'auto'}}
          exit={{opacity: 0, height: 0}}
        >
          {isMobile ? (
          <ChromePicker
            styles={{
              picker: {
                width: 200,
                padding: '10px',
                /** @ts-ignore */
                color: !isMobile && colorscheme == 'light' ? 'black' : 'white',
                backgroundColor: !isMobile && colorscheme == 'light' ? 'white' : 'black'
              }
            }}
            disableAlpha={false}
            color={color}
            onChange={handleChange}
          />
          ):(
          <SketchPicker
            styles={{
              picker: {
                width: 200,
                padding: '10px',
                /** @ts-ignore */
                color: colorscheme == 'light' ? 'black' : 'white',
                backgroundColor: colorscheme == 'light' ? 'white' : 'black'
              }
            }}
            disableAlpha={false}
            color={color}
            onChange={handleChange}
          />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  </div>);
}

export default ColorPicker;
