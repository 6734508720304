import { MdGridOn } from "react-icons/md";
import Tool from "./Tool";

const GannBox = () => {
  return (
    <Tool
      text={"Gann Box"}
      tool={"gann-box"}
      icon={<MdGridOn size={20} className={""} />}
    />
  );
};

export default GannBox;
