import { IoIosResize } from "react-icons/io";
import Tool from "./Tool";

const VerticalRay = () => {
  return (
    <Tool
      text={"Vertical Ray"}
      tool={"vertical-ray"}
      icon={<IoIosResize size={20} className={"transform -rotate-45"} />}
    />
  );
};

export default VerticalRay;
