import { useEffect, useState } from "react";

import useTool from "src/hooks/useTool";
import Row from "./Row";
import Switch, { SwitchOption } from "src/component/Switch";
import { Tool, Tools } from "common";
import useStateId from "src/hooks/useStateId";

const DEFAULT_TYPE = 2;

const types: SwitchOption[] = [
  {
    id: "2",
    value: 2,
    title: "Protractor"
  },
  {
    id: "1",
    value: 1,
    title: "Full Ring"
  },
];

  const doublearc_options: SwitchOption[] = [
    {
      id: "off",
      title: "Off",
      value: 0,
    },
    {
      id: "on",
      title: "On",
      value: 1,
    },
  ];

const Protractor = () => {
  const [type, setType] = useState<number>(Tools[Tool.Protractor].options.type.default as number);
  const [doubleArcs, setDoubleArcs] = useState((Tools[Tool.Protractor].options.doublearcs.default as number) == 1);

  const tool = useTool();
  const stateId = useStateId();

  useEffect(() => {
    if (tool === null) return;

    setType(tool.getOption("type"));
    setDoubleArcs(tool.getOption("doublearcs") === 1);
  }, [tool, stateId.id]);

  const handleArcChange = (option: SwitchOption) => {
    tool.setOption("doublearcs", option.value);

    setDoubleArcs(option.value === 1);
  };

  const handleChange = (option: SwitchOption) => {
    setType(option.value as number);
    tool?.setOption("type", type);
  };

  return (
    <div className="flex flex-col gap-2">
      <Row
        title="Style"
        component={
          <Switch
            options={types}
            selected={`${type}`}
            onChange={handleChange}
          />
        }
      />
    <Row
      title="Double Arcs"
      component={
        <div className="bg-gray-100 dark:bg-black p-1">
          <Switch
            options={doublearc_options}
            selected={doubleArcs ? "on" : "off"}
            onChange={handleArcChange}
          />
        </div>
      }
    />
    </div>
  );
};

export default Protractor;
