import { configureStore } from "@reduxjs/toolkit";
import { debounce } from "debounce";

import { counterSlice } from "./features/counter/counterSlice";
import { chartSlice } from "./features/counter/chartSlice";
import { bridgeSlice } from "./features/counter/bridgeSlice";
import { menuSlice } from "./features/counter/menuSlice";
import { appSlice } from "./features/counter/appSlice";
import { settingsSlice } from "./features/counter/settingsSlice";
import { presetsSlice } from "./features/counter/presetsSlice";
import { chartSettingsSlice } from "./features/counter/chartSettingsSlice";
import { marketsSlice } from "./features/counter/marketsSlice";
import { saveState, loadState } from './browser-storage'
import { mobileAppSlice } from "./features/mobile/mobileAppSlice";
import { isMobileDevice } from "src/hooks/useIsMobile";

export const store = configureStore({
  preloadedState: loadState(),
  reducer: {
    counter: counterSlice.reducer,
    chart: chartSlice.reducer,
    bridge: bridgeSlice.reducer,
    menu: menuSlice.reducer,
    app: appSlice.reducer,
    settings: settingsSlice.reducer,
    chartSettings: chartSettingsSlice.reducer,
    markets: marketsSlice.reducer,
    mobileApp: mobileAppSlice.reducer,
    presets: presetsSlice.reducer
  },
});

store.subscribe(
  debounce(() => {
    const state = store.getState();

    const stored_state = {
      chartSettings: state.chartSettings,
      settings: state.settings,
    };

    saveState(
      isMobileDevice() ? {
        ...stored_state,
        markets: {
          active: state.markets.active
        }
      } : stored_state
    );
  }, 200)
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
