
import CriterionPanel from "./CriterionPanel";
import Title from "./properties/Title";
import TradePanel from "./TradePanel";

const MtopsPanel = () => {
  return (
    <div className="flex flex-col justify-start text-xs dark:text-white flex-1">
      <Title>Checklist</Title>
      <CriterionPanel />
      <Title>Trade details</Title>
      <TradePanel />
    </div>
  );
};

export default MtopsPanel;
