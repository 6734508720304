import { useEffect, useState } from "react";
import LineWidthSwitch from "../../component/LineWidthSwitch";
import { SwitchOption } from "../../component/Switch";
import useTool from "src/hooks/useTool";
import Row from "../Row";
import useStateId from "src/hooks/useStateId";

const LWR = () => {
  const [lineWidth, setWidth] = useState<string>("0");

  const stateId = useStateId();
  const tool = useTool();

  useEffect(() => {
    if (tool === null) return;

    setWidth(tool.getOption("width"));
  }, [tool, stateId.id]);

  const handleWidthChange = (width: SwitchOption) => {
    setWidth(width.id);
    tool?.setOption("width", width.value);
  };

  return (<div className="flex flex-col gap-2">
      <Row
        title="Line width"
        component={
          <LineWidthSwitch selected={lineWidth} onChange={handleWidthChange} />
        }
      />
      </div>);
}

export default LWR;
