import { Tool, Tools } from "common";
import { useEffect, useState } from "react";

import ColorPicker from "src/component/ColorPicker";
import useStateId from "src/hooks/useStateId";
import useTool from "src/hooks/useTool";
import Row from "./Row";

const Arrow = () => {
  const [color, setColor] = useState<string>(Tools[Tool.Arrow].options.color.default as string);
  const [borderColor, setBorderColor] = useState<string>(Tools[Tool.Arrow].options.borderColor.default as string);

  const tool = useTool();
  const stateId = useStateId();

  useEffect(() => {
    if (tool === null) return;

    setColor(tool.getOption("color"));
    setBorderColor(tool.getOption("borderColor"));
  }, [tool, stateId.id]);

  const handleChange = (color: string) => {
    setColor(color);
    tool?.setOption("color", color);
  };

  const handleChangeBorder = (color: string) => {
    setBorderColor(color);
    tool?.setOption("borderColor", color);
  };

  return (
    <div className="flex flex-col gap-2">
      <Row
        title="Background"
        component={
          <ColorPicker
            color={color}
            onColorPick={(color) => handleChange(color)}
          />
        }
      />
      <Row
        title="Border"
        component={
          <ColorPicker
            color={borderColor}
            onColorPick={(color) => handleChangeBorder(color)}
          />
        }
      />
    </div>
  );
};

export default Arrow;
