import { AbstractTool } from "chart";
import { Tool } from "common";
import { useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import Modal from "src/component/Modal";
import useToolPreset from "src/hooks/useToolPreset";
import { addNewPreset } from "src/redux/features/counter/presetsSlice";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";


type NewPresetModalProperties = {
  tool: AbstractTool;
  onClose: () => void;
}

const MAX_NAME_LENGTH = 20;

const NewPresetModal = ({ tool, onClose }: NewPresetModalProperties) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [name, setName] = useState<string>("");

  const toolPreset = useToolPreset();

  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.presets.loading);

  useEffect(() => {
    if (!inputRef.current) return
    inputRef.current.focus();
  }, []);

  const createPreset = async () => {
    if (loading) return;
    if (name == "") return;
    const state = {
      name,
      tool: tool.typeName as Tool,
      options: toolPreset.getFilteredOptions(tool)
    }

    await dispatch(addNewPreset(state))

    onClose();
  };

  return (
    <Modal handleClose={onClose}>
      <div className="shadow-lg p-0 flex flex-col bg-gray-50 dark:bg-gray-950 dark:text-white text-sm rounded w-96 border border-transparent dark:border-gray-800">
        <div className="flex flex-col p-4 gap-3">
          <div className="font-bold px-2 text-gray-600 dark:text-white">Fill in the name for the new preset</div>
          <div className="text-xs px-2 text-gray-600 dark:text-white">The new preset will be created based on the current selected settings for the {tool.typeDisplayName} tool</div>
          <div className="flex-1 relative">
            <input
              ref={inputRef}
              className="p-1 px-2 border border-gray-200 dark:border-gray-900 rounded w-full dark:bg-black outline-trtp-primary dark:placeholder-gray-600"
              type="text"
              maxLength={MAX_NAME_LENGTH}
              defaultValue={""}
              placeholder={'Preset name...'}
              onChange={(input) => {
                setName(input.target.value)
              }}
            />
            <div
              className={'absolute right-4 top-[5px] text-xxs dark:text-gray-500 uppercase'}>
              {name.length == MAX_NAME_LENGTH ? ('max') : (<>{name.length}/{MAX_NAME_LENGTH}</>)}
            </div>
          </div>
        </div>
        <div className="flex gap-3 px-2 justify-end py-2 text-xs">
          {!loading && (<div className="px-4 py-2 hover:text-trtp-primary cursor-pointer" onClick={onClose}>Cancel</div>)}
          <div className="bg-trtp-primary rounded-sm text-white px-4 py-2 cursor-pointer" onClick={createPreset}>
            {loading ? (
              <div className="flex gap-2">
                <CgSpinner className="animate-spin" />
                <div> Working...</div>
              </div>
            ) : (
              <>Create</>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default NewPresetModal;
