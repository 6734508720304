import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { FaHistory, FaTimes } from "react-icons/fa";
import { MdEditNote, MdOutlineAddBox, MdOutlineDisabledByDefault, MdResetTv } from "react-icons/md";
import Tooltip from "rc-tooltip";

import { Asset, ASSETS, MarketInterface } from "common";

import OnOffSwitch from "../OnOffSwitch";
import { useConfig } from "../../context/ConfigContext";
import { useSocket } from "../../context/SocketContext";
import PrimaryButton from "../../properties/PrimaryButton";
import Row from "../../properties/Row";
import Title from "../../properties/Title";
import { selectMarket, createMarket, patchPriceSetting, patchSetting, setAssetPrice, removeFavorite, setFavorite } from "../../redux/features/counter/marketsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import HistoryImportModal from "./MarketsPanel/HistoryImportModal";
import AssetChip from "../MarketsPanel/AssetChip";
import MarketRow from "./MarketsPanel/MarketRow";
import useRestBackend from "../../hooks/useRestBackend";

const MarketsPanel = ({ afterSelect = () => { } }: { afterSelect?: () => void }) => {
  const { sockets } = useSocket();
  const { isDevelopment, restBackendUrl, session } = useConfig();

  const marketContainerRef = useRef(null);

  const { restCall } = useRestBackend();

  const [filter, setFilter] = useState("");
  const [showAdmin, setShowAdmin] = useState(false);
  const [showHistoryImport, setShowHistoryImport] = useState(false);

  const [assetType, setAssetType] = useState<string>("");

  const dispatch = useAppDispatch();

  const markets = useAppSelector((root) => root.markets.markets);
  const currentMarket = useAppSelector((root) => root.markets.active);
  const current = useAppSelector((root) => root.markets.markets.find((m) => m.identifier == currentMarket));

  const config = useConfig();

  /** @ts-ignore */
  const plan = config.session.tpro.pln;

  useEffect(() => {
    if (!sockets?.mts) return;
    if (markets.length == 0) return;

    const onPriceChange = (data: [string, string]) => {
      dispatch(setAssetPrice([data[0], parseFloat(data[1])]))
    }

    sockets.mts.emit('pricechange:subscribe');
    sockets.mts.on('pricechange', onPriceChange);

    return () => {
      sockets?.mts?.emit('pricechange:unsubscribe');
      sockets?.mts?.off('pricechange', onPriceChange);
    }
  }, [sockets]);

  const resetLocalSettings = () => {
    localStorage.clear();
    toast.info("Localstorage has been reset");
  };

  const saveMarket = async () => {
    if (!current) return;

    const response = await fetch(
      restBackendUrl + "/market",
      {
        method: current.isnew ? 'POST' : 'PUT',
        headers: new Headers({
          // @ts-ignore
          Authorization: `Bearer ${session.access_token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify(current)
      });

    await response.json();

    if (current?.isnew) {
      window.location.reload(); // @TODO this is lazy, we should update state
    } else {
      setShowAdmin(false);
      toast.success(`${current?.name} saved`);
    }
  };

  const removeMarket = async () => {
    if (!current) return;

    const response = await fetch(
      restBackendUrl + "/market/" + current.symbol,
      {
        method: 'DELETE',
        headers: new Headers({
          // @ts-ignore
          Authorization: `Bearer ${session.access_token}`
        })
      });

    await response.json();

    //window.location.reload(); // @TODO this is lazy, we should update state
  };

  const handleCreateMarket = async () => {
    const id = prompt("Enter identifier");

    if (id) {
      dispatch(createMarket(id));
      dispatch(selectMarket(id));
    }
  };

  const handleFavorite = async (market: string, isFavorite: boolean) => {
    await restCall("/favorite", {
      method: isFavorite ? "POST" : "DELETE",
      body: JSON.stringify({ market })
    });

    if (isFavorite) {
      dispatch(setFavorite(market));

      if (marketContainerRef.current) {
        // @ts-ignore
        marketContainerRef.current?.scrollTo({
          top: 0,
          behavior: 'smooth'
        });

        dispatch(selectMarket(market));
      }
    } else {
      dispatch(removeFavorite(market));
    }
  }

  const filterMarkets = (market: MarketInterface) => {
    if (assetType != "") {
      if (market.asset_type != assetType) {
        return false;
      }
    }

    return filter == ""
      || market.name.toLowerCase().indexOf(filter.toLowerCase()) != -1
      || market.identifier.toLowerCase().indexOf(filter.toLowerCase()) != -1;
  };

  const renderMarketRow = (market: MarketInterface) => (
    <MarketRow
      key={market.identifier}
      market={market}
      isFavorite={market.is_favorite || false}
      onFavoriteSelect={(isFav: boolean) => {
        handleFavorite(market.identifier, isFav);
      }}
      afterSelect={afterSelect} />
  );

  return (
    <div ref={marketContainerRef} className=" flex flex-col gap-2 overflow-y-auto text-xs dark:text-white flex-1">
      <div className="flex flex-col flex-1 overflow-y-auto">
        <Title>
          <div className="flex justify-between">
            <div className="flex gap-1 my-auto">
              Markets
            </div>
            <div className="flex justify-end">
              <AssetChip text={"All"} selected={assetType == ""} onSelect={() => setAssetType("")} />
              {ASSETS.filter((asset: Asset) => {
                if (plan != 0) return true;
                if (asset.id == "future") return false;

                return true;
              }).map((asset: Asset) => (
                <AssetChip
                  key={asset.id}
                  text={asset.name}
                  selected={assetType == asset.id}
                  onSelect={() => setAssetType(asset.id)}
                />
              ))}
            </div>
          </div>
        </Title>
        <div className="flex sticky top-[32px]  bg-gray-50 dark:bg-black pr-2">
          <input
            type="text"
            className="flex-1 bg-transparent pl-2 outline-none py-4 md:py-3"
            placeholder="Filter markets..."
            value={filter}
            onChange={(field) => setFilter(field.target.value)} />
          <FaTimes className={`my-auto  ${filter != "" ? "hover:text-trtp-primary cursor-pointer" : "hidden"}`} onClick={() => setFilter("")} />
          {isDevelopment && (
            <>
              <Tooltip overlay="Add market" placement="bottom">
                <MdOutlineAddBox size={16} className="my-auto dark:text-gray-500 hover:text-trtp-primary dark:hover:text-trtp-primary cursor-pointer" onClick={handleCreateMarket} />
              </Tooltip>
              <Tooltip overlay="Show admin" placement="bottom">
                <MdEditNote size={16} className={`my-auto dark:text-gray-500 hover:text-trtp-primary dark:hover:text-trtp-primary cursor-pointer ${showAdmin ? "bg-trtp-primary text-white rounded-sm" : ""}`} onClick={() => setShowAdmin(!showAdmin)} />
              </Tooltip>
            </>
          )}
        </div>
        {markets
          .filter(filterMarkets)
          .filter((value) => value.is_favorite == true)
          .filter((value) => {
            if (value.asset_type == 'future' && plan == 0) return false;
            return true;
          })
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(renderMarketRow)}

        {markets
          .filter(filterMarkets)
          .filter((value) => value.is_favorite == false)
          .filter((value) => {
            if (value.asset_type == 'future' && plan == 0) return false;
            return true;
          })
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(renderMarketRow)}
      </div>

      {isDevelopment && current && showAdmin && (
        <div className="py-4">
          <Title>
            <div className="flex justify-between">
              <div>
                {current.name}
              </div>
              <div className="flex gap-2">
                <Tooltip overlay="Import history" placement="bottom">
                  <FaHistory size="16" className="dark:text-gray-500 hover:text-trtp-primary dark:hover:text-trtp-primary cursor-pointer" onClick={() => setShowHistoryImport(true)} />
                </Tooltip>
                <Tooltip overlay="Reset localstorage" placement="bottom">
                  <MdResetTv size={16} onClick={resetLocalSettings} />
                </Tooltip>
                <Tooltip overlay={`Delete ${current.name}`} placement="bottom">
                  <MdOutlineDisabledByDefault
                    size={16}
                    className="dark:text-gray-500 hover:text-trtp-primary dark:hover:text-trtp-primary cursor-pointer"
                    onClick={removeMarket}
                  />
                </Tooltip>
              </div>
            </div>
          </Title>
          <Row
            title="Identifier"
            component={
              <div>{current.identifier}</div>
            }
          />
          <Row
            title="Active"
            component={
              <OnOffSwitch
                selected={current.active}
                onChange={(option) => {
                  dispatch(patchSetting({
                    market: current.identifier,
                    key: "active",
                    value: option == "on"
                  }));
                }}
              />
            }
          />
          <Row
            title="Currency"
            component={
              <input type="text" defaultValue={current.currency} key={`currency_${current.identifier}`} onChange={(value) => {
                dispatch(patchSetting({
                  market: current.identifier,
                  key: "currency",
                  value: value.target.value
                }));
              }} />
            }
          />
          <Row
            title="Precision"
            component={
              <input type="number" defaultValue={current.precision} key={`precision_${current.identifier}`} onChange={(value) => {
                const val = parseFloat(value.target.value);

                if (isNaN(val)) return;

                dispatch(patchSetting({
                  market: current.identifier,
                  key: "precision",
                  value: val
                }));
              }} />
            }
          />
          <Row
            title="Name"
            component={
              <input type="text" defaultValue={current.name} key={`name_${current.identifier}`} onChange={(value) => {
                dispatch(patchSetting({
                  market: current.identifier,
                  key: "name",
                  value: value.target.value
                }));
              }} />
            }
          />
          <Row
            title="Symbol"
            component={
              <input type="text" defaultValue={current.symbol} key={`symbol_${current.identifier}`} onChange={(value) => {
                dispatch(patchSetting({
                  market: current.identifier,
                  key: "symbol",
                  value: value.target.value
                }));
              }} />
            }
          />
          <Row
            title="Provider"
            component={
              <input type="text" defaultValue={current.provider} key={`provider_${current.identifier}`} onChange={(value) => {
                dispatch(patchSetting({
                  market: current.identifier,
                  key: "provider",
                  value: value.target.value
                }));
              }} />
            }
          />
          <Row
            title="Provider ID"
            component={
              <input type="text" defaultValue={current.provider_id} key={`providerid_${current.identifier}`} onChange={(value) => {
                dispatch(patchSetting({
                  market: current.identifier,
                  key: "provider_id",
                  value: value.target.value
                }));
              }} />
            }
          />
          {Object.entries(current.mts_pricelines).map(([timeframe, value]) => (
            <Row
              key={`price_line_${timeframe}`}
              title={`Price line ${timeframe}`}
              component={
                <input type="text" defaultValue={value} key={`pl_${current.identifier}`} onChange={(value) => {
                  const price = parseFloat(value.target.value);

                  if (isNaN(price)) return;

                  dispatch(patchPriceSetting({
                    market: current.identifier,
                    timeframe: parseFloat(timeframe),
                    value: price
                  }));
                }} />
              }
            />
          ))}
          <div className="flex flex-col gap-4 p-4">
            <PrimaryButton text={`Save ${current.name}`} onPress={saveMarket} />
          </div>
        </div>
      )}

      {showHistoryImport && (
        <HistoryImportModal handleClose={() => { setShowHistoryImport(!showHistoryImport) }} />
      )}
    </div>
  );
}

export default MarketsPanel;
