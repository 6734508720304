import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { useAppSelector } from "src/redux/hooks";
import useIsMobile from "src/hooks/useIsMobile";

const Christmas = () => {
  const [el, setEl] = useState<any>();

  const [isChristmas, setChristmas] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const menu = useAppSelector((state) => state.menu);

  useEffect(() => {
    setEl(document.getElementById('header'));

    const now = DateTime.now();
    setChristmas(now.month === 12 && (now.day >= 20 && now.day < 27));

  }, [menu.minimized]);

  if (isMobile) {
    return (<>
      {isChristmas && (
        <>
<div className="absolute top-[95px] right-[39px] text-xxs text-orange-600 font-bold px-2 rounded" style={{ zIndex: '20', pointerEvents: 'none' }}>Merry Christmas!</div>
<img
                className="absolute top-[71px] right-[113px] w-[50px]"
                style={{ zIndex: 200 }}
                src="santa-hat.png" />
          </>
      )}
    </>)
  } else {
    return (
      <>
        {isChristmas && (
          <>
            {el && (
              createPortal(<div className="absolute top-[40px] left-[45px] text-xxs text-orange-600 font-bold bg-white dark:bg-black px-2 rounded" style={{ zIndex: '2', pointerEvents: 'none' }}>Merry Christmas!</div>, el))}

            {el && (
              createPortal(<img
                className="absolute top-[4px] left-[-10px] w-[75px]"
                style={{ zIndex: 100 }}
                src="santa-hat.png" />
                , el))}

            {el && (
              createPortal(<img
                className="absolute top-[21px] left-[-8px] mx-auto opacity-70 dark:opacity-40 "
                style={{ zIndex: 1, pointerEvents: 'none' }}
                src="christmas-divider.png" />
                , el))}
          </>
        )}
      </>
    )

  }
}

export default Christmas;
