import { ReactComponent as TrtpLogo } from '../../trtp-logo.svg';
import { useConfig } from 'src/context/ConfigContext';

const BrandingLogo = () => {
  const config = useConfig();

  /** @ts-ignore */
  const plan = config.session.tpro.pln;

  /** @ts-ignore */
  const isDevelopment = window.location.href.indexOf('.dev') != -1;

  return (
    <div className="flex my-5 mx-3 gap-2" style={{zIndex: '2'}}>
      <TrtpLogo className="dark:invert" style={{ width: '25px', height: '25px' }} />
      <div className="flex my-auto dark:text-white text-sm select-none font-semibold">
        <div className="my-auto">
          Tesseract Pro
        </div>
        {plan == 0 && (
            <div className="text-xs rounded-sm px-2 py-1 bg-gray-200 dark:bg-black ml-1 text-gray-500" style={{fontSize: '7pt'}}>Free</div>
        )}
        {plan == 2 && (
            <div className="text-xs rounded-sm px-2 py-1 bg-trtp-primary  ml-1 text-white" style={{fontSize: '7pt'}}>PLUS</div>
        )}
        {isDevelopment && (
            <div className="text-xs rounded-sm px-2 py-1 bg-red-600  ml-3 text-white" style={{fontSize: '7pt'}}>Development</div>
        )}
      </div>
    </div>

  );
};

export default BrandingLogo;
