import ToolTip from 'rc-tooltip';
import { useCallback, useEffect, useState } from 'react';
import { useChart } from 'src/context/ChartContext';
import useIsMobile from 'src/hooks/useIsMobile';
import { Tool as ToolDef, Tools } from 'common';

import { useAppSelector } from '../../../redux/hooks';

type ToolProperties = {
  tool: string;
  text: string;
  keybinding?: string;
  icon: JSX.Element;
};

export const ToolTipPosition = {
  "left": "right",
  "right": "left",
  "float": "bottom"
}

const Tool = ({ tool, keybinding, text, icon }: ToolProperties) => {
  const activeTool = useAppSelector((state) => state.chart.tool);
  const activeTimeframe = useAppSelector((state) => state.chart.timeframe);
  const toolbarPosition = useAppSelector((state) => state.settings.toolbarPosition);
  const presets = useAppSelector((state) => state.presets.presets[tool as ToolDef]);
  const defaultPreset = useAppSelector((state) => {
    return state.settings.defaultPreset ? state.settings.defaultPreset[tool as ToolDef] ?? '' : ''
  });

  const isMobile = useIsMobile();
  const chartProvider = useChart();

  // // install keyboard handler
  useEffect(() => {
    if (!Tools[tool as ToolDef].shortcut) return;

    const handleKeyDown = (keyboardEvent: KeyboardEvent) => {
      /** @ts-ignore */
      if (keyboardEvent.target?.localName === "textarea") return;
      /** @ts-ignore */
      if (keyboardEvent.target?.localName === "input") return;

      if (keyboardEvent.repeat) return;

      // Dont need shifted keys
      if (keyboardEvent.shiftKey) return;

      const opt = Tools[tool as ToolDef].shortcut;

      if (!opt) return;

      if (opt.ctrl === true && (keyboardEvent.ctrlKey === false || keyboardEvent.metaKey === false)) {
        return;
      } else if (!opt.ctrl && (keyboardEvent.ctrlKey || keyboardEvent.metaKey)) {
        return;
      }

      if (opt.key?.toLowerCase() == keyboardEvent.key.toLowerCase()) {
        selectTool(tool);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeTimeframe, chartProvider, defaultPreset]);

  const selectTool = useCallback(
    (tool: string) => {
      const chart = chartProvider.get(activeTimeframe);

      if (!chart) return;

      if (presets && defaultPreset != '') {
        const toolPreset = presets.filter((preset) => preset.id == defaultPreset);
        if (toolPreset.length == 0) {
          // The preset default is assigned but not found in the preset list
          // Using the defaults.
          // @ts-ignore
          chart.tools.startPlace(tool);
        } else {
          // @ts-ignore
          chart.tools.startPlace(tool, toolPreset[0].options);
        }
      } else {
        // @ts-ignore
        chart.tools.startPlace(tool);
      }
    },
    [activeTimeframe, chartProvider, defaultPreset, presets]
  );

  if (isMobile) {
    return (
      <div className={`flex flex-col gap-3`} onClick={() => selectTool(tool)}>
        <div className="mx-auto text-gray-800 dark:text-white ">{icon}</div>
        <div className="text-gray-800 dark:text-white text-center">{text}</div>
      </div>
    );
  } else {
    return (
      <ToolTip
        overlay={
          <div>
            {text}
            {(keybinding && keybinding != '') || Tools[tool as ToolDef].shortcut?.key && (
              <span className="px-2 py-1 ml-1 font-mono text-xs bg-gray-800 dark:text-gray-400 rounded-md">
                {keybinding || Tools[tool as ToolDef].shortcut?.key}
              </span>
            )}
          </div>
        }
        placement={ToolTipPosition[toolbarPosition]}
        mouseEnterDelay={0.2}>
        <div
          className={`mx-auto cursor-pointer hover:text-trtp-primary ${activeTool === tool ? 'text-trtp-trtp-primary' : ''
            }`}
          onClick={() => selectTool(tool)}>
          {icon}
        </div>
      </ToolTip>
    );
  }
};

export default Tool;
