import { PiSpiral } from "react-icons/pi";
import Tool from "./Tool";

const Marker = () => {
  return (
    <Tool
      text={"Spiral"}
      tool={"spiral"}
      icon={<PiSpiral size={20} />}
    />
  );
};

export default Marker;
