import ToolTip from 'rc-tooltip';
import { FaTimes } from 'react-icons/fa';
import useTool from '../../../hooks/useTool';
import { ToolTipPosition } from "./Tool";

import { useAppSelector } from '../../../redux/hooks';

const Delete = () => {
  const activeTool = useAppSelector((state) => state.chart.tool);
  const toolbarPosition = useAppSelector((state) => state.settings.toolbarPosition);

  const tool = useTool();

  const deleteTool = () => {
    tool?.chart.tools.delete(tool);
  }

  return (
    <ToolTip
      overlay={
        <div>
          Delete selected
          <span className="px-2 py-1 ml-1 font-mono text-sm bg-gray-800 dark:text-gray-400 rounded-md">
            Backspace
          </span>
        </div>
      }
      placement={ToolTipPosition[toolbarPosition]}
      mouseEnterDelay={0.5}>
      {activeTool !== 'move' ? (
        <div
          className={`cursor-pointer hover:text-trtp-primary`}
          onClick={deleteTool}>
          <FaTimes size={20} className="mx-auto"/>
        </div>
      ) : (
        <div className={`text-gray-400 dark:text-gray-800 mx-auto`} onClick={deleteTool}>
          <FaTimes size={20} className="mx-auto"/>
        </div>
      )}
    </ToolTip>
  );
};

export default Delete;
