import { useEffect, useState } from "react";
import useTool from "src/hooks/useTool";
import Row from "./Row";
import OnOffSwitch, { OnOffSwitchOption } from "src/component/OnOffSwitch";
import { Tool, Tools } from "common";
import useStateId from "src/hooks/useStateId";
import ColorPicker from "src/component/ColorPicker";

const Marker = () => {
  const [label, setLabel] = useState<boolean>(Tools[Tool.Marker].options.show_label.default as boolean);
  const [color, setColor] = useState<string>("");

  const tool = useTool();
  const stateId = useStateId();

  useEffect(() => {
    if (tool === null) return;

    setLabel(tool.getOption("show_label"));
    setColor(tool.getOption("color"));
  }, [tool, stateId.id]);

  const handleColorChange = (color: string) => {
    setColor(color);
    tool?.setOption("color", color);
  };

  const handleChange = (option: OnOffSwitchOption) => {
    setLabel(option == "on");
    tool?.setOption("show_label", option == "on");
  };

  return (
    <div className="flex flex-col gap-2">
      <Row
        title="Color"
        component={
          <ColorPicker
            color={color}
            onColorPick={(color) => handleColorChange(color)}
          />
        }
      />
      <Row
        title="Countdown"
        component={
          <OnOffSwitch
            selected={label}
            onChange={handleChange}
          />
        }
      />
    </div>
  );
};

export default Marker;
