import { AbstractTool } from "chart";
import { useEffect, useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { BsLock, BsUnlock } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { IoDuplicateOutline } from "react-icons/io5";
import useIsMobile from "src/hooks/useIsMobile";
import useTitleToolSize from "src/hooks/useTitleToolSize";
import { setSheetOpen } from "src/redux/features/mobile/mobileAppSlice";
import { useAppDispatch} from "src/redux/hooks";
import { TitleButton } from "./TitleButton";
import TitleButtonContainer from "./TitleButtonContainer";
import PresetDropDown from "./ToolTitle/PresetDropdown";
import PresetContainer from "./ToolTitle/PresetContainer";

type ChildrenProps = {
  tool: AbstractTool;
  allowDuplicate?: boolean;
  allowLock?: boolean;
  allowDelete?: boolean;
  hasPresets?: boolean;
  children: React.ReactNode | string;
};

const ToolTitle = ({
  tool,
  children,
  hasPresets = true,
  allowLock = true,
  allowDuplicate = true,
  allowDelete = true
}: ChildrenProps) => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  const [locked, setLocked] = useState(false);
  const [visible, setVisible] = useState(true);
  const [showPresets, setShowPresets] = useState(false);

  const toolSize = useTitleToolSize();

  useEffect(() => {
    if (tool === null) return;

    setShowPresets(false);

    setLocked(tool?.getOption("locked", "0") === "1");
    setVisible(tool?.getOption("visible", "1") === "1");
  }, [tool])

  // Install keyboard handler 
  useEffect(() => {
    const downHandler = (key: any) => {
      /** @ts-ignore */
      if (key.target?.localName === "textarea") return;
      /** @ts-ignore */
      if (key.target?.localName === "input") return;

      if (key.ctrlKey && key.key === 'd') {
        key.preventDefault();

        tool.duplicate();
      }
    }

    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [tool]);

  const lock = (locked: boolean) => {
    tool.setOption("locked", locked ? "1" : "0");
    setLocked(locked);

    if (isMobile) dispatch(setSheetOpen(false));
  }

  const handleDuplicate = () => {
    tool?.duplicate();

    if (isMobile) dispatch(setSheetOpen(false));
  }

  const handleDelete = () => {
    tool.chart.tools.delete(tool);

    if (isMobile) dispatch(setSheetOpen(false));
  }

  const handleVisible = () => {
    setVisible(true);
    tool.setOption("visible", "1");

    if (isMobile) dispatch(setSheetOpen(false));
  }

  const handleHide = () => {
    setVisible(false);
    tool.setOption("visible", "0");

    if (isMobile) dispatch(setSheetOpen(false));
  }



  if (!tool) return <></>;

  return (
    <div className={`relative flex flex-col ${isMobile == false && "sticky top-0"}`}>
      <div className={`flex gap-2 justify-between bg-gray-100 dark:bg-gray-950 dark:text-gray-300 p-2 dark:font-bold`}>
        {tool.typeName != "turningpoint" && (
          <div>
            {visible ? (
              <TitleButton activated={true} tooltip={"Hide"} onClick={handleHide}>
                <BiShow size={toolSize} />
              </TitleButton>
            ) : (
              <TitleButton activated={false} tooltip={"Show"} onClick={handleVisible}>
                <BiHide size={toolSize} />
              </TitleButton>
            )}
          </div>
        )}
        {hasPresets ? (
          <PresetDropDown
            children={children}
            onClick={() => setShowPresets(!showPresets)}
            showPresets={showPresets}
            tool={tool} />
        ) : (
          <div className="my-auto flex-1 text-xs">
            {children}
          </div>
        )}
        {tool && (
          <TitleButtonContainer>
            {allowLock && (
              <>
                {locked ? (
                  <TitleButton activated={true} tooltip={`Unlock ${tool.typeDisplayName}`} onClick={() => lock(false)}>
                    <BsLock size={toolSize} />
                  </TitleButton>
                ) : (
                  <TitleButton activated={false} tooltip={`Lock ${tool.typeDisplayName}`} onClick={() => lock(true)}>
                    <BsUnlock size={toolSize} />
                  </TitleButton>
                )}
              </>
            )}
            {allowDuplicate && (
              <TitleButton activated={false} shortcut="ctrl-d" tooltip={"Duplicate"} onClick={handleDuplicate}>
                <IoDuplicateOutline size={toolSize} />
              </TitleButton>
            )}
            {allowDelete && (
              <TitleButton activated={false} shortcut='backspace' tooltip={'Delete'} onClick={handleDelete}>
                <FaTimes size={toolSize} />
              </TitleButton>
            )}
          </TitleButtonContainer>
        )}
      </div>
      <PresetContainer tool={tool} showPresets={showPresets} setShowPresets={setShowPresets} />
    </div>
  );
};

export default ToolTitle;
