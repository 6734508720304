import { useEffect, useState } from "react";
import { useChart } from "src/context/ChartContext";

import { useAppSelector } from "../redux/hooks";

const useTurningPoint = () => {
  const [turningPoint, setTurningPoint] = useState<any>(null);

  const activeTurningPoint = useAppSelector(
    (state) => state.chart.turningpoint
  );
  const activeTimeframe = useAppSelector((state) => state.chart.timeframe);
  const market = useAppSelector((state) => state.markets.active);

  const chartProvider = useChart();

  useEffect(() => {
    if (activeTurningPoint == null) return;

    const chart = chartProvider.get(activeTimeframe);

    if (!chart) return;

    const tp = chart?.turningpoints.forecast?.turningpoint || null;

    setTurningPoint(tp);
  }, [activeTurningPoint, activeTimeframe, market]);

  return turningPoint;
};

export default useTurningPoint;
