import { BsCircle } from "react-icons/bs";
import Tool from "./Tool";

const Circle = () => {
  return (
    <Tool
      text={"Circle"}
      tool={"circle"}
      icon={
        <BsCircle size={20} />
      }
    />
  );
};

export default Circle;
