import { useAppSelector } from '../../../redux/hooks';

const MarketLabel = () => {
  const currentMarket = useAppSelector((state) => state.markets.active);
  const market = useAppSelector((root) => root.markets.markets.find((m) => m.identifier == currentMarket));

  return (
    <div className="text-gray-400 dark:text-gray-600">{market!.symbol}</div>
  );
}

export default MarketLabel;
