import { TiWiFiOutline } from "react-icons/ti";
import Tool from "./Tool";

const GannSquare = () => {
  return (
    <Tool
      text={"Gann Square Fixed"}
      tool={"gann-square"}
      icon={<TiWiFiOutline size={26} className={`transform rotate-45 x-12`}  />}
    />
  );
};

export default GannSquare;
