import { HiOutlineArrowRight } from "react-icons/hi";
import Tool from "./Tool";

const Arrow = () => {
  return (
    <Tool
      text={"Arrow"}
      tool={"arrow"}
      icon={
        <HiOutlineArrowRight size={20} className={"transform -rotate-45"} />
      }
    />
  );
};

export default Arrow;
