import Modal from "./Modal";

type ConfirmModalProperties = {
  onSelect: () => void;
  onClose: () => void;
  title?: string;
  message?: string | React.ReactElement | React.ReactElement[]

  YesText?: string;
  NoText?: string;
}

const ConfirmModal = ({ onSelect, onClose, title = "Are you sure?", message = "Confirm current action", YesText = "Yes", NoText = "No" }: ConfirmModalProperties) => {
  return (
    <Modal handleClose={onClose}>
      <div className="flex flex-col bg-gray-50 dark:bg-gray-950 dark:text-white text-sm rounded w-96 border border-transparent dark:border-gray-900">
        <div className="flex-1 font-semibold bg-gray-100 dark:bg-gray-950 rounded-t p-4">{title}</div>
        <div className="p-4">
          {message}
        </div>
        <div className="flex gap-3 px-2 justify-end py-2 text-xs">
          <div className="px-4 py-2 hover:text-trtp-primary cursor-pointer" onClick={onClose}>{NoText}</div>
          <div className="bg-trtp-primary rounded-sm text-white px-4 py-2 cursor-pointer" onClick={onSelect}>{YesText}</div>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmModal;
