import { FaDiscord, FaRegCalendarAlt, FaRegKeyboard, FaRegObjectGroup } from "react-icons/fa";
import Tooltip from "rc-tooltip";
import { MdOutlineSettingsInputComponent } from "react-icons/md";

import { maximize, minimize, selectItem, TabType } from "../../../redux/features/counter/menuSlice";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { showDiscordWelcome, showKeyboardHelp } from "../../../redux/features/counter/appSlice";
import { useEffect } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { useConfig } from "src/context/ConfigContext";
import { TbListDetails } from "react-icons/tb";
import useIsMobile from "src/hooks/useIsMobile";

type TabProps = {
  id: string;
  icon: JSX.Element;
  tooltip: string;
  keybinding: string;
}

const Tab = (prop: TabProps) => {
  const dispatch = useAppDispatch();
  const selected = useAppSelector((state) => state.menu.selected === prop.id);
  const minimized = useAppSelector((state) => state.menu.minimized);

  return (
    <Tooltip overlay={<div>
      {prop.tooltip}
      {prop.keybinding != '' && (
        <span className="px-2 py-1 ml-1 text-xs bg-gray-800 dark:text-gray-400 rounded-md font-mono">
          {prop.keybinding}
        </span>
      )}
    </div>
    } placement={"left"} mouseEnterDelay={0.75}>
      <div
        className={`cursor-pointer ${selected && minimized == false ? "bg-trtp-primary text-white" : "text-gray-500 hover:text-trtp-primary dark:hover:text-white"} w-11 h-11 flex -pr-4 transition-all border border-transparent hover:border-trtp-primary `}
        onClick={() => {
          dispatch(selectItem(prop.id));
          dispatch(maximize());
        }}
      >
        <div className="m-auto">{prop.icon}</div>
      </div>
    </Tooltip>
  )
};

const Tabs = () => {
  const dispatch = useAppDispatch();
  const config = useConfig();

  const selected = useAppSelector((state) => state.menu.selected);
  const minimized = useAppSelector((state) => state.menu.minimized);

  const isMobile = useIsMobile();

  const openDiscord = () => {
    // @ts-ignore
    if (config.session.discord.is_invited == true && config.session.discord.invite_completed == false) {
      dispatch(showDiscordWelcome(true));
    } else {
      window.open("discord://discord.com/channels/1106463330668912651/1107145944446795816");
    }
  }

  useEffect(() => {
    const downHandler = (key: any) => {
      /** @ts-ignore */
      if (key.target?.localName === "textarea") return;
      /** @ts-ignore */
      if (key.target?.localName === "input") return;

      if (key.key === 'Tab') {
        //if(selected != TabType.Markets) dispatch(selectItem(TabType.Markets));
        dispatch(minimized ? maximize() : minimize());

        key.preventDefault();
      }

      if (key.ctrlKey && key.key === 'q') {
        dispatch(selectItem(TabType.Properties));
        dispatch(maximize());
        key.preventDefault();
      }

      if (key.ctrlKey && key.key === 'w') {
        dispatch(selectItem(TabType.Calendar));
        dispatch(maximize());
        key.preventDefault();
      }

      if (key.ctrlKey && key.key === 'e') {
        dispatch(selectItem(TabType.Mtops));
        dispatch(maximize());
        key.preventDefault();
      }

      if (key.ctrlKey && key.key === 'r') {
        dispatch(selectItem(TabType.Settings));
        dispatch(maximize());
        key.preventDefault();
      }

      if (key.ctrlKey && key.key === 't') {
        dispatch(selectItem(TabType.Tools));
        dispatch(maximize());
        key.preventDefault();
      }

      if (key.ctrlKey && key.key === 'o') {
        openDiscord();
        key.preventDefault();
      }

      if (key.key === '?') {
        dispatch(showKeyboardHelp(true));
        key.preventDefault();
      }
    }

    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [minimized, selected]);

  return (
    <>
      <div className={`flex flex-col justify-between bg-gray-50 dark:bg-gray-950 overflow-y-scroll`} style={{ minWidth: isMobile ? undefined : "45px" }}>
        <div className={`${isMobile && "flex"}`}>
          <Tab id={TabType.Markets} icon={<HiOutlineChartSquareBar size={20} />} tooltip={"Markets"} keybinding={"ctrl-q"} />
          <Tab id={TabType.Properties} icon={<MdOutlineSettingsInputComponent size={20} />} tooltip={"Properties"} keybinding={"ctrl-q"} />
          <Tab id={TabType.Calendar} icon={<FaRegCalendarAlt size={20} />} tooltip={"Forex Calendar"} keybinding={"ctrl-w"} />
          {config.mtsFeatures && (
            <Tab id={TabType.Mtops} icon={<TbListDetails size={20} />} tooltip={"Checklist"} keybinding={"ctrl-e"} />
          )}
          <Tab id={TabType.Tools} icon={<FaRegObjectGroup size={20} />} tooltip={"Tools"} keybinding={"ctrl-t"} />
          <Tab id={TabType.Settings} icon={<IoSettingsOutline size={20} />} tooltip={"Settings"} keybinding={"ctrl-r"} />
        </div>
        {isMobile == false && (
          <>
        <div style={{ minHeight: '10px' }}></div>
        <div>
          <div className="cursor-pointer text-gray-500 hover:text-trtp-primary mb-2" onClick={openDiscord}>
            <Tooltip overlay={<div>Tesseract Pro Discord (Discord Desktop)<span className="px-2 py-1 ml-1 text-xs bg-gray-800 dark:text-gray-400 rounded-md font-mono">
              ctrl-o
            </span>
            </div>
            } placement={"left"} mouseEnterDelay={0.75}>
              <FaDiscord size={22} className="m-auto" />
            </Tooltip>
          </div>
          <div className="cursor-help text-gray-500 hover:text-trtp-primary mb-2" onClick={() => dispatch(showKeyboardHelp(true))}>
            <Tooltip overlay={<div>Keyboard Shortcuts<span className="px-2 py-1 ml-1 text-xs bg-gray-800 dark:text-gray-400 rounded-md font-mono">
              ?
            </span>
            </div>
            } placement={"left"} mouseEnterDelay={0.75}>
              <FaRegKeyboard size={22} className="m-auto" />
            </Tooltip>
          </div>
        </div>
        </>
        )}
      </div>
    </>
  )
}

export default Tabs;
