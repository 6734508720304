import { AbstractTool } from "chart";
import { Preset, Tools } from "common";
import { useState } from "react";
import useStateId from "./useStateId";

const useToolPreset = () => {
  const { update: updateStateId } = useStateId();

  const [hoverState, setHoverState] = useState<any>({});

  // returns valid options for this tool
  const getValidOptions = (tool: AbstractTool): string[] => {
    const toolConfig = Tools[tool.typeName];

    if (!toolConfig) return [];

    // Gets the valid options of this tool
    return Object.keys(toolConfig.options)
      .filter((key) => {
        const preset = toolConfig.options[key].preset;

        switch (typeof preset) {
          case 'boolean':
            return preset === true
          case 'function':
            return preset(tool);
          default:
            return false;
        }
      });
  }

  // returns a object with valid options for the given tool
  const getFilteredOptions = (tool: AbstractTool): any => {
    const options = Object.fromEntries(tool.options);
    const validOptions = getValidOptions(tool);

    return Object.keys(options)
      .filter(key => validOptions.includes(key))
      .reduce((obj, key) => {
        //@ts-ignore
        obj[key] = options[key];
        return obj;
      }, {});
  }

  const set = (tool: AbstractTool, preset: Preset, silent: boolean = false) => {
    if (silent == false) {
      // resets state
      setHoverState({});
    }

    const validOptions = getValidOptions(tool);

    // merge all default options with the preset 
    // specific options
    const defaultOptions = Tools[tool?.typeName ?? ""].options || {};

    const options = {
      ...Object.keys(defaultOptions).reduce((result, key) => {
        //@ts-ignore
        result[key] = defaultOptions[key].default;
        return result;
      }, {}),
      ...Object.entries(tool.options),
      ...preset.options
    };


    // this sets all options and will be batched automatically
    Object.keys(options)
      .filter((key: string) => validOptions.indexOf(key) != -1)
      .map((key: string) => {
        //@ts-ignore
        tool.setOption(key, options[key] as string, silent);
      });

    tool.chart.requestRedraw();

    updateStateId();
  };

  const hoverStart = (tool: AbstractTool, preset: Preset) => {
    set(tool, preset, true);
  }

  const save = (tool: AbstractTool) => {
    const defaultOptions = Tools[tool?.typeName ?? ""].options || {};
    const options = Object.fromEntries(tool.options);

    const state = {
      ...Object.keys(defaultOptions).reduce((result, key) => {
        //@ts-ignore
        result[key] = defaultOptions[key].default;
        return result;
      }, {}),
      ...options,
    };

    setHoverState(state);
  }

  const restore = (tool: AbstractTool) => {
    if (hoverState) {
      Object.keys(hoverState)
        .map((key: string) => {
          tool.setOption(key, hoverState[key] as string, true);
        });

      tool.chart.requestRedraw();

      updateStateId();
    }
    setHoverState({});
  }

  const hoverEnd = (tool: AbstractTool, preset: Preset) => {
  }

  return { save, restore, set, hoverStart, hoverEnd, getFilteredOptions };
};

export default useToolPreset;
