import Tooltip from "rc-tooltip";
import { selectItem, TabType } from "./redux/features/counter/menuSlice";
import { useAppSelector, useAppDispatch } from "./redux/hooks";

const MarketIndicator = () => {
  const price = useAppSelector((state) => state.chart.price);
  const dispatch = useAppDispatch();
  const activeMarket = useAppSelector((state) => state.markets.active);
  const market = useAppSelector((root) => root.markets.markets.find((m) => m.identifier == activeMarket));

  const handleClick = () => {
    dispatch(selectItem(TabType.Markets));
  }

  return (
    <Tooltip overlay={<div> Change market
      <span className="px-2 py-1 ml-1 text-xs bg-gray-800 dark:text-gray-400 rounded-md font-mono">
        Tab
      </span>
    </div>
    } placement={"left"}>
      <div className="bg-gray-50 dark:bg-gray-975 text-sm" onClick={handleClick}>
        <div className="transition-all border border-transparent dark:hover:border-trtp-primary p-3 px-2 flex justify-between dark:text-white cursor-pointer">
          <div className="flex gap-4">
            <div className="bg-gray-100 dark:bg-black px-2 py-1 rounded dark:text-gray-400 text-xxs uppercase">{market?.symbol}</div>
            <div className="my-auto">{market?.name}</div>
          </div>
          <div className="my-auto pr-3">
            ${price}
          </div>
        </div>
      </div>
    </Tooltip>
  )
}

export default MarketIndicator;
