import { AnimatePresence, motion } from "framer-motion";
import Tooltip from "rc-tooltip";
import { useState } from "react";
import useIsMobile from "src/hooks/useIsMobile";

type TitleButtonProps = {
  activated?: boolean,
  children: React.ReactElement,
  onClick: () => void,
  shortcut?: string,
  confirm?: boolean,
  toolposition?: "top" | "left" | "right",
  tooltip?: React.ReactElement | string
};

export const TitleButton = ({
  activated = false,
  tooltip = "",
  toolposition = "top",
  children,
  onClick,
  shortcut,
  confirm = false
}: TitleButtonProps) => {
  const isMobile = useIsMobile();

  const [confirming, setConfirming] = useState(false);
  const [confirmId, setConfirmId] = useState<any>(0);

  
  const handleOnClick = () => {
    if (confirm == false) {
      onClick();
    } else if (confirming == false) {
      setConfirming(true);
      setConfirmId(setTimeout(() => {
        setConfirming(false);
      }, 2000));
    } else {
      clearTimeout(confirmId)

      setConfirming(false);
      onClick();
    }
  }

  return (
    <>
      <div 
        className={`${activated ? "bg-trtp-primary text-white " : "bg-gray-200 hover:text-trtp-primary dark:bg-black"} rounded p-1 cursor-pointer`}
        onClick={handleOnClick}>
        {confirming ? (
          <div className='bg-red-500 text-white text-xxs px-[2px] py-[1px] rounded-sm'>Sure?</div>
        ) : (
          <div>
            {isMobile ? (
              <>{children}</>
            ) : (
              <Tooltip
                overlay={shortcut ? (<div>{tooltip}<span className="px-2 py-1 ml-1 text-xs bg-gray-800 dark:text-gray-400 rounded-md font-mono">{shortcut}</span></div>) : tooltip}
                placement={toolposition}
                mouseEnterDelay={0.75}>
                {children}
              </Tooltip>
            )}
          </div>
        )
        }
      </div>
    </>
  );
}

