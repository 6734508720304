import { MdOutlineReplay } from "react-icons/md";
import Mode from "./Mode";

const Replay = () => {
  return (
    <Mode
      text={"Replay"}
      mode={"replay"}
      icon={<MdOutlineReplay size={20} />}
    />
  );
};

export default Replay;
