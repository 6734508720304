import { FibinaciLevel } from "chart";
import Tooltip from "rc-tooltip";
import { useEffect, useState } from "react";
import { MdOutlineRemoveCircleOutline } from "react-icons/md";
import useStateId from "src/hooks/useStateId";
import useTool from "src/hooks/useTool";
import Row from "../Row";

const LevelsRow = () => {
  const [levels, setLevels] = useState<FibinaciLevel[]>([]);

  const tool = useTool();
  const stateId = useStateId();

  useEffect(() => {
    if (tool === null) return;

    setLevels(tool.getLevels())
  }, [tool, stateId.id]);

  // useEffect(() => {
  //   if (levels.length == 0) return;

  //   sortLevels();
  // }, [levels])

  const sortLevels = () => {
    const _levels = [...levels].sort((a, b) => a.level - b.level);

    setLevels([..._levels]);
  };

  const removeLevel = (index: number) => {
    const newLevels = [...levels];
    newLevels.splice(index, 1);
    setLevels(newLevels);

    tool?.setOption("levels", newLevels);
  }

  const addLevel = () => {
    const newLevels = [...levels, {
      visible: true,
      level: levels[levels.length-1].level + 0.1
    }];

    setLevels(newLevels);

    // @ts-ignore
    tool?.setLevels(newLevels);
  }

  const saveLevels = () => {
    // @ts-ignore
    tool?.setLevels(levels);
  }

  return (
      <Row
        title="Levels"
        component={
          <div className="flex w-full flex-col">
            <div className="flex flex-col gap-1">
              {levels.map((fib: FibinaciLevel, index: number) => (
                <div className="flex gap-1" key={index}>
                  <div className="w-5 flex">
                    <input
                      type="checkbox"
                      defaultChecked={fib.visible}
                      className="cursor-pointer mx-auto my-auto w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-trtp-primary dark:focus:ring-trtp-primary dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={(field) => {
                        const _levels = [...levels];
                        _levels[index].visible = field.target.checked;
                        setLevels(_levels);
                        saveLevels();
                      }}
                    />
                  </div>
                  <div className="w-16 my-auto">
                    <input
                      key={index}
                      type="number"
                      value={fib.level}
                      className="text-[8pt] p-1 w-full rounded bg-white text-right outline-trtp-primary border border-gray-200 dark:border-gray-800 dark:bg-black"
                      onChange={(field) => {
                        const _levels = [...levels];
                        _levels[index].level = parseFloat(field.target.value);
                        setLevels(_levels);
                        saveLevels();
                      }}
                      onBlur={sortLevels}
                    />
                  </div>
                  {/* <div className="flex"> */}
                  {/*   <div className="mx-auto"> */}
                  {/*     <ColorPicker color={fib.color ?? color} onColorPick={(color) => { */}
                  {/*       const _levels = [...levels]; */}
                  {/*       _levels[index].color = color; */}
                  {/*       setLevels(_levels); */}
                  {/*       saveLevels(); */}
                  {/*     }} /> */}
                  {/*   </div> */}
                  {/* </div> */}
                  <div className="my-auto text-gray-500 dark:text-gray-700 hover:text-trtp-primary cursor-pointer" onClick={() => {
                    removeLevel(index);
                  }}>
                    <Tooltip overlay={`Remove level ${fib.level}`} mouseEnterDelay={0.75} >
                      <MdOutlineRemoveCircleOutline size={14} />
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex-1 flex w-full justify-center">
              <div className="bg-gray-100 hover:bg-gray-200 dark:bg-black dark:hover:bg-gray-900 text-gray-600 hover:text-black dark:hover:text-white cursor-pointer text-center rounded mx-1 my-2 p-1 w-full" onClick={addLevel}>
                Add level
              </div>
            </div>
          </div>
        }
      />

  )
}

export default LevelsRow;
