
import isMobile from 'ismobilejs';

export function isMobileDevice() {
  // Detect iPad
  if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
    return true;
  }

  const is_mobile = isMobile();

  return is_mobile.phone || is_mobile.tablet;
}

const useIsMobile = () => {
  return isMobileDevice();
};

export default useIsMobile;
