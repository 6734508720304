// src/timeframes.ts
var TIMEFRAMES = [30, 120, 480, 1920, 7680, 30720, 122880];

// src/assets.ts
var ASSETS = [
  { name: "Crypto", id: "crypto" },
  { name: "Forex", id: "forex" },
  { name: "Futures", id: "future" },
  { name: "Indices", id: "index" },
  { name: "Metals", id: "metal" }
];

// src/tools.tsx
var Tool = /* @__PURE__ */ ((Tool2) => {
  Tool2["None"] = "none";
  Tool2["Move"] = "move";
  Tool2["TurningPoint"] = "turningpoint";
  Tool2["TimeProjection"] = "time-projection";
  Tool2["Line"] = "line";
  Tool2["MagBox"] = "magbox";
  Tool2["GannSquare"] = "gann-square";
  Tool2["GannGrid"] = "gann-grid";
  Tool2["VerticalRay"] = "vertical-ray";
  Tool2["HorizontalRay"] = "horizontal-ray";
  Tool2["Marker"] = "marker";
  Tool2["Protractor"] = "protractor";
  Tool2["TouchableSurface"] = "touchable-surface-tool";
  Tool2["CustomDraggable"] = "custom-draggable-tool";
  Tool2["Arrow"] = "arrow";
  Tool2["Text"] = "text";
  Tool2["NonProbabilityBox"] = "non-probability-box";
  Tool2["VerticalAlert"] = "vertical-alert";
  Tool2["VerticalOrder"] = "vertical-order";
  Tool2["FibRetracement"] = "fib-retracement";
  Tool2["AngleLine"] = "angleline";
  Tool2["GannBox"] = "gann-box";
  Tool2["Circle"] = "circle";
  Tool2["GannFan"] = "gannfan";
  Tool2["PitchFork"] = "pitchfork";
  Tool2["Spiral"] = "spiral";
  return Tool2;
})(Tool || {});
var Tools = {
  ["turningpoint" /* TurningPoint */]: {
    id: "turningpoint" /* TurningPoint */,
    name: "Turning Point",
    shortcut: {
      key: "a"
    },
    options: {
      rays: {
        default: 0,
        preset: true
      },
      fixedscale: {
        default: 0,
        preset: true
      },
      gannbox: {
        default: 0,
        preset: true
      },
      doublearcs: {
        default: 0,
        preset: true
      },
      show_fib: {
        default: "on",
        preset: true
      },
      grid: {
        default: 0,
        preset: true
      },
      non_probability: {
        default: 0,
        preset: true
      },
      extend_arcs: {
        default: "0",
        preset: true
      },
      level: {
        default: "1",
        preset: (tool) => {
          return tool.getOption("level") == 6;
        }
      },
      number: {
        default: "0",
        preset: false
      }
    }
  },
  ["magbox" /* MagBox */]: {
    id: "magbox" /* MagBox */,
    name: "Rectangle",
    shortcut: {
      key: "z"
    },
    options: {
      color: {
        default: "rgba(235, 227, 12, 0.3)",
        preset: true
      },
      borderColor: {
        default: "rgb(171,170,41)",
        preset: true
      },
      width: {
        default: "2",
        preset: true
      },
      style: {
        default: "solid",
        preset: true
      }
    }
  },
  ["spiral" /* Spiral */]: {
    id: "spiral" /* Spiral */,
    name: "Spiral",
    options: {
      show_rectangles: {
        default: false,
        preset: true
      },
      width: {
        default: "1",
        preset: true
      },
      rectangle_color: {
        default: "theme.SpiralRectangleColor",
        preset: true
      },
      color: {
        default: "color.primary",
        preset: true
      }
    }
  },
  ["marker" /* Marker */]: {
    id: "marker" /* Marker */,
    name: "Marker",
    shortcut: {
      key: "s"
    },
    options: {
      show_label: {
        default: false,
        preset: true
      }
    }
  },
  ["line" /* Line */]: {
    id: "line" /* Line */,
    name: "Line",
    shortcut: {
      key: "l"
    },
    options: {
      color: {
        default: "color.primary",
        preset: true
      },
      width: {
        default: "2",
        preset: true
      },
      style: {
        default: "solid",
        preset: true
      }
    }
  },
  ["circle" /* Circle */]: {
    id: "circle" /* Circle */,
    name: "Circle",
    shortcut: {
      key: "c"
    },
    options: {
      color: {
        default: "theme.circleLineBackgroundColor",
        preset: true
      },
      borderColor: {
        default: "theme.circleLineColor",
        preset: true
      },
      drawBackground: {
        default: false,
        preset: true
      },
      width: {
        default: 1,
        preset: true
      },
      style: {
        default: "solid",
        preset: true
      }
    }
  },
  ["angleline" /* AngleLine */]: {
    id: "angleline" /* AngleLine */,
    name: "Angle Line",
    shortcut: {
      key: "k"
    },
    options: {
      color: {
        default: "color.primary",
        preset: true
      },
      horizonColor: {
        default: "color.primary",
        preset: true
      },
      width: {
        default: "2",
        preset: true
      },
      style: {
        default: "solid",
        preset: true
      }
    }
  },
  ["vertical-ray" /* VerticalRay */]: {
    id: "vertical-ray" /* VerticalRay */,
    name: "Vertical Line",
    shortcut: {
      key: "v"
    },
    options: {
      color: {
        default: "theme.rayVerticalColor",
        preset: true
      },
      width: {
        default: "1",
        preset: true
      },
      style: {
        default: "solid",
        preset: true
      }
    }
  },
  ["horizontal-ray" /* HorizontalRay */]: {
    id: "horizontal-ray" /* HorizontalRay */,
    name: "Horizontal Line",
    shortcut: {
      key: "h"
    },
    options: {
      color: {
        default: "theme.rayVerticalColor",
        preset: true
      },
      width: {
        default: "1",
        preset: true
      },
      style: {
        default: "solid",
        preset: true
      },
      text: {
        default: "",
        preset: true
      }
    }
  },
  ["arrow" /* Arrow */]: {
    id: "arrow" /* Arrow */,
    name: "Arrow",
    shortcut: {
      key: "j"
    },
    options: {
      color: {
        default: "theme.toolArrowRedBackgroundColor",
        preset: true
      },
      borderColor: {
        default: "theme.toolArrowRedBorderColor",
        preset: true
      }
    }
  },
  ["text" /* Text */]: {
    id: "text" /* Text */,
    name: "Text",
    shortcut: {
      key: "i"
    },
    options: {
      color: {
        default: "theme.textColor",
        preset: true
      },
      fontsize: {
        default: 24,
        preset: true
      },
      text: {
        default: "Type in Text Properties",
        preset: true
      }
    }
  },
  ["protractor" /* Protractor */]: {
    id: "protractor" /* Protractor */,
    name: "Protractor",
    shortcut: {
      key: "x"
    },
    options: {
      type: {
        default: 2,
        preset: true
      },
      doublearcs: {
        default: 0,
        preset: true
      }
    }
  },
  ["gann-grid" /* GannGrid */]: {
    id: "gann-grid" /* GannGrid */,
    name: "Gann Grid",
    shortcut: {
      key: "g"
    },
    options: {
      show_grid: {
        default: true,
        preset: true
      }
    }
  },
  ["gann-square" /* GannSquare */]: {
    id: "gann-square" /* GannSquare */,
    name: "Gann Square",
    options: {
      gannbox: {
        default: 0,
        preset: true
      },
      doublearcs: {
        default: 0,
        preset: true
      }
    }
  },
  ["gann-box" /* GannBox */]: {
    id: "gann-box" /* GannBox */,
    name: "Gann Box",
    options: {
      color: {
        default: "theme.gannBoxLineColor",
        preset: true
      },
      width: {
        default: "1",
        preset: true
      },
      style: {
        default: "solid",
        preset: true
      },
      labels: {
        default: true,
        preset: true
      },
      levels_time: {
        default: '[{ "visible": true, "angle": false, "level": 0.125 }, { "visible": true, "angle": false, "level": 0.25 }, { "visible": true, "angle": false, "level": 0.382 }, { "visible": true, "angle": true, "level": 0.5 }, { "visible": true, "angle": false, "level": 0.618 }, { "visible": true, "angle": false, "level": 0.786 }, { "visible": true, "angle": false, "level": 0.886 }]',
        preset: true
      },
      levels_price: {
        default: '[{ "visible": true, "angle": false, "level": 0.125 }, { "visible": true, "angle": false, "level": 0.25 }, { "visible": true, "angle": false, "level": 0.382 }, { "visible": true, "angle": true, "level": 0.5 }, { "visible": true, "angle": false, "level": 0.618 }, { "visible": true, "angle": false, "level": 0.786 }, { "visible": true, "angle": false, "level": 0.886 }]',
        preset: true
      }
    }
  },
  ["gannfan" /* GannFan */]: {
    id: "gannfan" /* GannFan */,
    name: "Gann Fan",
    options: {
      color: {
        default: "color.primary",
        preset: true
      },
      width: {
        default: 1,
        preset: true
      },
      style: {
        default: "solid",
        preset: true
      },
      levels: {
        default: '[{"visible": true,"level":8},{"visible": true,"level":4},{"visible": true,"level":3},{"visible": true,"level":2},{"visible": true,"level":1}]',
        preset: true
      }
    }
  },
  ["fib-retracement" /* FibRetracement */]: {
    id: "fib-retracement" /* FibRetracement */,
    name: "Fibonacci Retracement",
    options: {
      extendLeft: {
        default: 0,
        preset: true
      },
      extendRight: {
        default: 0,
        preset: true
      },
      trendline: {
        default: 0,
        preset: true
      },
      levels: {
        default: '[{ "visible": true, "level": 0 }, { "visible": true, "level": 0.236 }, { "visible": true, "level": 0.382 }, { "visible": true, "level": 0.5 }, { "visible": true, "level": 0.618 }, { "visible": true, "level": 0.786 }, { "visible": true, "level": 1 }, { "visible": true, "level": 1.618 }, { "visible": true, "level": 2.618 } ]',
        preset: true
      },
      color: {
        default: "theme.FibRetracementLineColor",
        preset: true
      },
      width: {
        default: 1,
        preset: true
      },
      style: {
        default: "solid",
        preset: true
      }
    }
  },
  ["pitchfork" /* PitchFork */]: {
    id: "pitchfork" /* PitchFork */,
    name: "Pitch Fork",
    options: {
      color: {
        default: "theme.PitchForkLineColor",
        preset: true
      },
      width: {
        default: 1,
        preset: true
      },
      style: {
        default: "solid",
        preset: true
      },
      levels: {
        default: '[{"visible":false,"level":0.25},{"visible":false,"level":0.382},{"visible":true,"level":0.5},{"visible":false,"level":0.618},{"visible":false,"level":0.75},{"visible":true,"level":1},{"visible":false,"level":1.5},{"visible":false,"level":1.75},{"visible":false,"level":2}]',
        preset: true
      }
    }
  }
};
var ToolsCategories = [
  {
    name: "Basic",
    tools: {
      ["turningpoint" /* TurningPoint */]: Tools["turningpoint" /* TurningPoint */],
      ["magbox" /* MagBox */]: Tools["magbox" /* MagBox */],
      ["marker" /* Marker */]: Tools["marker" /* Marker */]
    }
  },
  {
    name: "Shapes",
    tools: {
      ["line" /* Line */]: Tools["line" /* Line */],
      ["circle" /* Circle */]: Tools["circle" /* Circle */],
      ["angleline" /* AngleLine */]: Tools["angleline" /* AngleLine */],
      ["vertical-ray" /* VerticalRay */]: Tools["vertical-ray" /* VerticalRay */],
      ["horizontal-ray" /* HorizontalRay */]: Tools["horizontal-ray" /* HorizontalRay */],
      ["arrow" /* Arrow */]: Tools["arrow" /* Arrow */],
      ["text" /* Text */]: Tools["text" /* Text */]
    }
  },
  {
    name: "Gann",
    tools: {
      ["protractor" /* Protractor */]: Tools["protractor" /* Protractor */],
      ["gann-grid" /* GannGrid */]: Tools["gann-grid" /* GannGrid */],
      ["gann-square" /* GannSquare */]: Tools["gann-square" /* GannSquare */],
      ["gann-box" /* GannBox */]: Tools["gann-box" /* GannBox */],
      ["gannfan" /* GannFan */]: Tools["gannfan" /* GannFan */]
    }
  },
  {
    name: "Fibonacci",
    tools: {
      ["fib-retracement" /* FibRetracement */]: Tools["fib-retracement" /* FibRetracement */],
      ["spiral" /* Spiral */]: Tools["spiral" /* Spiral */]
    }
  },
  {
    name: "Pitch Fork",
    tools: {
      ["pitchfork" /* PitchFork */]: Tools["pitchfork" /* PitchFork */]
    }
  }
];

// src/modes.tsx
var getModeName = (id) => {
  switch (id) {
    case "annotation":
      return "Annotation";
    case "replay":
      return "Replay";
    case "measure":
      return "Measure";
    case "placetool":
      return "Add tool";
  }
};

// src/ohlc.ts
var OHLC = class {
  constructor() {
    this.open = 0;
    this.close = 0;
    this.high = 0;
    this.low = 0;
    this.date = 0;
  }
};

// src/indicator.ts
var Indicator = /* @__PURE__ */ ((Indicator2) => {
  Indicator2["MACD"] = "macd";
  Indicator2["Sinewave"] = "sinewave";
  Indicator2["Cycles"] = "cycles";
  Indicator2["Candles"] = "candles";
  Indicator2["GrayAreas"] = "grayareas";
  return Indicator2;
})(Indicator || {});
export {
  ASSETS,
  Indicator,
  OHLC,
  TIMEFRAMES,
  Tool,
  Tools,
  ToolsCategories,
  getModeName
};
