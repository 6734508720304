import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

interface MenuState {
  selected: string;
  minimized: boolean;
}

export enum TabType {
  Properties = "properties",
  Calendar = "calendar",
  Mtops = "mtops",
  Settings = "settings",
  Markets = "markets",
  Tools = "tools"
}

const initialState: MenuState = {
  selected: TabType.Markets,
  minimized: false
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    selectItem: (state, action: PayloadAction<string>) => {
      state.selected = action.payload;
    },
    minimize: (state) => {
      state.minimized = true;
    },
    maximize: (state) => {
      state.minimized = false;
    },
    toggleMenu: (state) => {
      state.minimized = !state.minimized;
    }
  },
});

export const { selectItem, minimize, maximize, toggleMenu } = menuSlice.actions;

export const selectMenuItem = (state: RootState) => state.menu.selected;

export default menuSlice.reducer;