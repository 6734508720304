import { motion } from "framer-motion";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useConfig } from "./context/ConfigContext";
import useIsMobile from "./hooks/useIsMobile";

const FreeBanner = () => {
  const [isBlackFriday, setBlackFriday] = useState(false);
  const [timer, setTimer] = useState(0);

  const config = useConfig();

  /** @ts-ignore */
  const plan = config.session.tpro.pln;

  const isMobile = useIsMobile();

  const now = DateTime.now().toUTC();

  /** @ts-ignore */
  const exp = DateTime.fromISO(config.session.tpro.exp);

  const duration = exp.diff(now);

  const days = Math.floor(duration.as('days'));
  const hours = Math.floor(duration.as('hours') % 24);
  const minutes = Math.floor(duration.as('minutes') % 60);

  // useEffect(() => {
  //   const currentDate = DateTime.now().setZone('America/New_York');
  //   const targetDate = DateTime.fromObject({ year: 2024, month: 11, day: 29 }).setZone('America/New_York');
  //   setTimeout(() => {
  //     setBlackFriday(currentDate < targetDate);
  //   }, 500)
  // }, []);

  useEffect(() => {
    const int = setInterval(() => setTimer((t) => t + 1), 10000); // every 10 sec

    return () => {
      clearInterval(int);
    }
  }, []);

  const handleUpgrade = () => window.location.href = config.siteUrl + '/account/subscription?direct';
  const handleUpgradeDirect = () => window.location.href = config.siteUrl + '/account/subscription?direct';

  if (plan != 0) return <></>;

  return (
    <>
      {isBlackFriday && createPortal(
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.8 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
            className="absolute top-0 left-0 h-screen w-screen z-40 bg-black opacity-50" style={{ zIndex: 999 }} onClick={() => setBlackFriday(false)}></motion.div>
          <div className="absolute top-0 left-0 h-screen w-screen z-50 dark:text-white flex" style={{ zIndex: 1000 }}>
            <motion.div
              initial={{ y: -50, scale: 0.9, opacity: 0 }}
              animate={{ y: 0, scale: 1, opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.8, type: "spring", stiffness: 400 }}
              className='flex flex-col-reverse md:flex-row gap-10 max-w-4xl  mx-3 md:w-1/2 p-10 md:mx-auto bg-gray-900 rounded-lg my-auto text-white' >
              <div className='my-auto mx-auto flex flex-col gap-5 text-lg'>
                <div className='flex flex-col gap-3'>
                  <div className='text-2xl line-through'>$39,99<span className='text-base'>p/m</span> </div>
                  <div className='text-4xl text-red-500 font-semibold'>Only $19,98<span className='text-base'>p/m</span> for 6 months!</div>
                </div>
                <div>
                  Take full advantage of the Black Friday 2023 deal and upgrade to the full version!
                </div>
                <div className='pt-4'>
                  <div className='rounded-md px-4 py-2 bg-trtp-primary text-center cursor-pointer' onClick={handleUpgradeDirect}>Claim 50% discount now!</div>
                </div>
                <div className=''>
                  <div className='rounded-md px-4 py-2 bg-gray-800 text-center text-base cursor-pointer' onClick={() => setBlackFriday(false)}>No thank you</div>
                </div>
              </div>
              <div>
                <div className='text-3xl md:text-8xl md:text-right font-bold md:pr-8'>
                  <div>Black</div>
                  <div>Friday</div>
                  <div className='text-red-500'>Sale</div></div>
              </div>
            </motion.div>
          </div>
        </>, document.body)}
      {isMobile == false && (
        <div className="bg-trtp-primary text-white p-3 px-3 text-xs flex gap-1">
          <span className="font-bold w-[100px] my-auto">Free plan:</span>
          <div className="flex justify-between w-full my-auto">
            <div className="flex-1 flex gap-1">
              <div className="flex gap-1">
                {days != 0 && (<div>{days} days</div>)}
                {hours != 0 && (<div>{hours} hours</div>)}
                {minutes != 0 && (<div>{minutes} minutes</div>)}
              </div>
              <div> left <span className="hidden">{timer}</span> </div>
            </div>
          </div>
          <div className="font-semibold bg-black rounded-md px-2 py-1 cursor-pointer" onClick={handleUpgrade}> Upgrade </div>
        </div>
      )}
    </>
  )
}

export default FreeBanner;
