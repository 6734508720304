import { useEffect, useState } from "react";
import { LayoutBase } from "rc-dock";

export const LAYOUT_KEY = "trtp-layout";

export const getTimeframeFromTabId = (tabId: string) => {
  return parseFloat(tabId.substring(6))
};

const useLayout = () => {
  const [layout, setLayout] = useState<LayoutBase | undefined>();

  useEffect(() => {
    const config = localStorage.getItem(LAYOUT_KEY);
    if (config) {
      try {
        const layoutConfig = JSON.parse(config);

        setLayout(layoutConfig);
      } catch (e) {
        localStorage.removeItem(LAYOUT_KEY);
      }
    }
  }, []);

  useEffect(() => {
    if (layout === undefined) return;


    localStorage.setItem(LAYOUT_KEY, JSON.stringify(layout));
  }, [layout]);

  return [layout, setLayout];
};

export default useLayout;
