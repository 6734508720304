import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setTurningpoint } from "../redux/features/counter/chartSlice";

import { useSocket } from "../context/SocketContext";
import { useChart } from "../context/ChartContext";
import { useEffect } from "react";
import useStateId from "./useStateId";

const useSetTurningpoints = () => {
  const activeTimeframe = useAppSelector((state) => state.chart.timeframe);
  // const activeTurningPoint = useAppSelector(
  //   (state) => state.chart.turningpoint
  // );
  const dispatch = useAppDispatch();

  const chartProvider = useChart();
  const { connected } = useSocket();
  const { id: stateId } = useStateId();

  useEffect(() => {
    const chart = chartProvider.get(activeTimeframe);
    if (!chart) return;

    const tp = chart.turningpoints.forecast?.turningpoint.getNumber() || null;

    dispatch(setTurningpoint(tp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, activeTimeframe, stateId]);
};

export default useSetTurningpoints;
