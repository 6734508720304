
import { useEffect, useState } from "react";
import {CgBorderStyleDashed, CgBorderStyleDotted, CgBorderStyleSolid} from 'react-icons/cg'

import useTool from "src/hooks/useTool";
import Row from "../Row";
import Switch, { SwitchOption } from "../../component/Switch";
import useStateId from "src/hooks/useStateId";

const DEFAULT_LINE_STYLE = "solid";

const styles: SwitchOption[] = [
  {
    id: "solid",
    value: 1,
    title: <CgBorderStyleSolid size={16} />,
    tooltip: 'Solid'
  },
  {
    id: "dashed",
    value: 2,
    title: <CgBorderStyleDashed size={16} />,
    tooltip: 'Dashed'
  },
  {
    id: "dotted",
    value: 3,
    title: <CgBorderStyleDotted size={16} />,
    tooltip: 'Dotted'
  },
];

const LineStyleRow = () => {
  const [style, setStyle] = useState<string>(DEFAULT_LINE_STYLE);

  const tool = useTool();
  const stateId = useStateId();

  useEffect(() => {
    if (tool === null) return;

    setStyle(tool.getOption("style", DEFAULT_LINE_STYLE));
  }, [tool, stateId.id]);

  const handleStyleChange = (newStyle: SwitchOption) => {
    setStyle(newStyle.id);
    tool?.setOption("style", newStyle.id);
  };

  return (
      <Row
        title="Style"
        component={
          <Switch
            options={styles}
            selected={style}
            onChange={handleStyleChange}
          />
        }
      />
  );
};

export default LineStyleRow;
