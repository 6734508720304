import { MdOutlineDraw } from "react-icons/md";
import Mode from "./Mode";

const Annotate = () => {
  return (
    <Mode
      text={"Annotate"}
      mode={"annotation"}
      keybinding={"o"}
      icon={<MdOutlineDraw size={20} />}
    />
  );
};

export default Annotate;
