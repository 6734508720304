import Modal from "../../Modal";
import { useAppSelector } from "../../../redux/hooks";
import { useConfig } from "../../../context/ConfigContext";
import { useState } from "react";
import { toast } from "react-toastify";

type HistoryImportModalProperties = {
  handleClose: () => void
}

const HistoryImportModal = ({ handleClose }: HistoryImportModalProperties) => {
  const currentMarket = useAppSelector((root) => root.markets.active);
  const market = useAppSelector((root) => root.markets.markets.find((m) => m.identifier == currentMarket));

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(Math.round(new Date().getTime() / 1000));

  const { siteUrl } = useConfig();

  const onStart = async () => {
    if (!market) return;
    const response = await fetch(
      siteUrl + "/api/markets/history",
      {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          identifier: market.identifier,
          start,
          end
        })
      });

    await response.json();

    handleClose();

    toast.success(`History requested`);
  }

  const onClose = () => {
    handleClose();
  }

  if (!market) return <div></div>;

  return (<Modal handleClose={handleClose}>
    <div className="flex flex-col bg-gray-50 dark:bg-gray-950 dark:text-white text-sm rounded w-96 border border-transparent dark:border-gray-700">
      <div className="flex-1 font-medium bg-gray-100 dark:bg-black rounded-t p-4">History import for {market.name}</div>
      <div className="flex flex-col p-4 gap-5">
        <div className="grid grid-cols-2 gap-5">
          <div>Start time (left side)</div><div ><input type="number" onChange={(e) => setStart(parseInt(e.target.value))}  /></div>
          <div>End time (right side)</div><div><input type="number" defaultValue={end} onChange={(e) => setEnd(parseInt(e.target.value))} /></div>
        </div>
        <div className="text-xs">
          Times are in epoch seconds, see <a href="https://www.epochconverter.com/" target="_blank" className="underline"> EpochConverter.com</a>
        </div>
      </div>
      <div className="flex gap-3 px-2 justify-end py-2 text-xs">
        <div className="px-4 py-2 hover:text-trtp-primary cursor-pointer" onClick={onClose}>Cancel</div>
        <div className="bg-trtp-primary rounded-sm text-white px-4 py-2 cursor-pointer" onClick={onStart}>Start import</div>
      </div>
    </div>
  </Modal>
  );
}

export default HistoryImportModal;
