import useIsMobile from "src/hooks/useIsMobile";

export type OnOffSwitchOption = 'on' | 'off';

type OnOffSwitchProps = {
  selected: boolean;
  onChange: (options: OnOffSwitchOption) => void;
};

const OnOffSwitch = (props: OnOffSwitchProps) => {
  const isMobile = useIsMobile();

  const handleClick = (option: OnOffSwitchOption) => {
    props.onChange(option);
  };

  return (
    <div
      className={`flex gap-1 ${isMobile ? "w-12" : "w-9"} select-none rounded-sm transition-all cursor-pointer ${
        props.selected
          ? 'justify-end bg-trtp-primary'
          : 'justify-start bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 hover:bg-gray-300'
      }`}
      style={{ padding: '5px' }}
      onClick={() => handleClick(props.selected ? 'off' : 'on')}>
      <div className={`rounded-sm h-full ${isMobile ? "w-5 min-h-[20px]" : "w-3 min-h-[10px]"} bg-white ${props.selected ? "dark:bg-white": "dark:bg-black "}`} ></div>
    </div>
  );
};

export default OnOffSwitch;
