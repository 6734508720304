import { Tool, Tools } from "common";
import { useEffect, useRef, useState } from "react";

import ColorPicker from "src/component/ColorPicker";
import useStateId from "src/hooks/useStateId";
import useTool from "src/hooks/useTool";
import Row from "./Row";

const Text = () => {
  const [content, setContent] = useState<string>(Tools[Tool.Text].options.text.default as string);
  const [color, setColor] = useState<string>(Tools[Tool.Text].options.color.default as string);
  const [size, setSize] = useState<number>(Tools[Tool.Text].options.fontsize.default as number);

  const textAreaRef = useRef<any>();

  const tool = useTool();
  const stateId = useStateId();

  useEffect(() => {
    if (textAreaRef.current === null) return;

    textAreaRef.current.style.height = "0px";

    const scrollHeight = textAreaRef.current.scrollHeight;
    textAreaRef.current.style.height = scrollHeight + "px";
  }, [textAreaRef, content]);

  useEffect(() => {
    setTimeout(() => {
      textAreaRef.current?.focus();
      textAreaRef.current?.select();
    }, 100);
  }, [textAreaRef]);

  useEffect(() => {
    if (tool === null) return;

    setColor(tool.getOption("color"));
    setContent(tool.getOption("text"));
    setSize(tool.getOption("fontsize"));
  }, [tool, stateId.id]);

  const handleChange = (color: string) => {
    setColor(color);
    tool?.setOption("color", color);
  };

  const handleContent = (text: string) => {
    setContent(text);
    tool?.setOption("text", text);
  };

  const handleSizeChange = (newsize: number) => {
    setSize(newsize);
    tool?.setOption("fontsize", newsize);
  };

  return (
    <div className="flex flex-col gap-2">
      <div>
        <div className="bg-gray-50 dark:bg-black p-2">
          <textarea
            ref={textAreaRef}
            className="w-full bg-gray-50 dark:bg-black border-transparent focus:border-transparent focus:ring-0 !outline-none"
            value={content}
            onChange={(e) => handleContent(e.target.value)}
          ></textarea>
        </div>
      </div>
      <Row
        title="Color"
        component={
          <ColorPicker
            color={color}
            onColorPick={(color) => handleChange(color)}
          />
        }
      />
      <Row
        title="Size"
        component={
          <div className="flex gap-3">
            <input
              type="number"
              value={size}
              onChange={(e) => handleSizeChange(parseInt(e.target.value))}
              className="text-center w-8 bg-gray-100 dark:bg-black border-transparent focus:border-transparent focus:ring-0 !outline-none"
            />
            <div className="my-auto text-gray-700">px</div>
          </div>
        }
      />
    </div>
  );
};

export default Text;
