import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";
import { BiRedo, BiUndo } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
import { FaLock } from "react-icons/fa6";
import { IoLockClosedOutline, IoLockOpenOutline } from "react-icons/io5";
import { MdDragIndicator } from "react-icons/md";
import { useChart } from "src/context/ChartContext";
import { useAppSelector } from "../../redux/hooks";

const FloatingToolbar = () => {
  const toolbarRef = useRef<HTMLDivElement>(null);

  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  const chartProvider = useChart();
  const chart = chartProvider.current();

  const activeTimeframe = useAppSelector((state) => state.chart.timeframe);

  const selectedToolId = useAppSelector((state) => state.chart.toolId[activeTimeframe]);
  const selectedTool = useMemo(() => chart?.tools.getById(selectedToolId ?? ""), [chart, selectedToolId])

  useEffect(() => {
    if (!chart) return;

    const handleStateChange = () => {
      // @ts-ignore
      const status = chart.undoManager.getStatus();

      setCanUndo(status.canUndo);
      setCanRedo(status.canRedo);
    }

    chart?.event.on("undomanager:state:change", handleStateChange);

    handleStateChange();

    return () => {
      chart?.event.off("undomanager:state:change", handleStateChange);
    }
  }, [chart])

  useEffect(() => {
    if (!selectedToolId) return;
    if (!chart) return;

    setIsLocked(chart?.tools.getById(selectedToolId)?.getOption('locked', false))
  }, [selectedToolId, chart]);

  const getDefaultPosition = () => {
    const position = localStorage.getItem("tpro_mobile_toolbar_position");
    if (!position) return {
      x: 10,
      y: window.innerHeight - 120
    };

    return JSON.parse(position);
  };

  const savePosition = (_: any, data: { x: number; y: number }) => {
    localStorage.setItem(
      "tpro_mobile_toolbar_position",
      JSON.stringify({ x: data.x, y: data.y })
    );
  };

  const handleUndo = useCallback(() => {
    chart?.undoManager.undo();
  }, [chart]);

  const handleLocking = useCallback(() => {
    if (!selectedToolId) return;

    const tool = chart?.tools.getById(selectedToolId);
    if (!tool) return;

    const currentlyLocked = tool.getOption('locked', false);
    setIsLocked(!currentlyLocked);
    tool.setOption('locked', !currentlyLocked);
  }, [chart, selectedToolId]);

  const handleDelete = useCallback(() => {
    if (!chart || !selectedToolId) return;

    const tool = chart!.tools.getById(selectedToolId);
    chart.tools.delete(tool!);
  }, [chart, selectedToolId]);

  const handleRedo = useCallback(() => {
    chart?.undoManager.redo();
  }, [chart]);

  return (
    <Draggable
      bounds="body"
      handle=".drag"
      defaultPosition={getDefaultPosition()}
      onStop={savePosition}
    >
      <div
        ref={toolbarRef}
        className="flex flex-wrap justify-start text-gray-500 bg-gray-100 shadow dark:shadow-2xl dark:bg-gray-950 rounded-md dark:border border-gray-800"
        style={{
          position: "absolute",
          zIndex: 500,
        }}
      >
        <div className="drag flex px-3">
          <MdDragIndicator
            size={20}
            className={`text:text-gray-400 my-auto`}
          />
        </div>
        <div className='flex gap-4 pr-3 py-1'>
          {canUndo ? (
            <div className={`flex flex-col`} onClick={handleUndo}>
              <BiUndo size={20} />
              <div className="text-xxs">Undo</div>
            </div>
          ) : (
            <div className={`flex flex-col dark:text-gray-800 text-gray-300`} onClick={handleUndo}>
              <BiUndo size={20} />
              <div className="text-xxs">Undo</div>
            </div>
          )}
          {canRedo ? (
            <div className={`flex flex-col`} onClick={handleRedo}>
              <BiRedo size={20} />
              <div className="text-xxs">Redo</div>
            </div>
          ) : (
            <div className={`flex flex-col dark:text-gray-800 text-gray-300`} onClick={handleRedo}>
              <BiRedo size={20} />
              <div className="text-xxs">Redo</div>
            </div>
          )}
          <AnimatePresence initial={false}>
            {selectedTool && selectedTool.isStatic == false && (
              <motion.div
                className='flex gap-4'
                exit={{ width: 0, opacity: 0 }}
                animate={{ width: 'auto', opacity: 1 }}
                initial={{ width: 0, opacity: 0 }}
              >
                <div
                  className={`flex items-center flex-col ${selectedToolId ? "" : "dark:text-gray-800 text-gray-300"}`}
                  onClick={handleDelete}
                >
                  <FaTimes size={20} />
                  <div className="text-xxs">Remove</div>
                </div>
                {!isLocked ? (
                  <div
                    onClick={handleLocking}
                    className={`w-[31px] flex items-center flex-col ${selectedToolId ? "" : "dark:text-gray-800 text-gray-300"}`}
                  >
                    <IoLockClosedOutline size={20} />
                    <div className="text-xxs">Lock</div>
                  </div>
                ) : (
                  <div
                    onClick={handleLocking}
                    className={`w-[31px] flex items-center flex-col ${selectedToolId ? "" : "dark:text-gray-800 text-gray-300"}`}
                  >
                    <IoLockOpenOutline size={20} />
                    <div className="text-xxs">Unlock</div>
                  </div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </Draggable>
  );
};

export default FloatingToolbar;
