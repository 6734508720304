import { useEffect, useState } from "react";
import { useAppSelector } from "../redux/hooks";
import { useChart } from "src/context/ChartContext";

const useTool = () => {
  const activeTimeframe = useAppSelector((state) => state.chart.timeframe);
  const [tool, setTool] = useState<any>(null);
  const toolId = useAppSelector((state) => state.chart.toolId[activeTimeframe]);

  const chartProvider = useChart();

  useEffect(() => {
    if (toolId === null) {
      setTool(null);
    } else {
      const chart = chartProvider.current();

      if (!chart) return;

      setTool(chart.tools.getById(toolId));
    }
  }, [toolId, activeTimeframe]);

  return tool;
};

export default useTool;
