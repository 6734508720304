import { Tool, Tools } from "common";
import { useEffect, useState } from "react";

import ColorPicker from "src/component/ColorPicker";
import OnOffSwitch, { OnOffSwitchOption } from "src/component/OnOffSwitch";
import useStateId from "src/hooks/useStateId";
import useTool from "src/hooks/useTool";
import Row from "./Row";
import LineStyleRow from "./Row/LineStyleRows";
import LineWidthRow from "./Row/LineWidthRow";

const Spiral = () => {
  const tool = useTool();
  const stateId = useStateId();

  const [showRectangles, setRectangles] = useState<boolean>(Tools[Tool.Spiral].options.show_rectangles.default as boolean);
  const [color, setColor] = useState<string>("");
  const [rectangleColor, setRectangleColor] = useState<string>("");

  useEffect(() => {
    if (tool === null) return;

    setRectangles(tool.getOption("show_rectangles"));
    setColor(tool.getOption("color"));
    setRectangleColor(tool.getOption("rectangle_color"));
  }, [tool, stateId.id]);

  const handleColorChange = (color: string) => {
    setColor(color);
    tool?.setOption("color", color);
  };

  const handleRectangleColorChange = (color: string) => {
    setRectangleColor(color);
    tool?.setOption("rectangle_color", color);
  };

  const handleChangeRectangles = (option: OnOffSwitchOption) => {
    setRectangles(option == "on");
    tool?.setOption("show_rectangles", option == "on");
  };

  return (
    <div className="flex flex-col gap-2 w-full">
      <Row
        title="Color"
        component={
          <ColorPicker
            color={color}
            onColorPick={(color) => handleColorChange(color)}
          />
        }
      />
      <LineWidthRow />
      <LineStyleRow />
      <Row
        title="Rectangles"
        component={
          <div className="flex gap-3">
            <div className={`my-auto`}>
            <OnOffSwitch
              selected={showRectangles}
              onChange={handleChangeRectangles}
            />
            </div>
            {showRectangles && (

              <ColorPicker
                color={rectangleColor}
                onColorPick={(color) => handleRectangleColorChange(color)}
              />
            )}

          </div>
        }
      />
    </div>
  );
};

export default Spiral;
