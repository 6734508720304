import { useEffect, useState } from "react";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { useConfig } from "src/context/ConfigContext";
import { useAppSelector } from "../../redux/hooks";

const Profile = () => {
  const [expanded, setExpanded] = useState(false);

  const config = useConfig();

  useEffect(() => {
    const handleClick = (e: any) => {
      if (e.target.localName == "svg") return;

      if (e.target?.closest("[data-main-dropdown-menu]") === null) {
        setExpanded(false);
      }
    };

    if (!expanded) {
      document.removeEventListener("click", handleClick);
    } else {
      document.addEventListener("click", handleClick);
    }

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [expanded]);

  const handleSignout = () => {
    window.location.href = config.siteUrl + '/api/auth/signout';
  }

  const handleAccountSettings = () => {
    window.location.href = config.siteUrl + '/account';
  }

  /** @ts-ignore */
  const name = config.session.user.name;
  return (<div className="relative flex select-none dark:text-gray-200 " data-main-dropdown-menu onClick={() => setExpanded(!expanded)}>
    <div className={`flex gap-1 cursor-pointer my-auto text-xs bg-gray-100 dark:bg-gray-900 border ${expanded ? "border-trtp-primary bg-white" : "border-gray-200 dark:border-gray-800 hover:border-trtp-primary dark:hover:border-trtp-primary"} hover:bg-white rounded px-3 py-2 shadow`}>
      <div className="truncate">{name}</div>
      {expanded && (<MdExpandLess className="my-auto" />)}
      {!expanded && (<MdExpandMore className="my-auto" />)}
    </div>
    {expanded && (
      <div className="text-xs flex flex-col absolute top-10 -left-20 right-0 bg-white dark:bg-gray-900 shadow rounded border border-gray-200 dark:border-gray-800">
        <div className="text-xs px-4 flex flex-col divide-y divide-gray-100 dark:divide-gray-700">
          <div className="cursor-pointer py-3 hover:text-trtp-primary" onClick={handleAccountSettings}>Account Settings</div>
          <div className="cursor-pointer py-3 hover:text-trtp-primary" onClick={handleSignout}>Signout</div>
        </div>
      </div>
    )}
  </div>);
}

export default Profile;
