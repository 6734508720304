import { FaLinesLeaning } from "react-icons/fa6";
import Tool from "./Tool";

const GannFan = () => {
  return (
    <Tool
      text={"Gann Fan"}
      tool={"gannfan"}
      icon={
        <FaLinesLeaning size={18} className="transform rotate-[220deg]" />
      }
    />
  );
};

export default GannFan;
