import { DateTime } from "luxon";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { io, Socket } from "socket.io-client";
import useIsMobile from "src/hooks/useIsMobile";
import { setPrice,setServerNumber } from "src/redux/features/counter/chartSlice";
import { useAppSelector } from "src/redux/hooks";
import { useConfig } from "./ConfigContext";

type OptionalSocket = Socket | null;

type SocketContextProperties = {
  sockets?: {
    mts: OptionalSocket;
    data: OptionalSocket;
    bridge: OptionalSocket;
  };
  connected: boolean;
};

export const SocketContext = React.createContext<SocketContextProperties>({
  connected: false,
});

type Props = {
  children: React.ReactNode;
};

export const SocketProvider = ({ children }: Props) => {
  const config = useConfig();

  const [connected, setConnected] = useState(false);

  const market = useAppSelector((state) => state.markets.active);
  const mts = useRef<OptionalSocket>();
  const data = useRef<OptionalSocket>();
  // const bridge = useRef<OptionalSocket>();

  const isMobile = useIsMobile();

  const dispatch = useDispatch();

  useEffect(() => {
    if (config.token === "") return;

    setConnected(false);

    const socket_options = {
      auth: {
        //@ts-ignore
        token: config.session.access_token,
      },
      query: {
        "userid": config.token,
        "devicetype": isMobile ? "mobileweb": "desktopweb",
        "time": DateTime.now().toUTC().toUnixInteger()
      },

      withCredentials: true,
      transports: ["websocket"],
    };

    mts.current = io(config.backendUrl + "/mts", socket_options);
    data.current = io(config.backendUrl + "/data", socket_options);
    // bridge.current = io(config.backendUrl + "/bridge", socket_options);

    const onConnect = () => {
      setConnected(true);
    }

    const onDisconnect = () => {
        setConnected(false);
    }
    data.current.on("connect", onConnect);
    data.current.on("disconnect", onDisconnect);

    const onHello = (serverPort: number) => {
      dispatch(setServerNumber(serverPort));
    };

    // Handle server actions
    const onServerAction = (data: any) => {
      const { action, type, message} = data; 

      if (action == "error") {
        switch(type) {
          case "account_expired":
            window.location.href = config.siteUrl + '/account/subscription?expired';
            break;
          // Every other error, just signout.
          default:
            localStorage.removeItem('token');

            // @ts-ignore
            window.location.href = config.siteUrl + '/api/auth/signout';
            break;
        }
      }
    }
    
    mts.current.on("hellofrom", onHello);
    mts.current.on("serveraction", onServerAction);

    return () => {
      mts.current?.off("hellofrom", onHello);
      mts.current?.off("serveraction", onServerAction);
      mts.current?.off("connect", onConnect);
      mts.current?.off("disconnect", onDisconnect);

      /** @ts-ignore */
      if (mts.current) mts.current.disconnect();
      /** @ts-ignore */
      if (data.current) data.current.disconnect();
      /** @ts-ignore */
      // if (bridge.current) bridge.current.disconnect();
    };
  }, [config.token, config.backendUrl]);

  // Subscribe to market when active market changes
  useEffect(() => {
    if (!mts.current) return;
    if (!connected) return;

    const onPriceChange = (price: number) => {
      dispatch(setPrice(price));
    };

    mts.current.on("event:pricechange." + market, onPriceChange);
    mts.current.emit('marketupdate:subscribe', {market});

    return () => {

      mts.current?.emit('marketupdate:unsubscribe', {market});
      mts.current?.off("event:pricechange." + market, onPriceChange);
    }
  }, [connected, market, mts.current]);

  return (
    <SocketContext.Provider
      value={{
        connected,
        sockets: {
          mts: mts.current || null,
          data: data.current || null,
          bridge: null, //bridge.current || null,
        },
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);

