import { useEffect, useState } from "react";
import { DateTime } from "luxon";

type EventType = {
  title: string;
  country: string;
  date: string;
  impact: string;
  forecase: string;
  previous: string;
  color: string;
}

const Crypto = () => {
  const [events, setEvents] = useState<EventType[]>([]);

  useEffect(() => {
    const getData = async () => {
      /** @ts-ignore */
      const response = await fetch("https://www.tesseractpro.io/cc_calendar_thisweek.json");
      const data = await response.json();

      const currentDate = DateTime.now().toUTC();
      setEvents(data.map((data: EventType) => {
        const date = DateTime.fromISO(data.date).toUTC();

        data.date = date.toLocal().toLocaleString(DateTime.DATETIME_SHORT);

        if (date < currentDate) {
          return { ...data, color: 'text-gray-300 dark:text-[#444444] hidden' };
        } else {
          switch (data.impact) {
            case "Low":
              return { ...data, color: 'text-gray-700 dark:text-orange-100' };
            case "Medium":
              return { ...data, color: 'text-orange-400 dark:text-red-300' };
            case "High":
              return { ...data, color: 'text-red-500 dark:text-red-500' };
            default:
              return { ...data, color: 'dark:text-[#888888]' };
          }
        }
      }));
    }

    getData();
  }, []);

  return (
    <div className="flex flex-col overflow-auto">
      <div className="flex flex-row bg-gray-100 dark:bg-gray-950 dark:text-gray-300 dark:font-bold sticky top-0">
        <div className="py-2" style={{ width: '50px' }}></div>
        <div className="py-2 truncate" style={{ width: '250px' }}>Event</div>
        <div className="py-2 truncate">Date</div>
      </div>
      {events.map((event: EventType, index: number) => (
        <div className={`flex ${event.color}`} key={index}>
          <div className={`py-2 text-center`} style={{ width: '50px' }}>{event.country}</div>
          <div className="py-2 truncate" style={{ width: '250px' }}>{event.title}</div>
          <div className="py-2 truncate">{event.date}</div>
        </div>
      ))}
    </div>
  )
}
const Forex = () => {
  const [events, setEvents] = useState<EventType[]>([]);

  useEffect(() => {
    const getData = async () => {
      /** @ts-ignore */
      const response = await fetch("https://www.tesseractpro.io/ff_calendar_thisweek.json");
      const data = await response.json();

      const currentDate = DateTime.now().toUTC();
      setEvents(data.map((data: EventType) => {
        const date = DateTime.fromISO(data.date).toUTC();

        data.date = date.toLocal().toLocaleString(DateTime.DATETIME_SHORT);

        if (date < currentDate) {
          return { ...data, color: 'text-gray-300 dark:text-[#444444] hidden' };
        } else {
          switch (data.impact) {
            case "Low":
              return { ...data, color: 'text-gray-700 dark:text-orange-100' };
            case "Medium":
              return { ...data, color: 'text-orange-400 dark:text-red-300' };
            case "High":
              return { ...data, color: 'text-red-500 dark:text-red-500' };
            default:
              return { ...data, color: 'dark:text-[#888888]' };
          }
        }
      }));
    }

    getData();
  }, []);

  return (
    <div className="flex flex-col overflow-auto">
      <div className="flex flex-row bg-gray-100 dark:bg-gray-950 dark:text-gray-300 dark:font-bold sticky top-0">
        <div className="py-2" style={{ width: '50px' }}></div>
        <div className="py-2 truncate" style={{ width: '250px' }}>Event</div>
        <div className="py-2 truncate">Date</div>
      </div>
      {events.map((event: EventType, index: number) => (
        <div className={`flex ${event.color}`} key={index} style={{scrollMarginTop: '100px'}}>
          <div className={`py-2 text-center`} style={{ width: '50px' }}>{event.country}</div>
          <div className="py-2 truncate" style={{ width: '250px' }}>{event.title}</div>
          <div className="py-2 truncate">{event.date}</div>
        </div>
      ))}
    </div>
  )
}

enum Tab {
  Forex, Crypto
}

const CalendarTab = ({ name, selected, onSelect }: { name: string, selected: boolean, onSelect: () => void }) => {
  return (
    <div className={`cursor-pointer dark:font-bold flex-1 ${selected ? "border-b-2 border-trtp-primary text-trtp-primary" : "hover:text-trtp-primary"} text-center pb-2 `} onClick={onSelect}>
      {name}
    </div>
  )
}

const Calendar = () => {
  const [tab, setTab] = useState(Tab.Forex)

  return (
    <div className="flex flex-col text-xs dark:text-white flex-1 w-full">
      <div className="flex justify-between bg-gray-100 dark:bg-gray-950 dark:text-gray-300 p-2  sticky top-0">
        <div className="dark:font-bold ">
          Calendar
        </div>
        <div className="text-gray-500" style={{ fontSize: '7pt' }}>(In your local time)</div>
      </div>
      <div className="flex bg-gray-100 dark:bg-gray-950 dark:text-gray-300 p-2">
        <CalendarTab name="Forex" selected={tab === Tab.Forex} onSelect={() => setTab(Tab.Forex)} />
        <CalendarTab name="Crypto" selected={tab === Tab.Crypto} onSelect={() => setTab(Tab.Crypto)} />
      </div>
        {tab === Tab.Forex && ( <Forex />)}
        {tab === Tab.Crypto && ( <Crypto />)}
    </div>
  );
}

export default Calendar;
