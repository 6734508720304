import { useConfig } from "./context/ConfigContext";
import { ReactComponent as TrtpLogo } from './trtp-logo.svg';

const Expired = () => {

  const config = useConfig();

  const handleUpgradeDirect = () => window.location.href = config.siteUrl + '/account/subscription?direct';

  return (
    <div className="h-screen w-screen flex gap-10 flex-col dark:bg-black justify-center">
      <div className="mx-auto">
        <TrtpLogo className="dark:invert" style={{ width: '125px', height: '125px' }} />
      </div>
      <div className="flex gap-3 mx-auto dark:text-white">
        <div className="my-auto">Your free trial has expired</div>
        <div className="text-2xl">😵</div>
      </div>
      <div className="flex mx-auto font-semibold text-white">
        <div className="cursor-pointer bg-trtp-primary px-5 py-3 rounded" onClick={handleUpgradeDirect}>Upgrade now</div>
      </div>
    </div>
  );
};

export default Expired;
