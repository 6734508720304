import { useAppSelector } from "../redux/hooks";

import { useSocket } from "../context/SocketContext";
import { useChart } from "../context/ChartContext";
import { useEffect } from "react";
import useStateId from "./useStateId";

const useChartKeyboardHandler = () => {
  const activeTimeframe = useAppSelector((state) => state.chart.timeframe);

  const { id: stateId, update } = useStateId();

  const chartProvider = useChart();
  const { connected } = useSocket();

  useEffect(() => {
    const handleKeyDown = (keyboardEvent: KeyboardEvent) => {
      const chart = chartProvider.get(activeTimeframe);

      /** @ts-ignore */
      if (keyboardEvent.target?.localName === "textarea") return;
      /** @ts-ignore */
      if (keyboardEvent.target?.localName === "input") return;

      const tp = chart?.turningpoints.forecast?.turningpoint || null;
      const key = keyboardEvent.key;

      if (tp) {
        // Don't act with ctrl on
        if (keyboardEvent.ctrlKey) return;

        switch(key.toLowerCase()) {
          case "q":
            tp.setOption("level", "3");
            break;
          case "w":
            tp.setOption("level", "2");
            break;
          case "e":
            tp.setOption("level", "1");
            break;
          case "r":
            tp.setOption("level", "4");
            break;
          case "t":
            tp.setOption("level", "5");
            break;
          case "c":
            tp.setOption("non_probability", 
              tp.getOption("non_probability", 0) == 0 ? "1" : "0"
            );
            break;
          case 'f':
            tp.setOption("grid", 
              tp.getOption("grid", 0) == 0 ? "1" : "0"
            );
            tp.setOption("rays", 
              tp.getOption("rays", 0) == 0 ? "1" : "0"
            );
            break;
          case 'y':
            switch(tp.getOption("extend_arcs", "0")) {
              case "3":
                tp.setOption("extend_arcs", "0")
                break;
              case "0":
                tp.setOption("extend_arcs", "1")
                break;
              case "1":
                tp.setOption("extend_arcs", "3")
                break;
            }
          default:
            chart?.event.fire("keyboard:down", keyboardEvent.key);
        }

        update();
      } else {
          chart?.event.fire("keyboard:down", keyboardEvent.key);
      }
    };

    const handleKeyUp = (keyboardEvent: KeyboardEvent) => {
      const chart = chartProvider.get(activeTimeframe);

      /** @ts-ignore */
      if (keyboardEvent.target?.localName === "textarea") return;
      /** @ts-ignore */
      if (keyboardEvent.target?.localName === "input") return;

      chart?.event.fire("keyboard:up", keyboardEvent.key);
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [connected, activeTimeframe, chartProvider]);
};

export default useChartKeyboardHandler;
