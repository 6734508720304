
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface AppState {
  keyboardHelpShown: boolean;
  discordWelcomeShow: boolean;
  magnetActive: boolean;
}

const initialState: AppState = {
  keyboardHelpShown: false,
  discordWelcomeShow: false,
  magnetActive: false
};

export const appSlice = createSlice({
  name: "App",
  initialState,
  reducers: {
    showKeyboardHelp: (state, action: PayloadAction<boolean>) => {
      state.keyboardHelpShown = action.payload;
    },

    showDiscordWelcome: (state, action: PayloadAction<boolean>) => {
      state.discordWelcomeShow = action.payload;
    },

    setMagnet: (state, action: PayloadAction<boolean>) => {
      state.magnetActive = action.payload;
    },

    toggleMagnet: (state, _: PayloadAction<void>) => {
      state.magnetActive = !state.magnetActive;
    }
  },
});

export const { showKeyboardHelp, showDiscordWelcome, setMagnet, toggleMagnet } = appSlice.actions;

export default appSlice.reducer;
