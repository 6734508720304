import { MarketInterface } from "common";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import useColorScheme from "src/hooks/useColorscheme";
import { setAssetPrice, setMarkets } from "src/redux/features/counter/marketsSlice";
import { setPresets } from "src/redux/features/counter/presetsSlice";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";

export enum ThemeType {
  Light = "light",
  Dark = "dark",
  System = "system"
}

type ConfigContextProperties = {
  backendUrl: string;
  restBackendUrl: string;
  siteUrl: string;
  token: string;
  labs: boolean;
  state: States;
  theme: ThemeType;
  session: SessionReturn;
  isDevelopment: boolean;
  setLabs: (value: boolean) => void;
  mtsFeatures: boolean;
};

export const ConfigContext = React.createContext<ConfigContextProperties>({
  backendUrl: "",
  restBackendUrl: "",
  siteUrl: "",
  token: "",
  labs: false,
  state: "loading",
  theme: ThemeType.Light,
  session: {},
  isDevelopment: false,
  setLabs: () => { },
  mtsFeatures: false
});

type Props = {
  children: React.ReactNode;
};

type SessionReturn =
  | {}
  | {
    // access: number;
    // accountType: number;
    // expires: string;
    user: {
      name: string;
      image: string;
    };
    tpro: {
      pln: number,
      exp: string
    },
    discord: {
      is_invited: boolean;
      invite_completed: boolean;
    },
    userId: string;
    markets: MarketInterface[]
    access_token: string;
    mts_features?: boolean;
  };

export type States = "loading" | "loaded" | "error" | "ratelimit" | "expired";

export const ConfigProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const colortheme = useColorScheme();

  const [isDevelopment, setDevelopment] = useState<boolean>(false);
  const siteUrl = process.env.REACT_APP_SITEURL || "https://www.tesseractpro.io";
  const backendUrl = process.env.REACT_APP_BACKEND || "https://backend.tesseractpro.io";
  const restBackendUrl = process.env.REACT_APP_RESTBACKEND || "https://rest-backend.tesseractpro.io";

  const [token, setToken] = useState<string>("");
  const [labs, setLabs] = useState(isDevelopment);
  const [session, setSession] = useState<SessionReturn>({});
  const [state, setState] = useState<States>("loading");
  const [mtsFeatures, setMtsFeatures] = useState<boolean>(false);

  useEffect(() => {
    if (state !== "loading") return;

    const parseSession = async (session: SessionReturn) => {
        /** @ts-ignore */
      const exp = session?.tpro?.exp ? DateTime.fromISO(session.tpro.exp) : null;
      if (exp !== null && exp < DateTime.now().toUTC()) {
        setState("expired");
      } else if (Object.keys(session).length === 0) {
        setState("error");

        /** @ts-ignore */
      } else if (session.access_token == undefined) {
        setState("error");
      } else {
        /** @ts-ignore */
        setToken(session.userId);

        // @ts-ignore
        localStorage.setItem('token', session.access_token);
        localStorage.setItem('restbackend', restBackendUrl);

        setSession(session);

        /** @ts-ignore */
        if (session.userId == "6181bd6689b20d22050a5329") {
          setDevelopment(true);
        }

        /** @ts-ignore */
        if (session.mts_features) {
          /** @ts-ignore */
          setMtsFeatures(session.mts_features);
        }

        const response = await fetch(
          restBackendUrl + "/bootup",
          {
            headers: new Headers({
              // @ts-ignore
              Authorization: `Bearer ${session.access_token}`
            })
          });

        const data = await response.json();

        if (data.statusCode == 429) {
          setState("ratelimit");
          return;
        } else {
          dispatch(setPresets(data.presets));

          dispatch(setMarkets(data.markets));
          for (const market of data.markets) {
            const { identifier, price } = market;
            dispatch(setAssetPrice([identifier, parseFloat(price)]))
          }
          setState("loaded");
        }
      }
    };

    const getSession = async () => {
      await fetch(siteUrl + "/api/auth/session", {
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => parseSession(data));
    };


    getSession();
  }, [state]);

  return (
    <ConfigContext.Provider
      value={{
        theme: colortheme === "light" ? ThemeType.Light : ThemeType.Dark,
        state,
        session,
        backendUrl,
        restBackendUrl,
        siteUrl,
        token,
        labs,
        setLabs,
        isDevelopment,
        mtsFeatures
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => useContext(ConfigContext);
