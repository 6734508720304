import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { useAppSelector } from "src/redux/hooks";
import useIsMobile from "src/hooks/useIsMobile";

const NewYear = () => {
  const [el, setEl] = useState<any>();

  const [isNewYear, setNewYear] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const menu = useAppSelector((state) => state.menu);

  useEffect(() => {
    setEl(document.getElementById('header'));
    const now = DateTime.now();
    setNewYear(
      (now.month === 12 && now.day >= 29) ||
      (now.month === 1 && now.day == 2)
    );

  }, [isMobile, menu.minimized]);

  if (isMobile) {
    return (<>
      {isNewYear && (
        <>
          <div className="absolute top-[95px] right-[39px] text-xxs text-orange-600 font-bold px-2 rounded" style={{ zIndex: '20', pointerEvents: 'none' }}>Happy New Year!</div>
          <img
            className="absolute top-[68px] right-[113px] w-[50px]"
            style={{zIndex: 200}}
            src="newyear.png" />
        </>
      )}
    </>)
  } else {
    return (
      <>
        {isNewYear && (
          <>
            {el && (
              createPortal(<div className="absolute top-[40px] left-[45px] text-xxs text-orange-600 font-bold bg-white dark:bg-black px-2 rounded" style={{ zIndex: '2', pointerEvents: 'none' }}>Happy New Year!</div>, el))}

            {el && (
              createPortal(<img
                className="absolute top-[0px] left-[-5px] w-[75px]"
                src="newyear.png" />
                , el))}
          </>
        )}
      </>
    )

  }
}

export default NewYear;
