import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type BridgePosition = {
  ticket: number;
  symbol: string;
  time: number;
  type: number;
  volume: number;
  profit: number;
  swap: number;
  sl: number;
  tp: number;
  magic: number;
  identifier: number;
  reason: number;
  comment: string;
  price_open: number;
  price_current: string;
};

export type BridgeAccount = {
  [key: string]: number;
  balance: number;
  credit: number;
  profit: number;
  equity: number;
  margin: number;
  margin_free: number;
  margin_level: number;
  margin_so_call: number;
  margin_so_so: number;
};

export type BridgeHistoryPosition = {
  ticket: string;
  order: string;
  time: string;
  time_msc: string;
  type: string;
  entry: string;
  magic: string;
  position_id: string;
  reason: string;
  volume: string;
  price: string;
  commission: string;
  swap: string;
  profit: string;
  fee: string;
  symbol: string;
  comment: string;
  external_id: string;

  // ticket: string;
  // time_setup: string;
  // time_done: string;
  // type: string;
  // type_time: string;
  // type_filling: string;
  // state: string;
  // magic: string;
  // reason: string;
  // price_open: string;
  // sl: string;
  // tp: string;
  // price_current: string;
  // price_stoplimit: string;
  // symbol: string;
  // comment: string;
};

type BridgeState = {
  connected: boolean;
  account: BridgeAccount | null;
  positions: BridgePosition[];
  history: BridgeHistoryPosition[];
};

const initialState: BridgeState = {
  connected: false,
  account: null,
  positions: [],
  history: [],
};

export const bridgeSlice = createSlice({
  name: "bridge",
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<BridgeAccount>) => {
      state.account = action.payload;
    },
    setPositions: (state, action: PayloadAction<BridgePosition[]>) => {
      state.positions = action.payload;
    },

    setConnected: (state, action: PayloadAction<boolean>) => {
      state.connected = action.payload;
    },

    setHistory: (state, action: PayloadAction<BridgeHistoryPosition[]>) => {
      state.history = action.payload;
    },
  },
});

export const { setAccount, setPositions, setConnected, setHistory } =
  bridgeSlice.actions;

export default bridgeSlice.reducer;
