import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import { SocketProvider } from "./context/SocketContext";
import { ConfigProvider, useConfig } from "./context/ConfigContext";
import { ChartProvider } from "./context/ChartContext";
import Unauthenticated from "./Unauthenticated";
import AppLoading from "./AppLoading";
import useIsMobile from "./hooks/useIsMobile"
import { MobileAppControllerProvider } from "./context/MobileAppControllerContext";
import { store } from "./redux/store";

import "./index.css";

import AppDesktop from "./AppDesktop";
import AppMobile from "./AppMobile";
import { useEffect } from "react";
import Expired from "./Expired";
import Ratelimited from "./Ratelimited";
// @todo investigate code splitting
// const AppDesktop = lazy(() => import("./AppDesktop"));
// const AppMobile = lazy(() => import("./AppMobile"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


const Inner = () => {
  const config = useConfig();
  const isMobile = useIsMobile();

  useEffect(() => {

    console.log(`%c████████╗███████╗███████╗███████╗███████╗██████╗  █████╗  ██████╗████████╗    ██████╗ ██████╗  ██████╗ 
╚══██╔══╝██╔════╝██╔════╝██╔════╝██╔════╝██╔══██╗██╔══██╗██╔════╝╚══██╔══╝    ██╔══██╗██╔══██╗██╔═══██╗
   ██║   █████╗  ███████╗███████╗█████╗  ██████╔╝███████║██║        ██║       ██████╔╝██████╔╝██║   ██║
   ██║   ██╔══╝  ╚════██║╚════██║██╔══╝  ██╔══██╗██╔══██║██║        ██║       ██╔═══╝ ██╔══██╗██║   ██║
   ██║   ███████╗███████║███████║███████╗██║  ██║██║  ██║╚██████╗   ██║       ██║     ██║  ██║╚██████╔╝
   ╚═╝   ╚══════╝╚══════╝╚══════╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝   ╚═╝       ╚═╝     ╚═╝  ╚═╝ ╚═════╝ `, "font-size: 8px;line-height: 1;padding: 0; margin: 0;");

    console.log("%cWarning!", "color: red; font-size: 28px");
    console.log("%cIf someone told you to copy/paste something here, there is a high likelihood you're being scammed!", "color: red;");
    console.log("%cTesseract Pro will never ask you to copy/paste something here.", "color: blue;font-weight: bold; ");
    console.log("%cIf you're just browsing the javascript source... Hello, lets chat!", "color: green;");
    console.log("%cContact Emiel on info@tesseractpro.io", "color: green;");
  }, []);

  return (
    <>
      {config.state === "expired" && <Expired />}
      {config.state === "ratelimit" && <Ratelimited />}
      {config.state === "error" && <Unauthenticated />}
      {config.state === "loading" && <AppLoading />}
      {config.state === "loaded" && (
        <>
          {isMobile ? (
            <MobileAppControllerProvider>
              <AppMobile />
            </MobileAppControllerProvider>
          ) : (
            <AppDesktop />
          )}
        </>
      )}
    </>
  );
};

root.render(
  <Provider store={store}>
    <ConfigProvider>
      <SocketProvider>
        <ChartProvider>
          <Inner />
        </ChartProvider>
      </SocketProvider>
    </ConfigProvider>
  </Provider>
);
