import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type PageType =
  "chart" | "markets" | "settings" | "draw" | "properties" | "tools" | "more"

interface AppState {
  page: PageType;
  sheetOpen: boolean;
}


const initialState: AppState = {
  page: "chart",
  sheetOpen: false
};

export const mobileAppSlice = createSlice({
  name: "Interface",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<PageType>) => {
      state.page = action.payload;
    },

    setSheetOpen: (state, action: PayloadAction<boolean>) => {
      state.sheetOpen = action.payload;
    }
  },
});

export const { setPage, setSheetOpen } = mobileAppSlice.actions;

export default mobileAppSlice.reducer;
