import { TbLayoutSidebarRightCollapse } from "react-icons/tb";
import { minimize } from "src/redux/features/counter/menuSlice";
import { useAppDispatch } from "src/redux/hooks";
import BrandingLogo from "./BrandingLogo";
import Profile from "./Profile";

const Header = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="relative flex justify-between bg-gray-100 dark:bg-gray-950" id="header">
      <BrandingLogo />
      <div className="my-auto flex gap-2 mx-2" style={{zIndex: '2'}}>
        <Profile />
        <TbLayoutSidebarRightCollapse
          size={20}
          className="dark:text-gray-700 hover:text-trtp-primary dark:hover:text-trtp-primary cursor-pointer my-auto"
          onClick={() => {
            dispatch(minimize());
          }}
          />
      </div>
    </div>
  );
}

export default Header;
