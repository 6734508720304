import Switch, { SwitchOption } from "./Switch";

type LineWidthSwitchProps = {
  selected?: string | number | null;
  onChange: (options: SwitchOption) => void;
};

const lineWidths: SwitchOption[] = [
  {
    id: "0.5",
    title: "0.5",
    value: 0.5,
    uppercase: false,
  },
  {
    id: "1",
    title: "1",
    value: 1,
    uppercase: false,
  },
  {
    id: "2",
    title: "2",
    value: 2,
    uppercase: false,
  },
  {
    id: "4",
    title: "4",
    value: 4,
    uppercase: false,
  },
  {
    id: "8",
    title: "8",
    value: 8,
    uppercase: false,
  },
  {
    id: "10",
    title: "10",
    value: 10,
    uppercase: false,
  },
];

const LineWidthSwitch = (props: LineWidthSwitchProps) => {
  const handleClick = (option: SwitchOption) => {
    props.onChange(option);
  };

  return (
    <div className="flex gap-2 select-none">
      <Switch
        options={lineWidths}
        selected={props.selected}
        onChange={handleClick}
      />
      <div className="text-xs my-auto dark:text-gray-700">
        px
      </div>
    </div>
  );
};

export default LineWidthSwitch;
