import { Tool, Tools } from "common";
import { useEffect, useState } from "react";

import ColorPicker from "src/component/ColorPicker";
import Switch, { SwitchOption } from "src/component/Switch";
import useStateId from "src/hooks/useStateId";
import useTool from "src/hooks/useTool";
import Row from "./Row";
import LineStyleRow from "./Row/LineStyleRows";
import LineWidthRow from "./Row/LineWidthRow";

const backgroundOptions: SwitchOption[] = [
  {
    id: "off",
    title: "Off",
    value: 0,
  },
  {
    id: "on",
    title: "On",
    value: 1,
  },
];

const Circle = () => {
  const tool = useTool();
  const stateId = useStateId();

  const [color, setColor] = useState<string>("");
  const [borderColor, setBorderColor] = useState<string>("");
  const [background, setBackground] = useState<boolean>();

  useEffect(() => {
    if (tool === null) return;

    setColor(tool.getOption("color"));
    setBackground(tool.getOption("drawBackground"));
    setBorderColor(tool.getOption("borderColor"));
  }, [tool, stateId.id]);

  const handleColorChange = (color: string) => {
    setColor(color);
    tool?.setOption("color", color);
  };

  const handleBorderColorChange = (color: string) => {
    setBorderColor(color);
    tool?.setOption("borderColor", color);
  };

  const handleBackgroundChange = (option: SwitchOption) => {
    tool?.setOption("drawBackground", option.value == 1);
    setBackground(option.value === 1);
  };

  return (
    <div className="flex flex-col gap-2 w-full">
      <Row
        title="Background"
        component={
          <div className="bg-gray-100 dark:bg-black p-1">
            <Switch
              options={backgroundOptions}
              selected={background ? "on" : "off"}
              onChange={handleBackgroundChange}
            />
          </div>
        }
      />
      <Row
        title="Background"
        component={
          <ColorPicker
            color={color}
            onColorPick={(color) => handleColorChange(color)}
          />
        }
      />
      <Row
        title="Border"
        component={
          <ColorPicker
            color={borderColor}
            onColorPick={(color) => handleBorderColorChange(color)}
          />
        }
      />
      <LineWidthRow />
      <LineStyleRow />
    </div>
  );
};

export default Circle;
