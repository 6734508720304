import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type PriIndicatorPosition = "float" | "top" | "bottom";

interface ChartSettingsState {
  pri_indicator_position: PriIndicatorPosition;
  scale_locked: boolean;
}

const initialState: ChartSettingsState = {
  pri_indicator_position: "float",
  scale_locked: false
};

export const chartSettingsSlice = createSlice({
  name: "chartSettings",
  initialState,
  reducers: {
    setPriIndicatorFixed: (state, action: PayloadAction<PriIndicatorPosition>) => {
      state.pri_indicator_position = action.payload;
    },

    setLockedScaling: (state, action: PayloadAction<boolean>) => {
      state.scale_locked = action.payload;
    },
  },
});

export const {
  setPriIndicatorFixed,
  setLockedScaling
} = chartSettingsSlice.actions;

export default chartSettingsSlice.reducer;
