const AssetChip = (props : {text: string, selected: boolean, onSelect: () => void}) => {
  return (
    <div
      className={`transition-colors cursor-pointer text-xxs py-[2px] rounded-sm ${props.selected ? "bg-trtp-primary text-white px-2 mx-0" : "px-1 mx-1 hover:bg-gray-200 hover:dark:bg-gray-900"}`}
      onClick={props.onSelect}>
      {props.text} 
    </div>
  )
}

export default AssetChip;
