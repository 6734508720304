import React from 'react';

import Arrow from "./properties/Arrow";
import HorizontalRay from "./properties/HorizontalRay";
import Line from "./properties/Line";
import Magbox from "./properties/Magbox";
import TurningPoint from "./properties/TurningPoint";
import VerticalRay from "./properties/VerticalRay";
import Text from "./properties/Text";
import { useAppSelector } from "./redux/hooks";
import FibRetracement from "./properties/FibRetracement";
import Protractor from "./properties/Protractor";
import useTool from "./hooks/useTool";
import ToolTitle from "./properties/ToolTitle";
import GannGrid from "./properties/GannGrid";
import GannSquare from "./properties/GannSquare";
import GannBox from "./properties/GannBox";
import Circle from "./properties/Circle";
import GannFan from "./properties/GannFan";
import PitchFork from "./properties/PitchFork";
import Marker from "./properties/Marker";
import useIsMobile from "./hooks/useIsMobile";
import Spiral from './properties/Spiral';

const PropertiesPanel = () => {
  const isMobile = useIsMobile();

  const timeframe = useAppSelector((state) => state.chart.timeframe);
  const activeTool = useAppSelector((state) => state.chart.tool[timeframe]);
  const activeTurningPoint = useAppSelector(
    (state) => state.chart.turningpoint
  );

  const tool = useTool();

  if (((activeTool == "move" || activeTool == undefined) && activeTurningPoint == null)) {
    return (
      <div className="flex flex-col gap-2 overflow-y-auto text-xs dark:text-white flex-1">
        <div className="p-10 text-center dark:text-gray-600">No tool or turningpoint selected</div>
      </div>
    );
  }

  const hiddenTitles = [
    "move", "custom-draggable-tool"
  ];

  return (
    <React.Fragment>
      {(tool || activeTurningPoint) && (
        <div className={`${isMobile && "min-h-[400px] max-h-[400px]"}  flex flex-col gap-2 overflow-y-auto ${isMobile ? "text-lg" : "text-xs"} dark:text-white flex-1`}>
          {tool?.typeName != "turningpoint" && hiddenTitles.indexOf(activeTool || "") == -1 && (
            <ToolTitle tool={tool}>{tool?.typeDisplayName}</ToolTitle>
          )}

          {tool && (
            <React.Fragment>
              {activeTool === "arrow" && <Arrow />}
              {activeTool === "line" && <Line />}
              {activeTool === "angleline" && <Line />}
              {activeTool === "magbox" && <Magbox />}
              {activeTool === "vertical-ray" && <VerticalRay />}
              {activeTool === "horizontal-ray" && <HorizontalRay />}
              {activeTool === "text" && <Text />}
              {activeTool === "fib-retracement" && <FibRetracement />}
              {activeTool === "protractor" && <Protractor />}
              {activeTool === "gann-grid" && <GannGrid />}
              {activeTool === "gann-square" && <GannSquare />}
              {activeTool === "gann-box" && <GannBox />}
              {activeTool === "circle" && <Circle />}
              {activeTool === "gannfan" && <GannFan />}
              {activeTool === "pitchfork" && <PitchFork />}
              {activeTool === "marker" && <Marker />}
              {activeTool === "spiral" && <Spiral />}
            </React.Fragment>
          )}
          {activeTurningPoint != null && <TurningPoint />}
        </div>
      )}
    </React.Fragment>
  );
};

export default PropertiesPanel;
