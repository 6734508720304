import { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import OnOffSwitch from "./component/OnOffSwitch";
import Switch, { SwitchOption } from "./component/Switch";
import useCriterion, { CriterionScore, SCORE } from "./hooks/useCriterion";
import useStateId from "./hooks/useStateId";
import useTurningPoint from "./hooks/useTurningPoint";

export const CRITERION = [
  {
    score: SCORE.TREND_DIRECTION,
    name: "Trend Direction",
  },
  {
    score: SCORE.TURNINGPOINT_DIRECTION,
    name: "Turning Point Spacing",
  },
  {
    score: SCORE.SCALING,
    name: "Scaling",
  },
  {
    score: SCORE.KILLZONE,
    name: "Killzone",
  },
  {
    score: SCORE.TIME_PROJECTION,
    name: "Time Projection",
  },
  {
    score: SCORE.CUBE_ROOT,
    name: "Cube Root",
  },
  {
    score: SCORE.OSCILLATOR,
    name: "Oscillator",
  },
  {
    score: SCORE.PRICE_PROJECTION,
    name: "Price Projection",
  },
  {
    score: SCORE.FAN_LINE,
    name: "Support",
  },
  {
    score: SCORE.ARC_CURVE,
    name: "Resistance",
  },
];

const rewardOptions: SwitchOption[] = [
  {
    id: "1",
    value: 1,
    title: "1",
    tooltip: "Reward Level 1",
  },
  {
    id: "2",
    value: 2,
    title: "2",
    tooltip: "Reward Level 2",
  },
  {
    id: "3",
    value: 3,
    title: "3",
    tooltip: "Reward Level 3",
  },
];

const MtaPanel = () => {
  const [score, setScore] = useState(-1);
  const [checked, setChecked] = useState(-1);
  const [criterion, setCriterion] = useState<CriterionScore | null>(null);
  const [rewardLevel, setRewardLevel] = useState(-1);
  const { calculateScore, getScoreName, getScoreColor } = useCriterion();

  const turningPoint = useTurningPoint();
  const { update: updateState } = useStateId();

  useEffect(() => {
    setScore(turningPoint?.getOption("score", 0 /*2046*/) || 0);
    setChecked(turningPoint?.getOption("checked", 0) || 0);
    setRewardLevel(turningPoint?.getOption("reward_level", 1) || 1);
  }, [turningPoint]);

  useEffect(() => {
    setCriterion(calculateScore(score, rewardLevel));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [score, rewardLevel]);

  useEffect(() => {
    if (checked !== -1) turningPoint?.setOption("checked", checked);
    if (score !== -1) turningPoint?.setOption("score", score);
    if (rewardLevel !== -1)
      turningPoint?.setOption("reward_level", rewardLevel);

    updateState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [turningPoint, checked, score, rewardLevel]);

  useEffect(() => {
    const downHandler = (key: any) => {
      /** @ts-ignore */
      if (key.target?.localName === "textarea") return;
      /** @ts-ignore */
      if (key.target?.localName === "input") return;

      if ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].indexOf(parseInt(key.key)) == -1) return;

      if (key.key == 0) {
        setScore(score ^ CRITERION[CRITERION.length - 1].score)
      } else {
        setScore(score ^ CRITERION[key.key - 1].score)
      }
    }

    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [score]);

  return (
    <div className="flex flex-col gap-2 p-3 ">
      {CRITERION.map((node) => (
        <div
          key={`criterion_${node.score}`}
          className="flex text-gray-600 text-xs dark:text-gray-300"
        >
          <div
            className="flex items-center flex-1 cursor-pointer gap-2 text-selectable"
            onClick={() => setChecked((checked) => checked ^ node.score)}
          >
            {node.score & checked ? (
              <FaCheckCircle className="text-selected" />
            ) : (
              <></>
            )}
            <div className="select-none">{node.name}</div>
          </div>
          <div>
            <OnOffSwitch
              selected={(node.score & score) !== 0}
              onChange={() => setScore(score ^ node.score)}
            />
          </div>
        </div>
      ))}
      <div className="flex text-gray-600 text-xs dark:text-gray-300">
        <div className="flex items-center flex-1 gap-2">Reward Level</div>
        <div className="bg-gray-50 dark:bg-black rounded-sm">
          <Switch
            options={rewardOptions}
            selected={rewardLevel}
            onChange={(option: SwitchOption) =>
              setRewardLevel(parseInt(option.id))
            }
          />
        </div>
      </div>
      <div className="flex flex-col justify-center ">
        <div
          className={`flex-1 p-2 text-lg text-center ${getScoreColor(
            criterion?.score || 0
          )}`}
        >
          {getScoreName(criterion?.score || 0)}
        </div>
      </div>
    </div>
  );
};
export default MtaPanel;
