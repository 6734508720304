import { defaultSettingsState } from "./features/counter/settingsSlice";

const KEY = "tpro-state-1";

export function loadState() {
  try {
    const serializedState = localStorage.getItem(KEY);

    if (!serializedState) return undefined;

    let state = JSON.parse(serializedState);

    // Merge default state with the saved state
    state.settings = { ...defaultSettingsState, ...state.settings };

    return state;
  } catch (e) {

    return undefined;
  }
}

export async function saveState(state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(KEY, serializedState);
  } catch (e) {
    // Ignore
  }
}
