import { MdGridOn } from "react-icons/md";
import Tool from "./Tool";

const GannGrid = () => {
  return (
    <Tool
      text={"Gann Grid"}
      tool={"gann-grid"}
      icon={<MdGridOn size={20} className={"transform rotate-45"} />}
    />
  );
};

export default GannGrid;
