import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getTimeframeFromTabId, LAYOUT_KEY } from "src/hooks/useLayout";

interface ChartState {
  serverNumber: number;
  stateId: number;
  price: number;
  timeframe: number;
  turningpoint: number | null;

  // The selected tool
  tool: {[key: number]: string | null};
  toolId: {[key: number]: string | null};
  mode: {[key: number]: string};

  // Current tool under the cursor (eg. drawing)
  drawingTool: {[key: number]: string};
}
let defaultTimeframe = 32;

// Extract the default timeframe from the saved layout
const config = localStorage.getItem(LAYOUT_KEY);
if (config) {
  try {
    const tf = getTimeframeFromTabId(
      JSON.parse(config)?.dockbox.children[0].activeId
    );
    if (tf > 0) {
      defaultTimeframe = tf;
    }
  } catch (e) {}
}

const initialState: ChartState = {
  serverNumber: 0,
  stateId: 0,
  price: 0,
  timeframe: defaultTimeframe,
  turningpoint: null,
  tool: {},
  toolId: {},
  mode: {},
  drawingTool: {}
};

export const chartSlice = createSlice({
  name: "chart",
  initialState,
  reducers: {
    setToolId: (state, action: PayloadAction<[number, string | null]>) => {
      state.toolId[action.payload[0]] = action.payload[1];
    },

    setTool: (state, action: PayloadAction<[number, string | null]>) => {
      state.tool[action.payload[0]] = action.payload[1];
    },

    setTimeframe: (state, action: PayloadAction<number>) => {
      state.timeframe = action.payload;
    },

    setTurningpoint: (state, action: PayloadAction<number | null>) => {
      state.turningpoint = action.payload;
    },

    increaseStateId: (state) => {
      state.stateId = state.stateId + 1;
    },

    setPrice: (state, action: PayloadAction<number>) => {
      state.price = action.payload;
    },

    setMode: (state, action: PayloadAction<[number, string]>) => {
      state.mode[action.payload[0]] = action.payload[1];
    },

    setDrawingTool: (state, action: PayloadAction<[number, string]>) => {
      state.drawingTool[action.payload[0]] = action.payload[1];
    },

    setServerNumber: (state, action: PayloadAction<number>) => {
      state.serverNumber = action.payload;
    }
  },
});

export const {
  setTimeframe,
  setTurningpoint,
  setTool,
  increaseStateId,
  setToolId,
  setPrice,
  setMode,
  setDrawingTool,
  setServerNumber

} = chartSlice.actions;

export const selectTimeframe = (state: ChartState) => state.timeframe;

export default chartSlice.reducer;
