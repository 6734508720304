import { LegacyRef, useRef} from "react";
import DockLayout from "rc-dock";
import { Resizable } from 're-resizable';

import { useAppDispatch } from "./redux/hooks";
import { setTimeframe } from "./redux/features/counter/chartSlice";

import Chart from "./Chart";

import "./App.css";
import "rc-dock/dist/rc-dock.css";
import "rc-tooltip/assets/bootstrap.css";
import "./override/rc-dock-no-animation.css";
import "./override/rc-dock-center-tabs.css";
import "./override/rc-dock-text-tabs.css";
import "./override/rc-dock-custom.css";
import 'react-toastify/dist/ReactToastify.css';

import { useAppSelector } from "./redux/hooks";
import { ThemeType } from "./context/ConfigContext";
import useSetTurningpoints from "./hooks/useSetTurningpoints";
import useChartKeyboardHandler from "./hooks/useChartKeyboardHandler";
import useLayout, { getTimeframeFromTabId } from "./hooks/useLayout";
import FloatingToolbar from "./FloatingToolbar";
import TabTitle from "./TabTitle";
import { useSocket } from "./context/SocketContext";
import ChartLoading from "./ChartLoading";
import Tabs from "./component/Desktop/Menu/Tabs";
import Menu from "./component/Desktop/Menu";
import KeyboardShortcuts from "./KeyboardShortcuts";
import useDynamicTitle from "./hooks/useDynamicTitle";
import Overwatch from "./Overwatch";
import Header from "./component/Header";
import Toolbar from "./component/Toolbar";
import MarketIndicator from "./MarketIndicator";
import { maximize } from "./redux/features/counter/menuSlice";
import { TbLayoutSidebarLeftCollapse } from "react-icons/tb";
import { ToastContainer } from "react-toastify";
import useColorScheme from "./hooks/useColorscheme";
import FreeBanner from "./FreeBanner";
import FunctionButtons from "./component/Toolbar/FunctionButtons";
import { TIMEFRAMES } from "common";
import Halloween from "./FunCalendarEvents/Halloween";
import Christmas from "./FunCalendarEvents/Christmas";
import NewYear from "./FunCalendarEvents/NewYear";

function AppDesktop() {
  const dockLayoutRef: LegacyRef<DockLayout> | null = useRef(null);

  const dispatch = useAppDispatch();
  const { connected } = useSocket();
  const theme = useColorScheme();

  const showKeyboardHelp = useAppSelector((state) => state.app.keyboardHelpShown);
  const toolbarPosition = useAppSelector((state) => state.settings.toolbarPosition);
  const showMarketIndicator = useAppSelector((state) => state.settings.show_marketindicator);
  const menu = useAppSelector((state) => state.menu);

  const [layout, setLayout] = useLayout();

  useSetTurningpoints();
  useChartKeyboardHandler();

  useDynamicTitle();

  const onSelectTab = (tabId: string) => {
    if (tabId && tabId.substring(0, 5) === "chart") {
      dispatch(setTimeframe(getTimeframeFromTabId(tabId)));
    }
  };

  const defaultLayout = {
    dockbox: {
      mode: "horizontal",
      children: [
        {
          activeId: "chart_32",
          tabs: TIMEFRAMES.map((tf: number) => ({
            id: "chart_" + tf / 60,
            title: <TabTitle timeframe={tf / 60} />,
            //cached: false,
            content: (
              <Chart
                key={tf / 60}
                timeframe={tf / 60}
                onActivate={() =>
                  onSelectTab("chart_" + tf / 60)
                }
              />
            ),
          }))
        }
      ],
    },
  };

  return (
    <>
      <NewYear />
      <Halloween />
      <Christmas />
      <ToastContainer
        theme={theme == ThemeType.Light ? "light" : "dark"}
        autoClose={2000}
      />

      {!connected && (
        <>
          <div className="absolute top-0 left-0 h-screen w-screen z-40 bg-black opacity-20"></div>
          <div className="absolute top-0 left-0 h-screen w-screen z-50">
            <ChartLoading />
          </div>
        </>
      )}

      {toolbarPosition == "float" && (<FloatingToolbar />)}

      <div className="flex h-screen w-screen flex-1" style={{ position: "relative" }} id="content">
        {toolbarPosition == "left" && (
          <div className="flex flex-col gap-1">
            <div className="flex-1 step-toolbar flex flex-col gap-4 justify-start p-4 pr-2 bg-gray-100 dark:bg-gray-950 dark:text-gray-500 overflow-x-hidden overflow-y-auto  min-w-[50px]">
              <Toolbar />
            </div>
            <div className="flex flex-col gap-4 justify-start p-2 bg-gray-100 dark:bg-gray-950 dark:text-gray-500">
              <FunctionButtons />
            </div>
          </div>
        )}
        <div className="relative flex flex-row flex-1">
          <DockLayout
            ref={dockLayoutRef}
            /** @ts-ignore */
            layout={layout ?? undefined}

            onLayoutChange={(layout, tabId) => {
              if (tabId) onSelectTab(tabId);

              /** @ts-ignore */
              setLayout(layout);
            }}
            /** @ts-ignore*/
            defaultLayout={defaultLayout}
            style={{
              position: "absolute",
              left: 5,
              top: 5,
              right: 5,
              bottom: 5,
            }}
          />
        </div>

        {menu.minimized ? (
          <div className="flex">
            {toolbarPosition == "right" && (
              <div className="flex flex-col gap-1">
                <div className="flex flex-col gap-4 justify-start p-4 pr-2 bg-gray-100 dark:bg-black dark:text-gray-500 overflow-y-scroll min-w-[50px]">
                  <Toolbar />
                </div>
                <div className="flex flex-col gap-4 justify-start p-2 bg-gray-100 dark:bg-gray-950 dark:text-gray-500">
                  <FunctionButtons />
                </div>
              </div>
            )}
            <div className="flex flex-col justify-items-start bg-gray-100 dark:bg-black">
              <div className="h-10 p-3 pr-4">
                <TbLayoutSidebarLeftCollapse
                  size={20}
                  className="m-auto text-gray-500 dark:text-gray-700 hover:text-trtp-primary dark:hover:text-trtp-primary cursor-pointer"
                  onClick={() => {
                    dispatch(maximize());
                  }}
                />
              </div>
              <div className="flex flex-1">
                <Tabs />
              </div>
            </div>
          </div>
        ) : (
          <Resizable
            enable={{ left: true }}
            className={`flex min-w-[425px] divide-x-2 divide-gray-200 dark:divide-black`}
            handleComponent={{
              left: <div className="border-r-2 border-transparent hover:border-trtp-primary h-full w-1 bg-gray-100 dark:bg-black my-1"></div>
            }}
          >
            {toolbarPosition == "right" && (
              <div className="flex flex-col gap-1">
                <div className="flex-1 flex flex-col gap-4 justify-start p-4 pr-2 bg-gray-100 dark:bg-black dark:text-gray-500 overflow-y-scroll min-w-[50px]">
                  <Toolbar />
                </div>
                <div className="flex flex-col gap-4 justify-start p-2 bg-gray-100 dark:bg-gray-950 dark:text-gray-500">
                  <FunctionButtons />
                </div>
              </div>
            )}

            <div className="flex-1 flex flex-col p-0 bg-trtp-background divide-y-2 dark:divide-black">
              <Header />
              <FreeBanner />
              {showMarketIndicator && (<MarketIndicator />)}
              <div className="bg-gray-50">
                <Overwatch />
              </div>
              <div className="flex h-full w-full flex-1 overflow-hidden">
                <Menu />
                <Tabs />
              </div>
            </div>
          </Resizable>
        )}
      </div>

      {showKeyboardHelp && (<KeyboardShortcuts />)}
    </>
  );
}

export default AppDesktop;
