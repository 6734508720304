import { getModeName } from 'common';
import { useCallback, useEffect, useState } from 'react';
import { useChart } from 'src/context/ChartContext';
import { useAppSelector } from '../../../redux/hooks';

const ModeIndicator = () => {
  const timeframe = useAppSelector((state) => state.chart.timeframe);
  const mode = useAppSelector((state) => state.chart.mode[timeframe]);

  const chartProvider = useChart();

  const stateId = useAppSelector((state) => state.chart.stateId);
  const [placingToolName, setPlacingToolName] = useState("");

  const setMode = useCallback((mode: string) => {
    const chart = chartProvider.get(timeframe);
    if (!chart) return;

    // @ts-ignore
    chart.mode.switch(mode);
  }, [timeframe, chartProvider]);

  useEffect(() => {
    const chart = chartProvider.get(timeframe);
    if (!chart) return;

    setPlacingToolName(chart.tools.currentTool?.typeDisplayName || "");
  }, [stateId, chartProvider]);

  return (
    <div
      className={"flex gap-2 text-sm justify-between h-full"}
      onClick={() => setMode("graph")}
    >
      <div className=" font-semibold my-auto text-white ml-5">
        {mode == "placetool" ? (
          <>{placingToolName}</>
        ) : (
          <>{getModeName(mode)}</>
        )}
      </div>
      <div
        className="my-auto bg-white text-gray-900 font-semibold rounded-md px-3 py-1"
        onClick={() => setMode("graph")}
      >Return</div>
    </div>
  )
}

export default ModeIndicator;
