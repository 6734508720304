import { useState, useEffect } from "react";
import Switch, { SwitchOption } from "src/component/Switch";
import useStateId from "src/hooks/useStateId";
import Row from "../Row";

type GridOptionProperties = { turningPoint: any; chart: any };

const GridOption = ({ turningPoint }: GridOptionProperties) => {
  const [hidden, setHidden] = useState(true);
  const [grid, showGrid] = useState(turningPoint.getOption("grid") === 1);

  const { id: stateId, update } = useStateId();

  useEffect(() => {
    setHidden(
      turningPoint.getOption("extend_arcs") == "3"
    );

    showGrid(turningPoint.getOption("grid") === 1);
  }, [turningPoint, stateId]);

  const handleChange = (option: SwitchOption) => {
    turningPoint.setOption("grid", option.value);
    turningPoint.setOption("rays", option.value);

    showGrid(option.value === 1);

    update();
  };

  const options: SwitchOption[] = [
    {
      id: "off",
      title: "Off",
      value: 0,
    },
    {
      id: "on",
      title: "On",
      value: 1,
    },
  ];

  if (hidden) return <></>;

  return (
    <Row
      title="Grid"
      component={
        <div className="bg-gray-100 dark:bg-black p-1">
          <Switch
            options={options}
            selected={grid ? "on" : "off"}
            onChange={handleChange}
          />
        </div>
      }
    />
  );
};

export default GridOption;
