import React from "react";
import { setPrice } from "src/redux/features/counter/chartSlice";
import { selectMarket } from "../../../redux/features/counter/marketsSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import PriceValue from "../../MarketsPanel/PriceValue";
import { MarketInterface } from "common";
import { AiFillStar } from "react-icons/ai";

const MarketRow: React.FC<{ market: MarketInterface, afterSelect: () => void, }> = React.memo(({ market, afterSelect }) => {
  const dispatch = useAppDispatch();
  const currentMarket = useAppSelector((root) => root.markets.active);
  const price = useAppSelector((root) => root.markets.current_prices[market.identifier]);

  const handleSelect = (market: MarketInterface) => {
    dispatch(selectMarket(market.identifier));
    dispatch(setPrice(price));

    afterSelect();
  }

  return (
    <div
      key={market.identifier}
      className={`text-sm dark:bg-gray-975 ${market.identifier == currentMarket && ("text-trtp-primary font-bold bg-gray-50")} flex justify-between p-2 `}
      onClick={() => {
        handleSelect(market);
      }}>
      <div className="flex gap-2">
        <div className="flex px-2 py-1 bg-gray-100 dark:bg-gray-800 rounded dark:text-gray-300 uppercase w-[85px]" style={{ fontSize: '7pt' }}>
          <div className='truncate flex-1'>{market.symbol}</div>
          {market.is_favorite == true && (
            <div className={`cursor-pointer my-auto text-yellow-400 dark:hover:text-yellow-500`}>
              <AiFillStar size={14} />
            </div>
          )}
        </div>
        <div className="my-auto">{market.name}</div>
      </div>
      <div className="my-auto dark:text-gray-400 text-gray-500 font-medium bg-gray-100 dark:bg-black px-[5px] py-[3px] rounded" style={{ fontSize: "8pt" }}>
        <PriceValue price={price} market={market} />
      </div>
    </div>
  );
});

export default MarketRow;
