type ChildrenProps = {
  children: React.ReactNode | string;
  backgroundColor?: boolean;
};
const Title = ({ children, backgroundColor = true }: ChildrenProps) => {
  return (
    <div className={`${backgroundColor ? "bg-gray-100 dark:bg-gray-950" : "font-medium"} dark:text-gray-300 p-2 dark:font-bold sticky top-0`}>
      {children}
    </div>
  );
};

export default Title;
