type ChildrenProps = {
  children: React.ReactNode | string;
};
const SubTitle = ({ children}: ChildrenProps) => {
  return (
    <div className={`font-medium dark:text-gray-500 py-0 px-3 dark:font-bold sticky top-0`}>
      {children}
    </div>
  );
};

export default SubTitle;
