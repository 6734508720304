import { HiOutlineLocationMarker } from "react-icons/hi";
import Tool from "./Tool";

const Marker = () => {
  return (
    <Tool
      text={"Marker"}
      tool={"marker"}
      icon={<HiOutlineLocationMarker size={20} />}
    />
  );
};

export default Marker;
