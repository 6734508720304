import {Chart as MtopsChart} from "chart";
import React, { useContext } from "react";
import { useAppSelector } from "src/redux/hooks";

export type OptionalChart = MtopsChart | null;

type ChartContextProperties = {
  register: (timeframe: number, chart: MtopsChart) => void;
  unregister: (timeframe: number) => void;
  get: (timeframe: number) => OptionalChart;
  current: () => OptionalChart;
  all: () => MtopsChart[] | null;
  performAll: (chart: MtopsChart) => null,
};

export const ChartContext = React.createContext<ChartContextProperties>({
  register: () => {},
  unregister: () => {},
  get: () => null,
  current: () => null,
  all: () => null,
  performAll: (_: MtopsChart) => null,
});

type Props = {
  children: React.ReactNode;
};

let refStore: { [key: number]: MtopsChart } = {};

export const ChartProvider = ({ children }: Props) => {
  const activeTimeframe = useAppSelector((state) => state.chart.timeframe);

  const register = (
    timeframe: number,
    chartRef: MtopsChart
  ) => (refStore[timeframe] = chartRef);

  const unregister = (timeframe: number) => {
    delete refStore[timeframe];
  };

  const get = (timeframe: number) => refStore[timeframe] ?? null;
  const current = () => refStore[activeTimeframe] ?? null;

  const all = (): OptionalChart[] => {
    return Object.values(refStore).map(
      (a: MtopsChart) => a
    );
  };

  const performAll = (callback: (chart: MtopsChart) => {}): void => {
    Object.values(refStore).map(callback);
  };

  return (
    <ChartContext.Provider
      value={{
        register,
        unregister,
        get,
        current,
        /** @ts-ignore */
        all,
        /** @ts-ignore */
        performAll
      }}
    >
      {children}
    </ChartContext.Provider>
  );
};

export const useChart = () => useContext(ChartContext);
