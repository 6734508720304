import { ReactComponent as TrtpLogo } from './trtp-logo.svg';
import { useEffect, useState } from "react";

const ChartLoading = () => {
  const [text, setText] = useState("Connecting");
  const [show, setShow] = useState(false);

  useEffect(() => {
    const showTimer = setTimeout(() => {
      setShow(true);
    }, 250);

    const first = setTimeout(() => {
      setText("Still connecting, please wait");
    }, 5000);

    const second = setTimeout(() => {
      setText("Connecting takes too long, please check internet connection");
    }, 10000);

    return () => {
      clearTimeout(first);
      clearTimeout(second);
      clearTimeout(showTimer);
    };
  }, []);

  if (!show) return <div></div>;

  return (
    <div className="h-screen w-screen flex bg-white dark:bg-black">
      <div className="flex flex-col gap-10 max-w-sm w-96 m-auto p-10">
        <div className="mx-auto">
          <TrtpLogo className="dark:invert animate-pulse" style={{ width: '50px', height: '50px' }} />
        </div>
        <div className="text-gray-600 font-medium dark:text-white text-xs text-center">
          {text}
        </div>
      </div>
    </div>
  );
};

export default ChartLoading;
