import { ToolCategory, ToolDefinition, ToolsCategories } from "common";
import Buttons from '../Toolbar/Buttons'

type DrawPanelProperties = {
  afterSelect: () => void
}

const DrawPanel = ({ afterSelect = () => { } }: DrawPanelProperties) => {
  return <div className=" min-h-[400px] max-h-[400px] flex flex-col gap-4 overflow-y-auto px-5">
    {ToolsCategories.map((category: ToolCategory) => (
      <div key={category.name} className="flex flex-col gap-2">
        <div className="dark:text-gray-500 ml-2">{category.name}</div>
        <div className="grid grid-cols-3 gap-3 text-xs">
          {Object.values(category.tools).map((tool: ToolDefinition) => (
            <div key={tool.id} className="shadow bg-gray-100 px-2 py-4 border border-transparent dark:border-gray-900 rounded-md dark:bg-black" onClick={afterSelect}>{Buttons[tool.id]}</div>
          ))}
        </div>
      </div>
    ))}
  </div>;
}

export default DrawPanel;
