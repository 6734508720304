import { Chart as MtopsChart, PlacementModeType } from 'chart';
import { useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';
import { useChart } from './context/ChartContext';
import { useConfig } from './context/ConfigContext';
import { useSocket } from './context/SocketContext';
import { useAppSelector, useAppDispatch } from './redux/hooks';
import {
  setTurningpoint,
  setMode,
  setTool,
  setToolId,
} from './redux/features/counter/chartSlice';
import useVirtualMouse from './hooks/useVirtualMouse';

import { selectItem } from './redux/features/counter/menuSlice';
import Toolbar from './component/Chart/Toolbar';
import useIsMobile from './hooks/useIsMobile';
import useStateId from './hooks/useStateId';
import useSelectChartOnScroll from './hooks/useSelectChartOnScroll';

const DEFAULT_TOOL = "move";

const Chart = ({
  timeframe,
  onActivate = () => { },
  onToolDoubleClick = () => { }
}: {
  timeframe: number;
  onActivate?: () => void;
  onToolDoubleClick?: (objectId: string) => void;
}) => {
  const { labs, theme, session } = useConfig();
  const isMobile = useIsMobile();

  const chartContainerRef = useRef<HTMLDivElement | null>(null);

  const [instance, setInstance] = useState<MtopsChart | null>(null);

  const { update: updateStateId } = useStateId();
  const dispatch = useAppDispatch();
  const price = useAppSelector((state) => state.chart.price);
  const timezone = useAppSelector((state) => state.settings.timezone);
  const activeTimeframe = useAppSelector((state) => state.chart.timeframe);
  const debug = useAppSelector((state) => state.settings.debug);
  const market = useAppSelector((state) => state.markets.active);
  const primaryColor = useAppSelector((state) => state.settings.primaryColor);
  const indicators = useAppSelector((state) => state.settings.indicators[timeframe]);
  const magnetActive = useAppSelector((state) => state.app.magnetActive);
  const colors = useAppSelector((state) => state.settings.colors);
  const chartSettings = useAppSelector((state) => state.chartSettings);

  const { sockets, connected } = useSocket();

  const marketConfig = useAppSelector((root) => root.markets.markets.find((m) => m.identifier == market));

  const chartProvider = useChart();
  const virtualMouse = useVirtualMouse();

  const onToolsChange = (evt: any) => {
    if (evt.data == null) {
      dispatch(setTool([timeframe, DEFAULT_TOOL]));
      dispatch(setToolId([timeframe, null]));
    } else {
      dispatch(setTool([timeframe, evt.data.typeName])); // Do we need this?
      dispatch(setToolId([timeframe, evt.data.objectId]));
      dispatch(selectItem("properties"))
    }
    updateStateId();
  };

  const onModeChange = (evt: any) => {
    updateStateId();

    if (evt.data != null) {
      dispatch(setMode([timeframe, evt.data]))
    }
  };

  useEffect(() => {
    if (marketConfig === undefined) {
      return;
    }

    if (!connected) return;

    if (marketConfig?.isnew) {
      return;
    }

    let color = primaryColor;
    if (primaryColor == "var(--trtp-primary)") {
      color = getComputedStyle(document.body).getPropertyValue('--trtp-primary');
    }

    /** @ts-ignore */
    let _instance = new MtopsChart(
      /** @ts-ignore */
      chartContainerRef.current,
      /** @ts-ignore */
      sockets.mts as Socket,
      /** @ts-ignore */
      sockets.data as Socket,
      {
        /** @ts-ignore */
        username: session.username,
        theme: theme,
        primaryColor: color,
        market,
        candleMinutesWidth: timeframe,
        timeframe,
        isTouch: isMobile,
        fixedScaling: true,
        drawDebug: debug,
        timezone,
        maa: labs,
        alerting: labs,
        settings: chartSettings,
        marketConfig,
        colors, 
        active_indicators: indicators ?? [],
        scalingLock: chartSettings.scale_locked
      }
    );

    _instance.tools.placementMode.setMode(
      magnetActive ? PlacementModeType.Magnetic : PlacementModeType.Float
    );

    setInstance(_instance);

    instance?.data.updatePrice(price);

    /** @ts-ignore */
    chartProvider.register(timeframe, _instance);

    virtualMouse.init(_instance);

    const onTurningpointChange = () => {
      const tp =
        _instance.turningpoints.selected?.getNumber().toString() ||
        null;

      if (tp) {
        dispatch(setTurningpoint(parseFloat(tp)));
      }
    };

    _instance.event.on('mode:switch', onModeChange);
    _instance.event.on('tools:property_changed', onToolsChange);
    _instance.event.on('tool:created', onToolsChange);
    _instance.event.on('tool:moved', onToolsChange);
    _instance.event.on('tool:resized', onToolsChange);
    _instance.event.on('tool:deleted', onToolsChange);
    _instance.event.on('tools:selectedChange', onToolsChange);

    _instance.event.on('tools:dblclick', (event) => {
      if (onToolDoubleClick) {
        onToolDoubleClick(event.data);
      }
    });

    _instance.event.on(
      'turningpoint:selectedChange',
      onTurningpointChange
    );

    // TODO move to virtualMouse hook
    const virtualMouseEvent = (evt: any) => {
      if (!_instance?.layout.graph) return;

      _instance.layout.graph.virtualMouse.setPosition(
        evt.detail.time,
        evt.detail.price
      );

      _instance.layout.graph?.virtualMouse.redraw();
    };
    document.addEventListener('virtualmouse:move', virtualMouseEvent);

    return () => {
      // reset tool
      dispatch(setTool([timeframe, DEFAULT_TOOL]));
      dispatch(setToolId([timeframe, null]));
      dispatch(setMode([timeframe, "graph"]));

      virtualMouse.deinit(_instance);

      document.removeEventListener('virtualmouse:move', virtualMouseEvent);

      _instance?.event.offAll()

      chartProvider.unregister(timeframe);

      _instance?.destroy();
      //chartRef.current = null;

      /** @ts-ignore */
      _instance = null;

    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    //virtualMouse,
    chartContainerRef,
    //chartProvider,
    //dispatch,
    session, // TODO: Can cause rerender?
    marketConfig,
    chartSettings,
    timeframe,
    market,
    labs,
    connected,
    debug,
    theme,
    timezone,
    indicators
  ]);

  // monitor indicators
  useEffect(() => {
    if (!instance) return;

    // @ts-ignore
    instance.options.active_indicators = indicators; 

    instance.requestRedraw();
  }, [instance, indicators]);

  // monitor colors
  useEffect(() => {
    if (!instance) return;

    // @ts-ignore
    instance.options.colors = colors; 

    instance.requestRedraw();
  }, [instance, colors]);

  useEffect(() => {
    instance?.data.updatePrice(price);
  }, [instance, price]);

  // TODO make optional
  useSelectChartOnScroll(chartContainerRef, timeframe);

  if (!connected) {
    return <></>;
  }

  return (
    <div
      className={
        isMobile ?
          ("flex p-0 m-0 flex-1 bg-white dark:bg-black") :
          (`flex-1 flex-col h-full w-full flex dark:bg-black m-0 sm:m-0`)}
      onMouseDown={isMobile ? undefined : onActivate}
    >
      {isMobile == false && instance && (
        <Toolbar chart={instance} market={market} active={timeframe == activeTimeframe} timeframe={timeframe} />
      )}
      <div
        className={isMobile ? ("flex flex-1") : (
          `flex flex-1 border-x-2 border-b-2 ${timeframe === activeTimeframe
            ? 'border-trtp-primary'
            : 'border-transparent border-t-white dark:border-t-gray-975'
          }`)}
        ref={chartContainerRef}
      >
      </div>

      {marketConfig === undefined && (
        <div className="dark:text-white p-3">Unknown market</div>
      )}
    </div>
  );
};

export default Chart;
