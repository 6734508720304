import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ThemeType } from "../../../context/ConfigContext";

import { Indicator, Tool } from "common";

export type ToolbarPosition = "float" | "left" | "right";

interface SettingsState {
  toolbarPosition: ToolbarPosition
  primaryColor: string;
  theme: ThemeType;
  debug: boolean;
  show_marketindicator: boolean;
  timezone: string;
  defaultPreset: {
    [key in Tool]?: string
  },
  colors: {
    [key: string]: string
  },
  indicators: {
    [key: number]: {
      [key in Indicator]?: boolean
    }
  }
}

const defaultIndicators = {
  [Indicator.MACD]: true,
  [Indicator.Cycles]: true,
  [Indicator.Sinewave]: true,
  [Indicator.GrayAreas]: true,
  [Indicator.Candles]: true,
};

export const defaultSettingsState: SettingsState = {
  toolbarPosition: "left",
  primaryColor: "var(--trtp-primary)",
  theme: ThemeType.System,
  debug: false,
  show_marketindicator: true,
  timezone: "America/New_York",
  colors: {},
  defaultPreset: {},
  indicators: {
    0.5: defaultIndicators,
    2: defaultIndicators,
    8: defaultIndicators,
    32: defaultIndicators,
    128: defaultIndicators,
    512: defaultIndicators,
    2048: defaultIndicators,
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: defaultSettingsState,
  reducers: {
    setDefaultPreset: (state, action: PayloadAction<[Tool, string]>) => {
      const [tool, preset] = action.payload;

      // @todo settings are loaded from localStorage, it might be absent.
      // it should not be needed somehow. need to investigate
      if (!state.defaultPreset) state.defaultPreset = {};

      state.defaultPreset[tool] = preset;
    },

    removeDefaultPreset: (state, action: PayloadAction<Tool>) => {
      const tool = action.payload;

      const presets = { ...state.defaultPreset };
      delete presets[tool];

      state.defaultPreset = presets;
    },

    setPrimaryColor: (state, action: PayloadAction<string>) => {
      if (action.payload == 'var(--trtp-primary)') {
        // @ts-ignore
        if (document.documentElement.style.getProperty) {
          // @ts-ignore
          state.primaryColor = document.documentElement.style.getProperty('--trtp-primary');
        }
      } else {
        state.primaryColor = action.payload;
      }
    },
    setToolbarPosition: (state, action: PayloadAction<ToolbarPosition>) => {
      state.toolbarPosition = action.payload;
    },

    setMarketIndicator: (state, action: PayloadAction<boolean>) => {
      state.show_marketindicator = action.payload;
    },

    setTheme: (state, action: PayloadAction<ThemeType>) => {
      state.theme = action.payload;
    },

    setDebug: (state, action: PayloadAction<boolean>) => {
      state.debug = action.payload;
    },

    setColor: (state, action: PayloadAction<[string, string]>) => {
      if (!state.colors) state.colors = {};

      state.colors[action.payload[0]] = action.payload[1];
    },

    resetColor: (state) => {
      state.colors = {};
    },

    setTimezone: (state, action: PayloadAction<string>) => {
      state.timezone = action.payload;
    },

    setIndicator: (state, action: PayloadAction<[number, Indicator, boolean]>) => {
      let [timeframe, indicator, checked] = action.payload;

      state.indicators[timeframe][indicator] = checked;
    },
  },
});

export const {
  setToolbarPosition, setPrimaryColor, setTheme, setDebug, setColor, resetColor,
  setMarketIndicator, setTimezone, setDefaultPreset, removeDefaultPreset, setIndicator
} = settingsSlice.actions;

export default settingsSlice.reducer;
