import { IoMagnetOutline } from 'react-icons/io5';
import { TbLockSearch } from 'react-icons/tb';
import { toggleMagnet } from 'src/redux/features/counter/appSlice';
import { setLockedScaling } from 'src/redux/features/counter/chartSettingsSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';

const LockedScalingIndicator = () => {
  const dispatch = useAppDispatch();

  const lockedScaling = useAppSelector((state) => state.chartSettings.scale_locked);
  const magnetIsActive = useAppSelector((root) => root.app.magnetActive);

  const handleScalingLockToggle = () => {
    dispatch(setLockedScaling(false));
  }

  const handleMagnetToggle = () => {
    dispatch(toggleMagnet());
  }

  return (
    <div
      className='bg-gray-100 shadow dark:bg-gray-900 p-1 rounded absolute top-[10px] right-[10px] text-trtp-primary flex flex-col gap-4'
          style={{zIndex: 10}}
    >
      {magnetIsActive && (
        <div
          onClick={handleMagnetToggle}>
          <IoMagnetOutline size={20} />
        </div>
      )}
      {lockedScaling && (
    <div onClick={handleScalingLockToggle}>
          <TbLockSearch size={20} />
        </div>
      )}
    </div>
  );
}

export default LockedScalingIndicator;
