import { Chart } from 'chart';
import { Indicator } from 'common';

import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import OnOffSwitch from 'src/component/OnOffSwitch';
import { setIndicator } from 'src/redux/features/counter/settingsSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import ToolbarArea from "./ToolbarArea";

type OptionsDropdownProperties = {
  chart: Chart,
  timeframe: number
};

type RowProperties = {
  children?: string | JSX.Element | JSX.Element[];
  title: string;
}

const OnOffRow = ({ title, onChange, active = false }: { title: string, active?: boolean, onChange: (checked:boolean) => void }) => {
  const handleClick = () => {
    onChange(!active);
  }
  return (
    <div className='hover:text-white hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer' onClick={handleClick}>
      <Row title={title}>
        <OnOffSwitch
          selected={active}
          onChange={(opt) => onChange(opt == 'on')}
        />
      </Row>
    </div>
  );
}

const Row = ({ children, title }: RowProperties) => {
  return (
    <div className='flex justify-between pl-3 pr-2 py-1'>
      <div className='my-auto text-xxs uppercase text-gray-700 dark:text-gray-500'>{title}</div>
      <div>{children}</div>
    </div>
  );
}

const Title = ({ name }: { name: string }) => {
  return (
    <div className='bg-gray-100 font-bold dark:bg-gray-950 py-1 px-2 text-xxs uppercase text-gray-500'>{name}</div>
  )
}

const OptionsDropdown = ({ chart, timeframe }: OptionsDropdownProperties) => {
  const dispatch = useAppDispatch();

  const indicators = useAppSelector((state) => state.settings.indicators[timeframe]);

  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (!containerRef.current) return;
      if (!dropdownRef.current) return;

      if (dropdownRef.current.contains(e.target)) {
        return;
      }

      if (!containerRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    if (!open) {
      document.removeEventListener("click", handleClick);
    } else {
      document.addEventListener("click", handleClick);
    }

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [open, containerRef, dropdownRef]);

  const handleClick = () => {
    setOpen(!open);
  }

  const handleZoomReset = () => {
    chart.controls.zoomReset();
    setOpen(!open);
  }
  return (
    <>
      <ToolbarArea className='relative text-xxs uppercase flex'>
        <div
          ref={dropdownRef}
          className={`flex-1 flex gap-1 px-1 cursor-pointer hover:text-trtp-primary dark:hover:text-white ${open && ("text-trtp-primary dark:text-white")}`}
          onClick={handleClick}
        >
          <div>options</div>
          <IoMdArrowDropdown className={`my-auto`} size={14} />
        </div>
      </ToolbarArea>
      <AnimatePresence>
        {open && (
          <motion.div
            ref={containerRef}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            transition={{ duration: 0.3 }}
            exit={{ height: 0, opacity: 0 }}
            className='absolute top-[24px] left-[0px] w-52 h-52 bg-gray-50 shadow-lg rounded-b-sm dark:bg-gray-975 border border-white dark:border-black'
            style={{ zIndex: 1000 }}>

            <Title name='Indicators' />
            <OnOffRow
              title='Automatic Sinewave'
              active={indicators[Indicator.Sinewave]}
              onChange={(activated) => {
                dispatch(setIndicator([timeframe, Indicator.Sinewave, activated]))
              }}
            />
            <OnOffRow
              title='Oscillator (MACD)'
              active={indicators[Indicator.MACD]}
              onChange={(activated) => {
                dispatch(setIndicator([timeframe, Indicator.MACD, activated]))
              }}
            />
            <OnOffRow 
              title='Cycles'
              active={indicators[Indicator.Cycles]}
              onChange={(activated) => {
                dispatch(setIndicator([timeframe, Indicator.Cycles, activated]))
              }}
            />
            {/* <OnOffRow  */}
            {/*   title='Gray Areas' */}
            {/*   active={indicators[Indicator.GrayAreas]} */}
            {/*   onChange={(activated) => { */}
            {/*     dispatch(setIndicator([timeframe, Indicator.GrayAreas, activated])) */}
            {/*   }} */}
            {/* /> */}
            <Title name='Other' />
            <div className='cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-900' onClick={handleZoomReset}>
              <Row title='Zoom reset' />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default OptionsDropdown;
