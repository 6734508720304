import { useState, useEffect } from "react";
import Switch, { SwitchOption } from "src/component/Switch";
import useStateId from "src/hooks/useStateId";
import Row from "../Row";

type FixedScaleOptionProperties = { turningPoint: any; chart: any };

const FixedScaleOption = ({ turningPoint }: FixedScaleOptionProperties) => {
  const [fixedScale, setFixedScale] = useState(turningPoint.getOption("fixedscale") === 1);

  const [hidden, setHidden] = useState(true);

  const { id: stateId, update } = useStateId();

  useEffect(() => {
    setHidden(
      turningPoint.getOption("extend_arcs") == "3"
    );
    setFixedScale(turningPoint.getOption("fixedscale") === 1);
  }, [turningPoint, stateId]);

  const handleChange = (option: SwitchOption) => {
    turningPoint.setOption("fixedscale", option.value);

    setFixedScale(option.value === 1);

    update();
  };

  const options: SwitchOption[] = [
    {
      id: "off",
      title: "Off",
      value: 0,
    },
    {
      id: "on",
      title: "On",
      value: 1,
    },
  ];
   
  if (hidden) return <></>;

  return (
    <Row
      title="Fixed Scaling"
      component={
        <div className="bg-gray-100 dark:bg-black p-1">
          <Switch
            options={options}
            selected={fixedScale ? "on" : "off"}
            onChange={handleChange}
          />
        </div>
      }
    />
  );
};

export default FixedScaleOption;
