import { AnimatePresence } from "framer-motion";
import ToolTip from "rc-tooltip";
import { useCallback, useState } from "react";
import { HiOutlineRefresh } from "react-icons/hi";
import ConfirmModal from "src/component/ConfirmModal";
import { useChart } from "src/context/ChartContext";

import { useAppSelector } from "../../../redux/hooks";
import { ToolTipPosition } from "./Tool";

const ClearChart = () => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const activeTimeframe = useAppSelector((state) => state.chart.timeframe);
  const toolbarPosition = useAppSelector((state) => state.settings.toolbarPosition);

  const chartProvider = useChart();

  const clearChart = useCallback(() => {
    const chart = chartProvider.get(activeTimeframe);

    if (!chart) return;

    // @ts-ignore
    chart.clear();

    setConfirmOpen(false);

  }, [activeTimeframe, chartProvider]);

  return (
    <>
      <AnimatePresence>
        {confirmOpen && (
          <ConfirmModal
            onClose={() => setConfirmOpen(!confirmOpen)}
            onSelect={clearChart}
            title={"Clear your chart?"}
            message={`Are you sure you want to clear your ${activeTimeframe}m chart?`}
          />
        )}
      </AnimatePresence>
      <ToolTip
        overlay={<div>Clear chart</div>}
        placement={ToolTipPosition[toolbarPosition]}
        mouseEnterDelay={0.5}
      >
        <div
          className={`cursor-pointer hover:text-trtp-primary mx-auto`}
          onClick={() => setConfirmOpen(!confirmOpen)}
        >
          <HiOutlineRefresh size={20} className={"transform-gpu -rotate-90"} />
        </div>
      </ToolTip>
    </>
  );
};

export default ClearChart;
