import { PiLineSegmentDuotone } from "react-icons/pi";
import Tool from "./Tool";

const Line = () => {
  return (
    <Tool
      text={"Line"}
      tool={"line"}
      icon={<PiLineSegmentDuotone size={20} />}
    />
  );
};

export default Line;
