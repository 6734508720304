import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { useAppSelector } from "src/redux/hooks";
import useIsMobile from "src/hooks/useIsMobile";

const Halloween = () => {
  const [el, setEl] = useState<any>();
  const [isHalloween, setHalloween] = useState<boolean>(false);

  const isMobile = useIsMobile();

  const menu = useAppSelector((state) => state.menu);

  useEffect(() => {
    setEl(document.getElementById('header'));

    const currentDate = DateTime.now();
    const halloweenDate = DateTime.fromObject({ month: 10, day: 31 });

    setHalloween(currentDate.month === halloweenDate.month && currentDate.day === halloweenDate.day);
  }, [menu.minimized]);

  if (isMobile) {
    return (<>
        {isHalloween && (
            <img
              className="absolute w-2/3 dark:invert opacity-30 dark:opacity-20"
              style={{ zIndex: 2000, top: '67px', right: '-5px', pointerEvents: 'none' }}
              src="halloween-tr-sm.png" />
              )}
    </>);
  } else {
    return (
      <>
        {isHalloween && (
          <>
            {el && (
              createPortal(<div className="absolute top-[40px] left-[45px] text-xxs text-orange-600" style={{pointerEvents: 'none'}}>Happy Halloween!</div>, el))}

            {el && (
              createPortal(<img
                className="absolute dark:invert top-[15px] left-[6px] w-[35px]"
                style={{ zIndex: 100}}
                src="ghost.png" />
                , el))}
            <img
              className={"absolute w-1/4 dark:invert opacity-20 dark:opacity-20"}
              style={{ zIndex: 100, top: '-5px', right: '-5px', pointerEvents: 'none' }}
              src="halloween-tr-sm.png" />
          </>
        )}
      </>
    )

  }
}

export default Halloween;
