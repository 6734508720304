import { IoMdArrowDropdown } from "react-icons/io";

import { AbstractTool } from "chart";
import useIsMobile from "src/hooks/useIsMobile";

type PresetDropDownProperties = {
  showPresets: boolean;
  tool: AbstractTool;
  onClick: () => void;
  children: React.ReactNode | string;
}

const PresetDropDown = ({ showPresets, tool, onClick, children }: PresetDropDownProperties) => {
  const isMobile = useIsMobile();

  const onPresetHover = () => {
    if (!tool.objectId) return;
    if (isMobile) return;

    //@ts-ignore
    tool.chart.toolHighlight.clear(); // make sure there is only one

    //@ts-ignore
    tool.chart.toolHighlight.add(tool.objectId);
  }

  const onPresetHoverEnd = () => {
    if (isMobile) return;

    //@ts-ignore
    tool.chart?.toolHighlight.clear(); // make sure there is only one
  }
  return (
    <div
      data-presets-dropdown
      className={`transition-all group/preset my-auto rounded-sm h-full text-xs flex p-0 flex-1 border ${showPresets ? "border-trtp-primary" : "border-gray-200 dark:border-gray-900 hover:border-trtp-primary dark:hover:border-trtp-primary"} cursor-pointer divide-x divide-gray-200 dark:divide-gray-800`}
      onMouseOver={onPresetHover}
      onMouseOut={onPresetHoverEnd}
      onClick={onClick}
    >
      <div className="px-2 text-gray-700 dark:text-gray-400 my-auto group-hover/preset:text-black dark:group-hover/preset:text-white font-semibold bg-gray-50 dark:bg-gray-950">{children}</div>
      <div className={`bg-white ${isMobile ? "h-6" : "h-4"} flex justify-between dark:bg-gray-900 flex-1 rounded-r-sm my-auto px-1 text-gray-500 dark:text-gray-600`}>
        {!showPresets ? (
          <div className="group-hover/preset:text-black my-auto dark:group-hover/preset:text-white">Presets</div>
        ) : (<div></div>)}
        {showPresets ? (
          <IoMdArrowDropdown
            className={`transform rotate-180 my-auto group-hover/preset:text-trtp-primary text-trtp-primary`}
            size={14}
          />
        ) : (
          <IoMdArrowDropdown
            className={`my-auto group-hover/preset:text-trtp-primary text-gray-300 dark:text-gray-700`}
            size={14}
          />
        )}
      </div>
    </div>
  )
}

export default PresetDropDown;
