import { Tool, Tools } from "common";
import { useEffect, useState } from "react";

import OnOffSwitch, { OnOffSwitchOption } from "src/component/OnOffSwitch";
import useStateId from "src/hooks/useStateId";
import useTool from "src/hooks/useTool";
import Row from "./Row";


const GannGrid = () => {
  const [grid, showGrid] = useState<boolean>();

  const tool = useTool();
  const stateId = useStateId();

  useEffect(() => {
    if (tool === null) return;

    showGrid(tool.getOption("show_grid"));
  }, [tool, stateId.id]);

  const handleChange = (option: OnOffSwitchOption) => {
    tool.setOption("show_grid", option == "on");
    showGrid(option == "on");
  };


  return (
    <div className="flex flex-col gap-2">
    <Row
      title="Grid"
      component={
        <div className="bg-gray-100 dark:bg-black p-1">
          <OnOffSwitch
            selected={grid ?? false}
            onChange={handleChange}
          />
        </div>
      }
    />
    </div>
  );
};

export default GannGrid;
