export enum TIMEFRAME_NAME {
  None, Short, Medium, Full
}

const timeframeTypes: {[key in TIMEFRAME_NAME]: {second: string, minute: string}} = {
  [TIMEFRAME_NAME.None]: {second: '', minute: ''},
  [TIMEFRAME_NAME.Short]: {second: 's', minute: 'm'},
  [TIMEFRAME_NAME.Medium]: {second: ' sec', minute: ' min'},
  [TIMEFRAME_NAME.Full]: {second: ' seconds', minute: ' minutes'},
}

export function timeframeName(input: number, type: TIMEFRAME_NAME = TIMEFRAME_NAME.Medium) {
  if (input <= 60) {
    return `${input}${timeframeTypes[type].second}`;
  } else {
    return `${input/60}${timeframeTypes[type].minute}`;
  }
}
