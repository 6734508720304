import { HiWifi } from "react-icons/hi";
import Tool from "./Tool";

const ReverseGann = () => {
  return (
    <Tool
      text={"Protractor"}
      tool={"protractor"}
      icon={<HiWifi size={20} className={`transform -rotate-90`} />}
    />
  );
};

export default ReverseGann;
