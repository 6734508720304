import { Tool, Tools } from "common";
import { useState, useEffect } from "react";
import Switch, { SwitchOption } from "src/component/Switch";
import useStateId from "src/hooks/useStateId";
import Row from "../Row";

type SupportResistanceOptionProperties = { turningPoint: any; chart: any };


const options: SwitchOption[] = [
  {
    id: "none",
    title: "None",
    value: "3"
  },
  {
    id: "forecast",
    title: "Square",
    value: "0",
  },
  {
    id: "ring",
    title: "Circle",
    value: "1",
  }
];

const SupportResistanceOption = ({
  turningPoint,
}: SupportResistanceOptionProperties) => {
  const defaultOption = turningPoint.getOption("extend_arcs");

  const [sr, setSupportResistance] = useState(defaultOption);

  const { id: stateId, update } = useStateId();

  useEffect(() => {
    const option = options.find((opt) => opt.value == defaultOption)

    if(!option) {
      return;
    }

    setSupportResistance(option.id);
  }, [turningPoint, stateId, defaultOption]);

  const handleChange = (option: SwitchOption) => {
    turningPoint.setOption("extend_arcs", option.value);

    setSupportResistance(option.id);

    update();
  };

  return (
    <Row
      title="Gann Tool"
      component={
        <div className="bg-gray-100 dark:bg-black p-1">
          <Switch options={options} selected={sr} onChange={handleChange} />
        </div>
      }
    />
  );
};

export default SupportResistanceOption;
