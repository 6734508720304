import { useState, useEffect } from "react";
import Switch, { SwitchOption } from "src/component/Switch";
import useStateId from "src/hooks/useStateId";
import Row from "../Row";

type FocusOptionProperties = {
  turningPoint: any;
  chart: any;
};

const FocusOption = ({ turningPoint }: FocusOptionProperties) => {
  const [hidden, setHidden] = useState(true);
  const [focus, setFocus] = useState(
    turningPoint.getOption("non_probability") === 1
  );

  const { id: stateId, update } = useStateId();

  useEffect(() => {
    setHidden(
      turningPoint.getOption("extend_arcs") !== "0"
    );
    setFocus(turningPoint.getOption("non_probability") === 1);
  }, [turningPoint, stateId]);

  const handleChange = (option: SwitchOption) => {
    turningPoint.setOption("non_probability", option.value);

    setFocus(option.value === 1);

    update();
  };

  const options: SwitchOption[] = [
    {
      id: "off",
      title: "Off",
      value: 0,
    },
    {
      id: "on",
      title: "On",
      value: 1,
    },
  ];

  if (hidden) return <></>;
  return (
    <Row
      title="Focus"
      component={
        <div className="dark:bg-black p-1">
          <Switch
            options={options}
            selected={focus ? "on" : "off"}
            onChange={handleChange}
          />
        </div>
      }
    />
  );
};

export default FocusOption;
