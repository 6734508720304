const useVirtualMouse = () => {
  const onVirtualMouseMove = (evt: any) => {
    const position = evt.data;

    const event = new CustomEvent("virtualmouse:move", { detail: position });
    document.dispatchEvent(event);
  };

  const init = (chart: any) => {
    chart.event.on("virtualmouse:mouse_move", onVirtualMouseMove);
  };

  const deinit = (chart: any) => {
    chart.event.off("virtualmouse:mouse_move", onVirtualMouseMove);
  };

  return { init, deinit };
};

export default useVirtualMouse;
