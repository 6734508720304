import { FibinaciLevel } from 'chart';

import { useEffect, useState } from "react";

import useTool from "src/hooks/useTool";
import Row from "./Row";
import OnOffSwitch from "src/component/OnOffSwitch";
import ColorPicker from 'src/component/ColorPicker';
import { MdOutlineRemoveCircleOutline } from 'react-icons/md';
import Tooltip from 'rc-tooltip';
import Switch, { SwitchOption } from 'src/component/Switch';
import { CgBorderStyleDashed, CgBorderStyleDotted, CgBorderStyleSolid } from 'react-icons/cg';
import LineWidthSwitch from 'src/component/LineWidthSwitch';
import { Tool, Tools } from 'common';
import useStateId from 'src/hooks/useStateId';

const styles: SwitchOption[] = [
  {
    id: "solid",
    value: 1,
    title: <CgBorderStyleSolid size={16} />,
    tooltip: 'Solid'
  },
  {
    id: "dashed",
    value: 2,
    title: <CgBorderStyleDashed size={16} />,
    tooltip: 'Dashed'
  },
  {
    id: "dotted",
    value: 3,
    title: <CgBorderStyleDotted size={16} />,
    tooltip: 'Dotted'
  },
];

const FibRetracement = () => {
  const [extendLeft, setExtendLeft] = useState<number>(0);
  const [extendRight, setExtendRight] = useState<number>(0);
  const [trendLine, setTrendLine] = useState<number>(0);
  const [levels, setLevels] = useState<FibinaciLevel[]>([]);
  const [color, setColor] = useState<string>("");
  const [style, setStyle] = useState<string>("");
  const [lineWidth, setWidth] = useState<string>("");

  const tool = useTool();
  const state = useStateId();

  useEffect(() => {
    if (tool === null) return;

    setExtendLeft(tool.getOption("extendLeft"));
    setExtendRight(tool.getOption("extendRight"));
    setTrendLine(tool.getOption("trendline"));
    setLevels(tool.getLevels());
    setColor(tool.getOption("color"));
    setStyle(tool.getOption("style"));
    setWidth(tool.getOption("width"));
  }, [tool, state.id]);

  useEffect(() => {
    if (color == "") return;

    tool?.setOption("color", color);
  }, [color])

  const handleStyleChange = (newStyle: SwitchOption) => {
    setStyle(newStyle.id);
    tool?.setOption("style", newStyle.id);
  };

  const handleWidthChange = (width: SwitchOption) => {
    setWidth(width.id);
    tool?.setOption("width", width.value);
  };

  const saveLevels = () => {
    tool?.setLevels(levels);
  }

  const onTrendLine = (value: number) => {
    setTrendLine(value);
    tool?.setOption("trendline", value);
  }

  const onExtendLeft = (value: number) => {
    setExtendLeft(value);
    tool?.setOption("extendLeft", value);
  }

  const onExtendRight = (value: number) => {
    setExtendRight(value);
    tool?.setOption("extendRight", value);
  }

  const sortLevels = () => {
    const _levels = [...levels].sort((a, b) => a.level - b.level);

    setLevels([..._levels]);
  };

  const removeLevel = (index: number) => {
    const newLevels = [...levels];
    newLevels.splice(index, 1);
    setLevels(newLevels);

    //.tool?.setLevels(newLevels);
    tool?.setOption("levels", newLevels);
  }

  const addLevel = () => {
    const newLevels = [...levels, {
      visible: true,
      level: levels[levels.length - 1].level + 0.1
    }];

    setLevels(newLevels);
    tool?.setLevels(newLevels);
  }

  return (
    <div className="flex flex-col gap-2">
      <Row
        title="Extend lines"
        component={
          <div className="flex gap-3 px-2 w-full justify-center">
            <div className="text-[7pt] my-auto dark:text-gray-400 dark:hover:text-trtp-primary cursor-pointer" onClick={() => onExtendLeft(extendLeft == 1 ? 0 : 1)}>Left</div>
            <OnOffSwitch
              selected={extendLeft != 0}
              onChange={(option) => {
                const value = option == "on" ? 1 : 0;
                onExtendLeft(value);
              }}
            />
            <div className="w-[2px] border border-l border-gray-200 dark:border-gray-900"></div>
            <OnOffSwitch
              selected={extendRight != 0}
              onChange={(option) => {
                const value = option == "on" ? 1 : 0;
                onExtendRight(value);
              }}
            />
            <div className="text-[7pt] my-auto dark:text-gray-400 dark:hover:text-trtp-primary cursor-pointer" onClick={() => onExtendRight(extendRight == 1 ? 0 : 1)}>Right</div>
          </div>
        }
      />
      <Row
        title="Trend line"
        component={
          <OnOffSwitch
            selected={trendLine != 0}
            onChange={(option) => {
              const value = option == "on" ? 1 : 0;
              onTrendLine(value);
            }}
          />
        }
      />
      <Row
        title="Color"
        component={
          <ColorPicker color={color} onColorPick={(color) => {
            setColor(color);
          }} />
        }
      />
      <Row
        title="Style"
        component={
          <Switch
            options={styles}
            selected={style}
            onChange={handleStyleChange}
          />
        }
      />
      <Row
        title="Line width"
        component={
          <LineWidthSwitch selected={lineWidth} onChange={handleWidthChange} />
        }
      />
      <Row
        title="Levels"
        component={
          <div className="flex w-full flex-col">
            <div className="flex flex-col gap-1">
              {levels.map((fib: FibinaciLevel, index: number) => (
                <div className="flex gap-1" key={index}>
                  <div className="w-5 flex">
                    <input
                      type="checkbox"
                      defaultChecked={fib.visible}
                      className="cursor-pointer mx-auto my-auto w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-trtp-primary dark:focus:ring-trtp-primary dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={(field) => {
                        const _levels = [...levels];
                        _levels[index].visible = field.target.checked;
                        setLevels(_levels);
                        saveLevels();
                      }}
                    />
                  </div>
                  <div className="w-16 my-auto">
                    <input
                      key={index}
                      type="number"
                      value={fib.level}
                      className="text-[8pt] p-1 w-full rounded bg-white text-right outline-trtp-primary border border-gray-200 dark:border-gray-800 dark:bg-black"
                      onChange={(field) => {
                        const _levels = [...levels];
                        _levels[index].level = parseFloat(field.target.value);
                        setLevels(_levels);
                        saveLevels();
                      }}
                      onBlur={sortLevels}
                    />
                  </div>
                  <div className="flex">
                    <div className="mx-auto">
                      <input
                        className="p-1 px-2 border border-gray-200 dark:border-gray-900 rounded w-full dark:bg-black outline-trtp-primary dark:placeholder-gray-600"
                        type="text"
                        maxLength={20}
                        defaultValue={fib.name ?? ""}
                        placeholder={'Level name...'}
                        onChange={(input) => {
                          const _levels = [...levels];
                          _levels[index].name = input.target.value;
                          setLevels(_levels);
                          saveLevels();
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="flex"> */}
                  {/*   <div className="mx-auto"> */}
                  {/*     <ColorPicker color={fib.color ?? color} onColorPick={(color) => { */}
                  {/*       const _levels = [...levels]; */}
                  {/*       _levels[index].color = color; */}
                  {/*       setLevels(_levels); */}
                  {/*       saveLevels(); */}
                  {/*     }} /> */}
                  {/*   </div> */}
                  {/* </div> */}
                  <div className="my-auto text-gray-500 dark:text-gray-700 hover:text-trtp-primary cursor-pointer" onClick={() => {
                    removeLevel(index);
                  }}>
                    <Tooltip overlay={`Remove level ${fib.level}`} mouseEnterDelay={0.75} >
                      <MdOutlineRemoveCircleOutline size={14} />
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex-1 flex w-full justify-center">
              <div className="bg-gray-100 hover:bg-gray-200 dark:bg-black dark:hover:bg-gray-900 text-gray-600 hover:text-black dark:hover:text-white cursor-pointer text-center rounded mx-1 my-2 p-1 w-full" onClick={addLevel}>
                Add level
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default FibRetracement;
