import { useEffect } from "react";
import { useConfig } from "./context/ConfigContext";

const Unauthenticated = () => {
  const {siteUrl} = useConfig();

  useEffect(() => {
    setTimeout(() => {
      window.location.href = siteUrl + '/api/auth/signin';
    }, 1000);
  }, []);

  return (
    <div className="h-screen w-screen flex dark:bg-black">
      <div className="flex m-auto">
        Not logged-in, redirecting...
      </div>
    </div>
  );
};

export default Unauthenticated;
