import { useAppSelector } from "../../../redux/hooks";
import PropertiesPanel from "../../../PropertiesPanel"
import { TabType } from "../../../redux/features/counter/menuSlice";
import Calendar from "../../Calendar";
import MtopsPanel from "src/MtopsPanel";
import SettingsPanel from "src/SettingsPanel";
import MarketsPanel from "../MarketsPanel";
import ToolsPanel from "src/ToolsPanel";

const Menu = () => {
  const selected = useAppSelector((state) => state.menu.selected);

  return (
    <>
      {selected === TabType.Markets && (<MarketsPanel />)}
      {selected === TabType.Properties && (<PropertiesPanel />)}
      {selected === TabType.Calendar && (<Calendar />)}
      {selected === TabType.Mtops && (<MtopsPanel />)}
      {selected === TabType.Settings && (<SettingsPanel />)}
      {selected === TabType.Tools && (<ToolsPanel />)}
    </>
  )
}

export default Menu;
