import React from "react";
import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { IoTrendingDown, IoTrendingUp } from "react-icons/io5";
import { useSocket } from "./context/SocketContext";
import useIsMobile from "./hooks/useIsMobile";
import useTimeframes from "./hooks/useTimeframes";
import { setTimeframe } from "./redux/features/counter/chartSlice";
import { useAppDispatch, useAppSelector } from "./redux/hooks";

type Result = {
  trend: { [key: string]: number },
  transition: { [key: string]: number },
}

const defaultState = {
  trend: {},
  transition: {},
}

const IconLong = () => {
  const colors = useAppSelector((state) => state.settings.colors);

  if (colors.transition_long) {
    return <IoTrendingUp
      size={18}
      className={`m-auto`}
      style={{
        color: colors.transition_long
      }}
    />
  } else {
    return <IoTrendingUp size={18} className={`text-orange-400 m-auto`} />
  }
}

const IconShort = () => {
  const colors = useAppSelector((state) => state.settings.colors);

  if (colors.transition_short) {
    return <IoTrendingDown
      size={18}
      className={`m-auto`}
      style={{
        color: colors.transition_short
      }}
    />
  } else {
    return <IoTrendingDown size={18} className={`text-purple-800 m-auto`} />
  }
}

const Overwatch = () => {
  const { sockets } = useSocket();
  const { timeframes } = useTimeframes();
  const dispatch = useAppDispatch();

  const [data, setData] = useState<Result>(defaultState);

  const activeTimeframe = useAppSelector((state) => state.chart.timeframe);

  const currentMarket = useAppSelector((state) => state.markets.active);
  const market = useAppSelector((root) => root.markets.markets.find((m) => m.identifier == currentMarket));

  const isMobile = useIsMobile();

  useEffect(() => {
    const refresh = () => {
      sockets?.mts?.emit("market.trend", { market: currentMarket }, (result: any) => {
        setData(result.data)
      });
    }

    const refreshTimer = setInterval(refresh, 5000);

    refresh();

    return () => {
      clearInterval(refreshTimer);
    }
  }, [sockets?.mts, currentMarket])

  const changeTimeframe = (timeframe: number) => {
    dispatch(setTimeframe(timeframe));

    if (isMobile == false) {
      // Click the tab to make the tab active
      document.getElementById(`chart_tab_${timeframe}`)?.click();
    }
  }

  return (
    <div className="step-overwatch">
      {isMobile == false && (
        <div className="bg-gray-100 dark:bg-gray-950 dark:text-gray-300 p-2 dark:font-bold text-xs">
          Overwatch {market?.name}
        </div>
      )}
      <div className={`grid grid-cols-8 text-center dark:bg-gray-975 py-2 ${isMobile == false && ("pb-4")} dark:text-gray-300 px-2`} style={{ fontSize: '8pt' }}>
        <div></div>
        {timeframes.map((timeframe: number) => (
          <div
            key={"tmf" + timeframe}
            className={`transition-all cursor-pointer duration-200 text-[7pt] border-l border-r border-t ${timeframe == activeTimeframe ? "border-trtp-primary rounded-t-sm bg-white dark:bg-black dark:text-white font-semibold" : "text-gray-600 dark:text-gray-500 border-transparent"}`}
            onClick={() => changeTimeframe(timeframe)}>
            {timeframe == 0.5 ? ("30s") : timeframe}
          </div>
        ))}
        <div className="text-right text-gray-600 dark:text-gray-500 mr-[2px]">Trend</div>
        {timeframes.map((timeframe: number) => (
          <div
            key={"trend" + timeframe}
            className={`transition-all cursor-pointer duration-200 border-l border-r ${timeframe == activeTimeframe ? "border-trtp-primary bg-white dark:bg-black" : "border-transparent"}`}
            onClick={() => changeTimeframe(timeframe)}>
            {data.trend[timeframe.toString()] == 1 && (<div><IconLong /></div>)}
            {data.trend[timeframe.toString()] == 2 && (<div><IconShort /></div>)}
            {data.trend[timeframe.toString()] == 3 && (<div>s-up</div>)}
            {data.trend[timeframe.toString()] == 4 && (<div>s-down</div>)}
            {data.trend[timeframe.toString()] == 5 && (<div className="mt-[2px]"><BsDot className={"m-auto text-gray-300 dark:text-gray-700"} /></div>)}
          </div>
        ))}
        <div className="text-right text-gray-600 dark:text-gray-500 mr-[2px]">Transition</div>
        {timeframes.map((timeframe: number) => (
          <div
            key={"transition" + timeframe}
            className={`transition-all cursor-pointer duration-200 border-l border-r border-b ${timeframe == activeTimeframe ? "border-trtp-primary rounded-b-sm bg-white dark:bg-black" : "border-transparent"}`}
            onClick={() => changeTimeframe(timeframe)}>
            {data.transition[timeframe.toString()] == 1 && (<div><IconLong /></div>)}
            {data.transition[timeframe.toString()] == 2 && (<div><IconShort /></div>)}
            {data.transition[timeframe.toString()] == 3 && (<div className="mt-[2px]"><BsDot className={"m-auto text-gray-300 dark:text-gray-700"} /></div>)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Overwatch;
