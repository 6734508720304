const AssetChip = (props: { text: string, selected: boolean, onSelect: () => void }) => {
  return (
    <div
      key={props.text}
      className={`my-auto whitespace-nowrap text-center flex-1 text-xs p-2 bg-gray-100 border rounded-md dark:text-white dark:bg-black ${props.selected ? "border-trtp-primary" : "border-transparent dark:border-gray-900 "}`}
      onClick={props.onSelect}>
      {props.text}
    </div>
  )
}

export default AssetChip;
