import { PlacementModeType } from "chart";
import { useEffect, useState } from "react";
import { HiOutlineRefresh } from "react-icons/hi";
import { IoMagnetOutline, IoSettingsOutline } from "react-icons/io5";
import { MdOutlineDraw } from "react-icons/md";
import { TbLockSearch, TbRulerMeasure, TbZoomReplace } from "react-icons/tb";
import { useChart } from "src/context/ChartContext";
import { useMobileAppController } from "src/context/MobileAppControllerContext";
import { toggleMagnet } from "src/redux/features/counter/appSlice";
import { setLockedScaling } from "src/redux/features/counter/chartSettingsSlice";
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';

type MorePanelProperties = {
  afterSelect: () => void
}

type ButtonProperties = {
  text: string,
  description?: string,
  active?: boolean,
  icon: JSX.Element,
  afterSelect: () => void
};

const Button = ({ text, description = "", active = false, icon, afterSelect }: ButtonProperties) => {
  return (
    <div
      className={`transition-all shadow relative text-sm flex gap-4 px-4 bg-gray-100 py-4 border ${active ? 'border-trtp-primary' : 'border-transparent dark:border-gray-900'}  rounded-md dark:bg-black dark:text-white`}
      onClick={afterSelect}>
      <div>{icon}</div>
      <div className="flex flex-col gap-1">
        <div>{text}</div>
        <div className="text-xxs tracking-tighter leading-tight text-gray-600 dark:text-gray-400 font-semibold">{description}</div>
      </div>
      {active && (
        <div className="absolute top-0 right-0 rounded-bl-sm rounded-tr-md text-white bg-trtp-primary px-[6px] py-[1px] lowercase font-bold" style={{ fontSize: '6pt' }}>Active</div>
      )}
    </div>
  );
}

type ConfirmButtonProperties = {
  text: string,
  afterConfirm: () => void,
  afterCancel: () => void
};

const ConfirmButton = ({ text, afterConfirm, afterCancel }: ConfirmButtonProperties) => {
  return (
    <div className={`col-span-2 flex flex-col text-sm gap-4 px-4 dark:text-white`}>
      <div>{text}</div>
      <div className={"flex justify-stretch gap-3"}>
        <div className={"flex-1 text-center bg-gray-200 dark:bg-gray-900 p-3 rounded"} onClick={afterCancel}>Cancel</div>
        <div className={"flex-1 text-center bg-green-500 text-white dark:bg-green-800 dark:text-white p-3 rounded"} onClick={afterConfirm}>Yes</div>
      </div>
    </div>
  );
}
const MorePanel = ({ afterSelect = () => { } }: MorePanelProperties) => {
  const [sureClear, setSureClear] = useState(false);

  const dispatch = useAppDispatch();

  const magnetIsActive = useAppSelector((root) => root.app.magnetActive);
  const activeTimeframe = useAppSelector((state) => state.chart.timeframe);
  const mode = useAppSelector((state) => state.chart.mode[activeTimeframe]);
  const lockedScaling = useAppSelector((state) => state.chartSettings.scale_locked);

  const chartProvider = useChart();
  const mobileAppController = useMobileAppController();

  useEffect(() => {
    //@ts-ignore
    chartProvider.performAll((chart: MtopsChart) => {
      if (magnetIsActive) {
        chart.tools.placementMode.setMode(PlacementModeType.Magnetic);
      } else {
        chart.tools.placementMode.setMode(PlacementModeType.Float);
      }
    });
  }, [magnetIsActive]);

  const delayedSelect = () => {
    setTimeout(() => {
      afterSelect();
    }, 350);
  }

  const changeMode = (mode: string) => {
    const chart = chartProvider.get(activeTimeframe);
    if (!chart) return;

    // @ts-ignore
    chart.mode.switch(mode);

    delayedSelect();
  }

  const handleMagnetToggle = () => {
    dispatch(toggleMagnet());

    delayedSelect();
  }

  const handleScalingLockToggle = () => {
    const chart = chartProvider.get(activeTimeframe);
    if (!chart) return;

    dispatch(setLockedScaling(!lockedScaling));

    delayedSelect();
  }

  const handleScalingReset = () => {
    const chart = chartProvider.get(activeTimeframe);
    if (!chart) return;

    chart.controls.zoomReset();

    delayedSelect();
  }

  const handleClear = () => {
    const chart = chartProvider.get(activeTimeframe);

    if (!chart) return;

    // @ts-ignore
    chart.clear();
    setSureClear(false);
    afterSelect();
  }

  return <div className="min-h-[400px] max-h-[400px] flex flex-col gap-4 overflow-y-auto pb-5 dark:bg-gray-975">
    <div className="flex flex-col">
      {sureClear == false ? (
        <>
          <div className="grid grid-cols-2 gap-3 py-3 px-5">
            <Button active={mode == 'annotation'} text="Annotate" description={"Draw annotations on the chart"} icon={<MdOutlineDraw size={20} />} afterSelect={() => changeMode("annotation")} />
            <Button active={mode == 'measure'} text="Measure" description={"Measure time and price in a range"} icon={<TbRulerMeasure size={20} />} afterSelect={() => changeMode("measure")} />
            <Button active={magnetIsActive} text="Magnet" description={"Snaps movement to closest pricebar"} icon={<IoMagnetOutline size={20} />} afterSelect={handleMagnetToggle} />
          </div>
          <div className="text-gray-700 font-semibold py-2 px-5 ">Scaling</div>
          <div className="grid grid-cols-2 gap-3 pb-3 px-5">
            <Button active={lockedScaling} text="Lock Scaling" description={"Locks the scaling of the chart"} icon={<TbLockSearch size={20} />} afterSelect={handleScalingLockToggle} />
            <Button text="Reset scaling" description={"Reset zoom and scaling to default settings"} icon={<TbZoomReplace size={20} />} afterSelect={handleScalingReset} />
          </div>
          <div className="text-gray-700 font-semibold py-2 px-5">Other</div>
          <div className="grid grid-cols-2 gap-3 pb-3 px-5">
            <Button active={false} text="Clear chart" description={"Removes all tools from the chart"} icon={<HiOutlineRefresh size={20} className={"transform-gpu -rotate-90"} />} afterSelect={() => handleClear()} />
            <Button active={false} text="Settings" description={"Change settings of Tesseract Pro"} icon={<IoSettingsOutline size={20} />} afterSelect={() => mobileAppController.switchPage("settings")} />
          </div>
        </>
      ) : (
        <ConfirmButton text="Sure you want to clear?" afterConfirm={handleClear} afterCancel={() => setSureClear(false)} />
      )}
    </div>
  </div>;
}

export default MorePanel;
