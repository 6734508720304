import { useEffect, useState } from "react";
import ColorPicker from "src/component/ColorPicker";
import { useChart } from "src/context/ChartContext";
import useStateId from "src/hooks/useStateId";
import useTool from "src/hooks/useTool";
import Row from "./Row";
import GannBoxOption from "./turningpoint/GannboxOption";
import GannDoubleArcsOption from "./turningpoint/GannDoubleArcsOption";
import GridOption from "./turningpoint/GridOption";

const GannSquare = () => {
  const [color, setColor] = useState<string>("");

  const tool = useTool();
  const chartProvider = useChart();
  const chart = chartProvider.current();

  const stateId = useStateId();


  useEffect(() => {
    if (tool === null) return;

    setColor(tool.getOption("color"));
  }, [tool, stateId.id]);

  const handleColorChange = (color: string) => {
    setColor(color);
    tool?.setOption("color", color);
  };

  if (!tool) {
    return <></>;
  }

  return (
    <>
      <Row
        title="Color"
        component={
          <ColorPicker
            color={color}
            onColorPick={(color) => handleColorChange(color)}
          />
        }
      />
      <GannBoxOption turningPoint={tool} chart={chart} checkHidden={false} />
      <GannDoubleArcsOption turningPoint={tool} chart={chart} />
    </>
  );
};

export default GannSquare;
