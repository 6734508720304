import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Preset, Tool } from "common";

interface PresetsState {
  loading: boolean;
  presets: {
    [key in Tool]?: Preset[]
  }
}

const initialState: PresetsState = {
  loading: false,
  presets: {}
};

export type AddPresetPayload = {
  name: string;
  options: { [key: string]: number | boolean | string }
  tool: Tool,
}

export type AddPresetPayloadResult = Preset & AddPresetPayload & {
  id: string;
}

type DeletePresetPayload = {tool: string, preset: Preset};
type DeletePresetPayloadResult = {
  success: boolean;
}

export const addNewPreset = createAsyncThunk(
  'presets/addNewPreset',
  async (payload: AddPresetPayload): Promise<AddPresetPayloadResult> => {
    const response = await fetch(
      localStorage.getItem('restbackend') + "/preset",
      {
        method: 'POST',
        headers: new Headers({
          // @ts-ignore
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(payload)
      });

    return response.json();
  }
)

export const deletePreset = createAsyncThunk(
  'presets/deletePreset',
  async (payload: DeletePresetPayload): Promise<DeletePresetPayloadResult> => {
    const response = await fetch(
      localStorage.getItem('restbackend') + "/preset/" + payload.tool + '/' + payload.preset.id,
      {
        method: 'DELETE',
        headers: new Headers({
          // @ts-ignore
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        })
      });

    return response.json();
  }
)


export const presetsSlice = createSlice({
  name: "presets",
  initialState,
  reducers: {
    setPresets: (state, action: PayloadAction<any>) => {
      state.presets = action.payload;
    }
  },
  extraReducers: (builder) => {
    // Add new preset
    builder
      .addCase(addNewPreset.fulfilled, (state, action) => {
        state.loading = false;

        if (!state.presets[action.payload.tool]) {
          state.presets[action.payload.tool] = [];
        }

        // @ts-ignore
        state.presets[action.payload.tool].push({
          id: action.payload.id,
          name: action.payload.name,
          options: action.payload.options
        });
      })
      .addCase(addNewPreset.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewPreset.rejected, (state, action) => {
        console.error(state, action);
        state.loading = false;
      });

    // Remove preset
    builder
      .addCase(deletePreset.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;

        for (const tool in state.presets) {
          // @ts-ignore
          state.presets[tool] = state.presets[tool].filter((preset: Preset) => {
            return preset.id != action.payload.id
          })
        }

      })
      .addCase(deletePreset.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePreset.rejected, (state, action: PayloadAction<any>) => {
        console.error(action);
        state.loading = false;
      });
  }
});

export const { setPresets } = presetsSlice.actions;

export default presetsSlice.reducer;
