import { motion } from "framer-motion"
import { createPortal } from "react-dom"
import useColorScheme from "src/hooks/useColorscheme"

type ModalProperties = {
  handleClose: () => void
  children: React.ReactElement
}

const Modal = ({ handleClose, children }: ModalProperties) => {
  const colorscheme = useColorScheme(); 

  return (
    createPortal(<>
      <motion.div
        initial={{opacity: 0}}
        exit={{opacity: 0}}
        animate={{opacity: colorscheme == "dark" ? 0.8 : 0.4}}
        className="absolute top-0 left-0 h-screen w-screen bg-black cursor-default" style={{ zIndex: 10000000 }} onClick={handleClose}>
      </motion.div>
      <motion.div
        initial={{opacity: 0}}
        exit={{opacity: 0}}
        animate={{opacity: 1}}
        className="cursor-default absolute top-1/2 left-1/2  transform -translate-x-1/2 -translate-y-1/2 flex" style={{ zIndex: 10000001 }}>
        <div className="flex flex-col m-auto">
          {children}
        </div>
      </motion.div>
    </>, document.body)
  );
}

export default Modal;
